import PropTypes from 'prop-types';

export const dailyProductionQueryType = PropTypes.shape({
  siteProductionDaily: PropTypes.arrayOf(
    PropTypes.shape({
      readingDateLocal: PropTypes.string,
      deliveredKwh: PropTypes.string,
    }),
  ),
});

export const meterInfoType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    manufacturer: PropTypes.string,
    serialNumber: PropTypes.string,
    inServiceDate: PropTypes.string,
    status: PropTypes.string,
    statusDetails: PropTypes.shape({
      readingEndTimeLocal: PropTypes.string,
    }),
    types: PropTypes.arrayOf(PropTypes.string),
    vendorName: PropTypes.string,
  }),
);

export const meterStatusType = PropTypes.shape({
  consumptionKwh: PropTypes.string,
  createDate: PropTypes.string,
  creationDateUTC: PropTypes.string,
  deliveredKwh: PropTypes.string,
  feedInKwh: PropTypes.string,
  isDaytime: PropTypes.string,
  lastUpdated: PropTypes.string,
  purchasedKwh: PropTypes.string,
  readingEndTimeLocal: PropTypes.string,
  readingEndTimeUTC: PropTypes.string,
  receivedKwh: PropTypes.string,
  serialNumber: PropTypes.string,
  timeZone: PropTypes.string,
  unknownKwh: PropTypes.string,
  vendor: PropTypes.string,
});

export const meterType = PropTypes.shape({
  prospectId: PropTypes.string,
  manufacturer: PropTypes.string,
  serialNumber: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  deviceId: PropTypes.string,
  vendorName: PropTypes.string,
  model: PropTypes.string,
  inServiceDate: PropTypes.string,
  lastUpdated: PropTypes.string,
  vendorSiteId: PropTypes.string,
  timeZone: PropTypes.string,
  tzName: PropTypes.string,
  status: PropTypes.string,
  statusDetails: meterStatusType,
});

export const meterIntervalReadingType = PropTypes.shape({
  deliveredKwh: PropTypes.string,
  readingEndTimeLocal: PropTypes.string,
});

export const meterIntervalType = PropTypes.shape({
  manufacturer: PropTypes.string,
  performanceData: PropTypes.arrayOf(meterIntervalReadingType),
});

export const meterIntervalQueryType = PropTypes.shape({
  meterInterval: PropTypes.arrayOf(meterIntervalType),
});

export const meterDailyReadingType = PropTypes.shape({
  consumptionKwh: PropTypes.string,
  deliveredKwh: PropTypes.number,
  readingDateLocal: PropTypes.string,
});

export const batteryReadingType = PropTypes.shape({
  batteryPercentageState: PropTypes.number,
  batteryState: PropTypes.number,
  internalTemp: PropTypes.number,
  lifeTimeEnergyCharged: PropTypes.number,
  lifeTimeEnergyDischarged: PropTypes.number,
  power: PropTypes.number,
  timeStamp: PropTypes.string,
  timeStampUTC: PropTypes.string,
});

export const batteryType = PropTypes.shape({
  manufacturer: PropTypes.string,
});

export const contractGenerationsReadingType = PropTypes.shape({});

export const siteProductionMonthlyReadingType = PropTypes.shape({});

export const fieldServiceActivityReadingType = PropTypes.shape({
  actionCodeName: PropTypes.string,
  caseNumber: PropTypes.string,
  componentCodeName: PropTypes.string,
  createDate: PropTypes.string,
  closedDate: PropTypes.string,
  dispatchName: PropTypes.string,
  dispatchTime: PropTypes.string,
  serviceContractName: PropTypes.string,
  reason: PropTypes.string,
});

export const peerAdjGenerationsReadingType = PropTypes.shape({
  generationDate: PropTypes.string,
  peerAdjustedMedian: PropTypes.string,
  peerAdjustedNormalized: PropTypes.string,
});

export const lineSeriesCoordinatesType = PropTypes.shape({
  x: PropTypes.number.isRequired,
  y: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(Date)]),
});

export const siteDetailsType = PropTypes.shape({
  serviceContractName: PropTypes.string,
  contractId: PropTypes.string,
  customerName: PropTypes.string,
  address: PropTypes.shape({
    street: PropTypes.string,
    state: PropTypes.string,
    city: PropTypes.string,
    zip: PropTypes.string,
    lat: PropTypes.string,
    lon: PropTypes.string,
  }),
  ptoDate: PropTypes.string,
  systemSizeDC: PropTypes.string,
  battery: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape(batteryType),
      }),
    ),
  }),
  inverter: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          manufacturer: PropTypes.string,
        }),
      }),
    ),
  }),
  meter: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          manufacturer: PropTypes.string,
        }),
      }),
    ),
  }),
  systemsCharacteristics: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          panelMfg: PropTypes.string,
        }),
      }),
    ),
  }),
  installPartner: PropTypes.string,
  salesPartnerName: PropTypes.string,
  fundName: PropTypes.string,
  utilityCompany: PropTypes.string,
  status: PropTypes.string,
  fyge: PropTypes.string,
  billingType: PropTypes.string,
  welcomeDate: PropTypes.string,
  m1Date: PropTypes.string,
  m2Date: PropTypes.string,
  ptoRecordedDate: PropTypes.string,
});

export const siteSystemCharacteristicsType = PropTypes.shape({
  prospectId: PropTypes.string,
  tilt: PropTypes.string,
  azimuth: PropTypes.string,
  roofOffset: PropTypes.string,
  numPanels: PropTypes.string,
  numInverters: PropTypes.string,
  inverterMfg: PropTypes.string,
  inverterModel: PropTypes.string,
  panelMfg: PropTypes.string,
  panelModel: PropTypes.string,
  janShading: PropTypes.number,
  febShading: PropTypes.number,
  marShading: PropTypes.number,
  aprShading: PropTypes.number,
  mayShading: PropTypes.number,
  junShading: PropTypes.number,
  julShading: PropTypes.number,
  augShading: PropTypes.number,
  sepShading: PropTypes.number,
  octShading: PropTypes.number,
  novShading: PropTypes.number,
  decShading: PropTypes.number,
});
