import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import config from '../../config';
import withAuthorizationCheck from '../containers/withAuthorizationCheck';
import LinksMenu from './LinksMenu';
import EXTERNAL_LINKS, { nonAdminExternalLinks } from '../constants/ExternalLinks';
import INTERNAL_LINKS, { nonAdminInternalLinks } from '../constants/InternalLinks';
import InternalLinkComponent from './InternalLinkComponent';
import LoginContainer from '../containers/LoginContainer';
import Icon from './Icon';
import SearchBar from './SearchBar';
import { BLUE, WHITE, NAVY } from '../constants/_colors';
import { HEADER_ZINDEX } from '../constants/_zIndex';

const HeaderWrap = styled.header`
  height: 100px;
  height: 65px;
  position: fixed;
  width: 100%;
  z-index: 150;
`;

const Header = styled.div`
  align-items: center;
  background: ${NAVY};
  display: flex;
  justify-content: space-between;
  padding: 13px 25px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: ${HEADER_ZINDEX};
`;

const Nav = styled.nav`
  align-items: center;
  display: flex;
  height: 100%;
  padding: 9px 0;
`;

const NocLogo = styled(Icon)`
  height: 25px;
  width: 64px;
`;

const NocLogoWrap = styled.div`
  justify-content: center;
  display:flex;
  width: 100px;
`;
const SearchWrap = styled.div`
  display: flex;
  flex: 1;
  padding: 0 18px 0 18px;
`;

export const HeaderComponentNoRouter = ({ isAuthorized: isAdmin }) => {
  return (
    <HeaderWrap>
      <Header>
        <NocLogoWrap>
          <InternalLinkComponent path="/">
            <NocLogo style={{ marginTop: '-3px', marginRight: 0 }} name="LogoIcon" />
          </InternalLinkComponent>
        </NocLogoWrap>
        <SearchWrap>
          <SearchBar />
        </SearchWrap>
        <Nav>
          <LoginContainer />
          <LinksMenu
            renderIcon={isOpen => (
              <Icon name="ExternalLinksIcon" fill={isOpen ? BLUE : WHITE} width={21} height={20} />
            )}
            links={isAdmin ? EXTERNAL_LINKS : nonAdminExternalLinks}
          />
          <LinksMenu
            renderIcon={isOpen => (
              <Icon name="InternalLinksIcon" fill={isOpen ? BLUE : WHITE} width={21} height={21} />)}
            links={isAdmin ? INTERNAL_LINKS : nonAdminInternalLinks}
          />
        </Nav>
      </Header>
    </HeaderWrap>
  );
};

HeaderComponentNoRouter.propTypes = {
  isAuthorized: PropTypes.bool,
};

HeaderComponentNoRouter.defaultProps = {
  isAuthorized: false,
};

export default withAuthorizationCheck(
  HeaderComponentNoRouter,
  [`NOC-${config.oktaEnvironment}-Admin`, 'NOC-Admin'],
);
