import localConfig from './config/config.local';
import devConfig from './config/config.dev';
import stgConfig from './config/config.stg';
import prodConfig from './config/config.prod';

const hostNameConfigMap = {
  'devmaj-noc2.sunrundev.com': devConfig,
  'majstg-noc.sunrun.com': stgConfig,
  'majstg-noc2.sunrun.com': stgConfig,
  'noc.sunrun.com': prodConfig,
  'noc2.sunrun.com': prodConfig,
};

export const getConfig = hostname => hostNameConfigMap[hostname];

const config = getConfig(window.location.hostname) || localConfig;

export default config;
