/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { Crosshair } from 'react-vis';
import { WHITE, NAVY } from '../../../general/constants/_colors';

const StyledCrosshair = styled.div`
  padding: 19px 17px;
  min-width: 200px;
  background: ${WHITE};
  opacity: 0.9;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
  z-index: 300;
  font-size: 14px;
  line-height: 2;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  color: ${NAVY};
`;

function HistoricalPerformanceCrossHair({ coordinate, children, ...otherProps }) {
  if (!coordinate) return null;
  return (
    <Crosshair
      style={{
        line: {
          background: 'none',
          border: '1px dashed red',
        },
      }}
      values={[coordinate]}
      {...otherProps}
    >
      <StyledCrosshair>{children}</StyledCrosshair>
    </Crosshair>
  );
}

HistoricalPerformanceCrossHair.propTypes = {
  coordinate: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  children: PropTypes.node,
};

const StyledCrosshairDate = styled.div`
  font-weight: bold;
  padding: 0 6px;
`;


function reduceCoordinatesByLabelToHoverValuesByDate(coordinatesByLabel) {
  return Object.keys(coordinatesByLabel).reduce((hoverValuesByDate, label) => {
    const coordinates = coordinatesByLabel[label];
    coordinates.forEach(({ x, y }) => {
      if (!hoverValuesByDate[x]) hoverValuesByDate[x] = { x, y: 0 };
      hoverValuesByDate[x][label] = y;
    });
    return hoverValuesByDate;
  }, {});
}

export {
  HistoricalPerformanceCrossHair,
  reduceCoordinatesByLabelToHoverValuesByDate,
  StyledCrosshairDate,
};
