import React from 'react';
import PropTypes from 'prop-types';
import { XYPlot } from 'react-vis';
import AutoSizer from 'react-virtualized-auto-sizer';

const ResizableXYPlot = ({ children, margin, ...rest }) => (
  <AutoSizer disableHeight>
    {
      ({ width }) => (
        <XYPlot
          width={width}
          margin={margin}
          {...rest}
        >
          {children}
        </XYPlot>
      )
    }
  </AutoSizer>
);

ResizableXYPlot.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  margin: PropTypes.shape({
    left: PropTypes.number,
    right: PropTypes.number,
  }),
};

ResizableXYPlot.defaultProps = {
  margin: {
    left: 0,
    right: 0,
  },
};

export default ResizableXYPlot;
