import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { NAVY } from '../../../general/constants/_colors';

const LegendItemWrap = styled.div`
  align-items: center;
  color: ${NAVY};
  display: flex;
  font-size:16px;
  padding: 0 6px;
`;

const LegendIcon = styled.div`
  background-color: ${props => props.color};
  height: ${props => props.height}px;
  margin-right: 12px;
  width: 20px;
`;

export const LineKey = ({ color, children, className }) => (
  <LegendItemWrap className={className}>
    <LegendIcon color={color} height={4} />
    {children}
  </LegendItemWrap>
);

export const AreaKey = ({ color, children, className }) => (
  <LegendItemWrap className={className}>
    <LegendIcon color={color} height={20} />
    {children}
  </LegendItemWrap>
);

const legendItemPropTypes = {
  color: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  className: PropTypes.string,
};

const legendItemDefaultProps = {
  className: undefined,
  children: [],
};

LineKey.propTypes = legendItemPropTypes;
AreaKey.propTypes = legendItemPropTypes;
LineKey.defaultProps = legendItemDefaultProps;
AreaKey.defaultProps = legendItemDefaultProps;
