import React from 'react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import { withSystemCharacteristics } from '../../queries/withSiteQueries';
import MonthlyGraph from '../../../general/components/MonthlyGraph';
import Table from '../../../general/components/Table';
import { Tooltip } from '../../../general/components';
import { siteSystemCharacteristicsType } from '../types';

const systemDetailsColumns = [
  {
    key: 'array',
    name: 'Array',
    render: (row, index) => {
      return index + 1;
    },
  },
  {
    key: 'tilt',
    name: 'Tilt',
    render: (row) => {
      return row.tilt;
    },
  },
  {
    key: 'azimuth',
    name: 'Azimuth',
    render: (row) => {
      return Math.round(row.azimuth);
    },
  },
  {
    key: 'roof_offset',
    name: 'Roof Offset',
    render: (row) => {
      return row.roofOffset;
    },
  },
  {
    key: 'num_panels',
    name: 'Num Panels',
    render: (row) => {
      return row.numPanels;
    },
  },
  {
    key: 'num_inverters',
    name: 'Num Inverters',
    render: (row) => {
      return row.numInverters;
    },
  },
  {
    key: 'inverter_mfg',
    name: 'Inverter Mfg',
    render: (row) => {
      return row.inverterMfg;
    },
  },
  {
    key: 'inverter_model',
    name: 'inverter Model',
    render: (row) => {
      return row.inverterModel;
    },
  },
  {
    key: 'panel_mfg',
    name: 'Panel Mfg',
    render: (row) => {
      return row.panelMfg;
    },
  },
  {
    key: 'panel_model',
    name: 'Panel Model',
    render: (row) => {
      return row.panelModel;
    },
  },
  {
    key: 'solar_access',
    name: 'Solar Access',
    style: {
      padding: '0 12px',
    },
    render: (row) => {
      const months = [
        {
          name: 'Jan',
          percent: Math.round(row.janShading),
        },
        {
          name: 'Feb',
          percent: Math.round(row.febShading),
        },
        {
          name: 'Mar',
          percent: Math.round(row.marShading),
        },
        {
          name: 'Apr',
          percent: Math.round(row.aprShading),
        },
        {
          name: 'May',
          percent: Math.round(row.mayShading),
        },
        {
          name: 'Jun',
          percent: Math.round(row.junShading),
        },
        {
          name: 'Jul',
          percent: Math.round(row.julShading),
        },
        {
          name: 'Aug',
          percent: Math.round(row.augShading),
        },
        {
          name: 'Sep',
          percent: Math.round(row.sepShading),
        },
        {
          name: 'Oct',
          percent: Math.round(row.octShading),
        },
        {
          name: 'Nov',
          percent: Math.round(row.novShading),
        },
        {
          name: 'Dec',
          percent: Math.round(row.decShading),
        },
      ];
      return (
        <Tooltip
          hoverTarget={(<MonthlyGraph months={months} />)}
          style={{ top: -230 }}
        >
          <MonthlyGraph months={months} size="large" />
        </Tooltip>
      );
    },
  },
];

const SystemDetailsTable = (props) => {
  const { systemsCharacteristicsQuery: { systemsCharacteristics } } = props;
  if (systemsCharacteristics.length === 0) return null;
  return (
    <Table
      columns={systemDetailsColumns}
      rows={systemsCharacteristics}
      getUniqueKey={(row, index) => `${row.numInverters}-${row.numPanels}-${index}`}
    />
  );
};

SystemDetailsTable.propTypes = {
  systemsCharacteristicsQuery: PropTypes.shape({
    systemsCharacteristics: PropTypes.arrayOf(
      siteSystemCharacteristicsType,
    ),
  }).isRequired,
};

export default flowRight(withSystemCharacteristics)(SystemDetailsTable);
