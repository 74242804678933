export default [
  {
    text: 'Itron TMS',
    location: 'http://198.147.212.128:8080/smartview/index.jsp',
  },
  {
    text: 'Itron Openway',
    location: 'https://rds.itron-hosting.com/RDWeb/Pages/en-US/login.aspx',
  },
  {
    text: 'SolarEdge',
    location: 'https://monitoring.solaredge.com/solaredge-web/p/login',
  },
  {
    text: 'Locus',
    location: 'https://locusnoc.datareadings.com/login',
  },
  {
    text: 'Enphase',
    location: 'https://enlighten.enphaseenergy.com/',
  },
];

export const nonAdminExternalLinks = [
  {
    text: 'SolarEdge',
    location: 'https://monitoring.solaredge.com/solaredge-web/p/login',
  },
];
