import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-flexbox-grid';

const Profile = ({ user }) => {
  const entries = Object.entries(user);
  return (
    <Fragment>
      <h4>Okta User Info</h4>
      &nbsp;
      <Grid>
        { entries.map(([k, v]) => (
          <Row key={k} between="xs">
            <Col><span>{k}</span></Col>
            <Col><span>{v}</span></Col>
          </Row>
        ))}
      </Grid>
    </Fragment>
  );
};

Profile.propTypes = {
  user: PropTypes.shape({}).isRequired,
};

export default Profile;
