import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { debounce, get } from 'lodash';
import mixpanel from 'mixpanel-browser';
import styled from '@emotion/styled/macro';
import Icon from './Icon';
import { BLUE, GREY, RED } from '../constants/_colors';
import Loader from './Loader';

const SearchWrap = styled.div`
  width: 100%;
  display: flex;
  align-self: flex-start;
  border-radius: 20px;
  border: none;
  position: relative;
  background: white;
  flex-direction: column;
  overflow: hidden;
`;

const Search = styled.input`
  width: 100%;
  outline: none;
  color: ${BLUE};
  padding: 7px 16px;
  align-self: flex-start;
  display: flex;
  border: none;
  transition: .3s;

  &::placeholder {
    color: ${BLUE};
  }
`;

const Container = styled.div`
  position: relative;
  transition: .3s;
  width: 100%;
`;

const SearchIcon = styled(Icon)`
cursor: pointer;
position: absolute;
width: 30px;
height: 30px;
padding: 7px;
right: 4px;
top: 0;
`;

const SearchBoxWrap = styled.div`
  width: 100%;
`;

const Results = styled.ul`
  width: 100%;
  margin-top: 20px;
  font-size: 20px;
`;

const ResultItem = styled.li`
  width: 100%;
  font-size: ${props => (props.isError ? '12px' : 'inherit')};
  color: ${props => (props.isError ? RED : BLUE)};
  text-align: ${props => (props.isError ? 'center' : 'left')};
  padding: 12px;
  transition: .3s;
  cursor: pointer;

  &:hover {
    background: ${props => (props.isError ? 'inherit' : GREY)}
  }
`;

const LoaderWrap = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 7px;
  right: 0;
  top: 0;
`;

const SITE_SEARCH_QUERY = gql`
  query SiteSearch($searchText: String!, $first: Int) {
    search(first: $first, searchText: $searchText) {
      edges {
        node {
          id
          prospectId
          customerName
          contractId
          serviceContractAgreementNumber
        }
      }
    }
  }
`;

class SearchBar extends PureComponent {
  constructor() {
    super();
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.debounceSearchChange = debounce(this.handleSearchSubmit, 300);
    this.state = {
      isActive: false,
      searchValue: '',
      debouncedSearchValue: '',
    };
  }

  handleSearchSubmit(value) {
    mixpanel.track('User Searched', { searchValue: value });
    this.setState({ debouncedSearchValue: value.trim() });
  }

  handleSearchChange(e) {
    this.setState({ searchValue: e.target.value });
    if (e.target.value && e.target.value !== '') {
      this.debounceSearchChange(e.target.value);
    }
  }

  handleBlur() {
    this.setState({ isActive: false });
  }

  handleFocus() {
    this.setState({ isActive: true });
  }

  handleLinkClick(to) {
    const { history } = this.props;
    history.push(to);
  }

  render() {
    const {
      debouncedSearchValue, searchValue, isActive,
    } = this.state;
    return (
      <Query
        query={SITE_SEARCH_QUERY}
        skip={debouncedSearchValue === ''}
        variables={{ first: 5, searchText: debouncedSearchValue }}
      >
        {({
          data, loading,
        }) => {
          return (
            <Container>
              <SearchWrap>
                <SearchBoxWrap>
                  <Search
                    onChange={e => this.handleSearchChange(e)}
                    value={searchValue}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    placeholder="Search by SCN"
                  />
                  {
                    loading && (
                      <LoaderWrap>
                        <Loader width={25} />
                      </LoaderWrap>
                    )
                  }
                  {
                    !loading && (
                    <SearchIcon
                      name="SearchIcon"
                    />
                    )
                  }
                </SearchBoxWrap>
                {
                  isActive && searchValue !== '' && get(data, 'search.edges.length', 0) > 0 && (
                    <Results>
                      {
                        data.search.edges.map((searchResult) => {
                          return (
                            <ResultItem
                              key={searchResult.node.id}
                              onMouseDown={() => this.handleLinkClick(`/site/${searchResult.node.prospectId}`)}
                            >
                              {`${searchResult.node.serviceContractAgreementNumber}: ${searchResult.node.customerName}`}
                            </ResultItem>
                          );
                        })
                      }
                    </Results>
                  )
                }
                {
                  !loading && isActive && searchValue !== '' && get(data, 'search.edges.length') === 0 && (
                    <Results>
                      <ResultItem isError>
                        {`No search results for ${searchValue}`}
                      </ResultItem>
                    </Results>
                  )
                }
              </SearchWrap>
            </Container>
          );
        }}
      </Query>
    );
  }
}

SearchBar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(SearchBar);
