import { gql } from '@apollo/client';

export const ALERT_TYPES_QUERY = gql`
  query AlertTypesQuery {
    alertType(first: 15) {
      edges {
        node {
          alertId: id
          intRef
          name
          alertColor
          caseReason
          casePriority
        }
      }
    }
  }
`;
