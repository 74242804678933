import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Rollbar from 'rollbar';
import config from '../../config';

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  }

  state = {
    hasError: false,
    rollbar: new Rollbar(config.rollbar),
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    const { rollbar } = this.state;
    rollbar.error(error, info);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return <div>Error</div>;
    }
    return children;
  }
}

export default ErrorBoundary;
