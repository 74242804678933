import styled from '@emotion/styled/macro';
import { NAVY } from '../constants/_colors';

const SectionHeader = styled.div`
  color: ${NAVY};
  font-size: 24px;
  line-height: 25px;
  padding-left: 10px;
`;

export default SectionHeader;
