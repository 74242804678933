import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from '.';

const CircleWithTooltip = ({ children }) => {
  const style = {
    top: -35,
    whiteSpace: 'normal',
    width: 600,
  };

  return (
    <Tooltip style={style} hoverTarget={<Icon name="BlueCircleIcon" width={13} height={13} />}>
      {children}
    </Tooltip>
  );
};

CircleWithTooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CircleWithTooltip;
