/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import styled from '@emotion/styled/macro';
import { ButtonLink } from '../../general/components';
import Profile from './Profile';
import Versions from './Versions';

const VERSIONS_QUERY = gql`
  query VersionsQuery {
    versions @rest(type: "Versions", path: "") {
      PACKAGE_VERSIONS
      SHA
    }
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Container = styled.div`
  padding: 20px;
`;

const ToggleButtonWrapper = styled.div`
  margin-top: 10px;
`;


const ProfileSection = styled.div`
  margin-top: 10px;
`;

const Welcome = ({ user }) => {
  const [isShowing, setIsShowing] = useState(false);

  return (
    <Container>

      Hello
      {' '}
      {<Bold>{user.name}</Bold>}
      {' '}
        from
      {' '}
      {<Bold>{user.department}</Bold>}
      ,

      <ProfileSection>
        {isShowing && <Profile user={user} />}
        {isShowing
          && (
            <Query query={VERSIONS_QUERY}>
              {({ data }) => {
                return data
                  ? <Versions data={data} />
                  : null;
              }}
            </Query>
          )
        }
        <ToggleButtonWrapper>
          <ButtonLink onClick={() => setIsShowing(!isShowing)}>{
            isShowing ? 'Hide Details' : 'Show Details'}
          </ButtonLink>
        </ToggleButtonWrapper>
      </ProfileSection>

    </Container>
  );
};

Welcome.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    department: PropTypes.string,
  }).isRequired,
};

export default Welcome;
