import {
  NAVY,
  CCC,
} from '../../../general/constants/_colors';


export const axisStyle = {
  line: {
    stroke: 'none',
  },
  ticks: {
    stroke: 'none',
  },
  text: {
    fontSize: 12,
    fill: NAVY,
  },
};

export const dashedGridStyle = {
  stroke: CCC,
  strokeDasharray: 5,
  strokeWidth: 0.5,
};

export const invisibleLineStyle = {
  fill: 'none',
  stroke: 'none',
};
