import React from 'react';
import PropTypes from 'prop-types';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';
import config from '../../../config';


const MapSetup = withScriptjs(withGoogleMap((props) => {
  return (
    <GoogleMap
      {...props.mapProps}
      options={
        {
          mapTypeControl: false,
          streetViewControl: false,
          scrollwheel: false,
          rotateControl: false,
          zoomControl: false,
          fullscreenControl: false,
          ...props.mapProps.options,
        }
      }
    >
      {props.children}
    </GoogleMap>
  );
}));

const Map = (props) => {
  return (
    <MapSetup
      {...props}
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.maps.apiKey}&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: '100%' }} />}
      containerElement={<div style={{ height: '300px', position: 'relative' }} />}
      mapElement={<div style={{ height: '100%' }} />}
    />
  );
};

Map.defaultProps = {
  mapProps: {
    options: {},
    defaultZoom: 20,
  },
};

Map.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  mapProps: PropTypes.shape({
    options: PropTypes.object,
    defaultZoom: PropTypes.number,
  }),
};


export default Map;
