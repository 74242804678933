import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  LineSeries, YAxis, XAxis, HorizontalGridLines,
} from 'react-vis';
import { SplitLineSeries } from '@sunrun/recent-performance-charts';
import { DateTime } from 'luxon';
import ResizableXYPlot from './ResizableXYPlot';
import {
  CCC, CHARCOAL,
} from '../../../general/constants/_colors';
import { invisibleLineStyle } from './styles';

const dashedGridStyle = {
  stroke: CCC,
  strokeDasharray: 2,
  strokeWidth: 0.5,
};

const axisStyle = {
  line: {
    stroke: 'none',
  },
  ticks: {
    stroke: 'none',
  },
  text: {
    fontSize: 12,
    fill: CHARCOAL,
  },
};

class Display extends PureComponent {
  getTickValues = startDate => Array(12).fill(0)
    .map((el, index) => {
      return startDate.plus({ months: index }).toMillis();
    });

  getTickFormat = t => DateTime.fromMillis(t).toFormat('MMM');

  render() {
    const { axisStartDate, years } = this.props;

    return (
      <ResizableXYPlot
        animation
        height={310}
        margin={{ left: 70, right: 0 }}
        xType="time"
      >
        {
          years
            .map(y => <SplitLineSeries key={y.key} data={y.coordinates} unit="day" delta={1} style={y.style} />)
        }
        <LineSeries
          data={[
            { x: axisStartDate.toMillis(), y: 1 },
            { x: axisStartDate.endOf('year').toMillis(), y: 1 },
          ]}
          style={invisibleLineStyle}
        />
        <YAxis style={axisStyle} width={70} tickFormat={t => `${t} kWh`} />
        <XAxis
          style={{
            ...axisStyle,
            text: {
              fontSize: 12,
              fill: CHARCOAL,
              textAnchor: 'start',
            },
          }}
          tickValues={this.getTickValues(axisStartDate)}
          tickFormat={this.getTickFormat}
        />
        <HorizontalGridLines style={dashedGridStyle} />
      </ResizableXYPlot>
    );
  }
}

Display.propTypes = {
  axisStartDate: PropTypes.instanceOf(DateTime).isRequired,
  years: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Display;
