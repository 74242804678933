import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { NAVY, YELLOW } from '../constants/_colors';

const LoaderSvg = styled.svg`
  max-width: ${props => props.width}px;
`;

const NavyCircle = styled.circle`
  fill: ${NAVY};
`;

const Ring = styled.circle`
  @keyframes svg-animation {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg)
    }
  }
  animation: .5s linear infinite svg-animation;
  display: block;
  fill: transparent;
  stroke-linecap: round;
  stroke-dasharray: 120;
  stroke-dashoffset: 200;
  stroke-width: 10px;
  transform-origin: 50% 50%;
`;

const Loader = ({ width, className }) => {
  return (
    <LoaderSvg className={className} width={width} viewBox="0 0 100 100">
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style={{ stopColor: YELLOW, stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: YELLOW, stopOpacity: 0 }} />
        </linearGradient>
      </defs>
      <NavyCircle cx="50" cy="50" r="50" />
      <Ring cx="50" cy="50" r="25" stroke="url(#gradient)" />
    </LoaderSvg>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
};

Loader.defaultProps = {
  className: undefined,
  width: 64,
};

export default Loader;
