import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StreetViewPanorama, Marker } from 'react-google-maps';
import { flowRight } from 'lodash';
import Map from './Map';
import { PlusIcon, MinusIcon } from './ControlIcons';
import { withSiteDetails } from '../../queries/withSiteQueries';

const markerIcon = {
  url: '/images/markers/blue_transparent.svg',
  scaledSize: {
    height: 58,
    width: 41,
  },
};

class StreetMapView extends PureComponent {
  constructor() {
    super();
    this.handleOnLoad = this.handleOnLoad.bind(this);
    this.handleZoomChanged = this.handleZoomChanged.bind(this);
    this.handleZoomClick = this.handleZoomClick.bind(this);
    this.streetRef = null;
    this.state = {
      hasSetHeading: false,
      zoom: 1,
    };
  }

  handleOnLoad(ref) {
    const { siteDetailsQuery: { site } } = this.props;
    const { hasSetHeading } = this.state;
    if (!hasSetHeading && ref && window.google) {
      this.streetRef = ref;
      const streetViewService = new window.google.maps.StreetViewService();
      const endPoint = new window.google.maps.LatLng(Number(site.address.lat), Number(site.address.lng) || Number(site.address.lon));
      streetViewService.getPanoramaByLocation(endPoint, 100, (data, status) => {
        if (status === 'OK') {
          const { latLng } = data.location;
          const startPoint = new window.google.maps.LatLng(latLng.lat(), latLng.lng());
          const computedHeading = window.google.maps.geometry.spherical.computeHeading(startPoint, endPoint);
          ref.getStreetView().setPov({
            heading: computedHeading,
            pitch: 0,
          });
          this.setState({ hasSetHeading: true });
        }
      });
    }
  }

  handleZoomChanged() {
    if (this.streetRef && this.streetRef.getStreetView()) {
      this.setState({ zoom: this.streetRef.getStreetView().zoom });
    }
  }

  handleZoomClick(offset) {
    if (this.streetRef && this.streetRef.getStreetView()) {
      const currentZoom = this.streetRef.getStreetView().zoom;
      this.setState({ zoom: currentZoom + offset });
    }
  }

  render() {
    const { siteDetailsQuery: { site } } = this.props;
    const { zoom } = this.state;
    return (
      <Map
        mapProps={{
          defaultZoom: 20,
          ref: this.handleOnLoad,
        }}
      >
        <StreetViewPanorama
          onZoomChanged={this.handleZoomChanged}
          zoom={zoom}
          options={{
            panControl: false,
            enableCloseButton: false,
            fullscreenControl: false,
            zoomControl: false,
          }}
          position={{
            lat: Number(site.address.lat),
            lng: Number(site.address.lng) || Number(site.address.lon),
          }}
          visible
        />
        <Marker
          position={{
            lat: Number(site.address.lat),
            lng: Number(site.address.lng) || Number(site.address.lon),
          }}
          // icon="/images/markers/blue.svg"
          icon={markerIcon}
        />
        <PlusIcon name="BluePlusIcon" onClick={() => this.handleZoomClick(0.1)} />
        <MinusIcon name="BlueMinusIcon" onClick={() => this.handleZoomClick(-0.1)} />
      </Map>
    );
  }
}

StreetMapView.propTypes = {
  siteDetailsQuery: PropTypes.shape({
    site: PropTypes.shape({
      address: PropTypes.shape({
        lat: PropTypes.string,
        lng: PropTypes.string,
        lon: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default flowRight(withSiteDetails)(StreetMapView);
