import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { createSort } from '../general/utils/utils';

const headerColumns = [
  {
    id: 'prospectId',
    align: 'left',
    label: 'Prospect id',
  },
  {
    id: 'vendor',
    align: 'left',
    label: 'Vendor',
  },
  {
    id: 'totalEnergy',
    align: 'left',
    label: 'Total Production',
  },
  {
    id: 'totalEnergyExpected',
    align: 'left',
    label: 'Total Expected Production',
  },
];

const Container = styled(Paper)({
  margin: 20,
  padding: 20,
});

const TableHeightContainer = styled(TableContainer)({
  maxHeight: 500,
});

const SystemFailureTable = ({ data }) => {
  const [tablePage, setTablePage] = useState(0);
  const [tableRowCount, setTableRowCount] = useState(25);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState(null);
  const handleSort = (id) => {
    if (sortColumn === id) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrder('asc');
      setSortColumn(id);
    }
  };
  const sortFunc = createSort(sortColumn, sortOrder === 'asc' ? -1 : 1);

  return (
    <Container elevation={3}>
      <TableHeightContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headerColumns.map(cell => (
                <TableCell
                  key={cell.id}
                  align={cell.align}
                >
                  <TableSortLabel
                    active={sortColumn === cell.id}
                    direction={sortColumn === cell.id ? sortOrder : 'asc'}
                    onClick={() => handleSort(cell.id)}
                  >
                    {cell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.sort(sortFunc)
              .slice(tablePage * tableRowCount, tablePage * tableRowCount + tableRowCount)
              .map(row => (
                <TableRow
                  hover
                  key={row.prospectId}
                  onClick={() => window.open(`${window.location.origin}/site/${row.prospectId}`, '_blank')}
                >
                  <TableCell align="left">{row.prospectId}</TableCell>
                  <TableCell align="left">{row.vendor}</TableCell>
                  <TableCell align="left">{row.totalEnergy}</TableCell>
                  <TableCell align="left">{row.totalEnergyExpected}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableHeightContainer>
      <TablePagination
        component="div"
        count={data.length}
        onChangePage={(e, page) => setTablePage(page)}
        page={tablePage}
        rowsPerPage={tableRowCount}
        rowsPerPageOptions={[10, 25, 50]}
        onChangeRowsPerPage={(e) => {
          setTableRowCount(parseInt(e.target.value, 10));
          setTablePage(0);
        }}
      />
    </Container>
  );
};

SystemFailureTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    prospectId: PropTypes.string,
    vendor: PropTypes.string,
    totalEnergy: PropTypes.number,
    totalEnergyExpected: PropTypes.number,
  })).isRequired,
};

export default SystemFailureTable;
