import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const prospectHeaderColumns = [
  {
    id: 'prospectId',
    label: 'Prospect id',
  },
  {
    id: 'totalDeliveredKwhSum',
    label: 'Total Energy',
  },
  {
    id: 'expectedGeneration',
    label: 'Expected Generation',
  },
  {
    id: 'errors',
    label: 'Errors',
  },
];

const invertersHeaderColumns = [
  {
    id: 'inverterManufacturer',
    label: 'Manufacturer',
  },
  {
    id: 'inverterModel',
    label: 'Model',
  },
  {
    id: 'inverterSerialNumber',
    label: 'Serial Number',
  },
  {
    id: 'inverterActivationDate',
    label: 'Activation Date',
  },
];

const metersHeaderColumns = [
  {
    id: 'meterManufacturer',
    label: 'Manufacturer',
  },
  {
    id: 'meterModel',
    label: 'Model',
  },
  {
    id: 'meterSerialNumber',
    label: 'Serial Number',
  },
  {
    id: 'meterActivationDate',
    label: 'Activation Date',
  },
];

const peerHeaderColumns = [
  {
    id: 'peerProspectId',
    label: 'Prospect id',
  },
  {
    id: 'totalExpectedGen',
    label: 'Total Energy',
  },
  {
    id: 'deliveredSum',
    label: 'Expected Generation',
  },
];

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const LeftContainer = styled('div')({
  width: '70%',
});

const IndividualContainer = styled(Paper)({
  margin: 20,
  padding: 20,
});

const PeerIndividualContainer = styled(IndividualContainer)({
  height: 'fit-content',
  maxHeight: 500,
  width: '35%',
});

const TableHeightContainer = styled(TableContainer)({
  maxHeight: 500,
});

const ProspectDetailsTable = ({ data }) => {
  const getErrorDisplay = () => {
    return [
      data.getProspect.isNonComm ? 'Non Comm' : '',
      data.getProspect.isSystemFailure ? 'System Failure' : '',
      data.getProspect.isUnderPerformance ? 'Under Performance' : '',
    ].filter(e => e).join(', ') || 'None';
  };
  return (
    <Container>
      <LeftContainer>
        <IndividualContainer elevation={3}>
          <h2>Prospect Details</h2>
          <TableHeightContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {prospectHeaderColumns.map(cell => (
                    <TableCell
                      key={cell.id}
                      align="left"
                    >
                      {cell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  hover
                  onClick={() => window.open(`${window.location.origin}/site/${data.getProspect.prospectId}`, '_blank')}
                >
                  <TableCell align="left"><b>{data.getProspect.prospectId}</b></TableCell>
                  <TableCell align="left">{Number(data.getProspect.totalDeliveredKwhSum).toFixed(2)}</TableCell>
                  <TableCell align="left">{Number(data.getProspect.expectedGeneration).toFixed(2)}</TableCell>
                  <TableCell align="left">{getErrorDisplay()}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableHeightContainer>
        </IndividualContainer>
        <IndividualContainer elevation={3}>
          <h2>Inverter Details</h2>
          <TableHeightContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {invertersHeaderColumns.map(cell => (
                    <TableCell
                      key={cell.id}
                      align="left"
                    >
                      {cell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.getSysChars.inverters.map(i => (
                  <TableRow key={`inverter${i.serialNumber}`}>
                    <TableCell align="left">{i.manufacturer}</TableCell>
                    <TableCell align="left">{i.model}</TableCell>
                    <TableCell align="left">{i.serialNumber}</TableCell>
                    <TableCell align="left">{i.activationDate.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHeightContainer>
        </IndividualContainer>
        <IndividualContainer elevation={3}>
          <h2>Meter Details</h2>
          <TableHeightContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {metersHeaderColumns.map(cell => (
                    <TableCell
                      key={cell.id}
                      align="left"
                    >
                      {cell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.getSysChars.meters.map(i => (
                  <TableRow key={`inverter${i.serialNumber}`}>
                    <TableCell align="left">{i.manufacturer || i.meterType}</TableCell>
                    <TableCell align="left">{i.model}</TableCell>
                    <TableCell align="left">{i.serialNumber}</TableCell>
                    <TableCell align="left">{i.activationDate.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableHeightContainer>
        </IndividualContainer>
      </LeftContainer>
      <PeerIndividualContainer>
        <h2>Peers</h2>
        <TableHeightContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {peerHeaderColumns.map(cell => (
                  <TableCell
                    key={cell.id}
                    align="left"
                  >
                    {cell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.getProspect.peers.map(peer => (
                <TableRow
                  hover
                  key={`peer${peer.prospectId}`}
                  onClick={() => window.open(`${window.location.origin}/site/${peer.prospectId}`, '_blank')}
                >
                  <TableCell align="left">{peer.prospectId}</TableCell>
                  <TableCell align="left">{Number(peer.totalEnergyProduced).toFixed(2)}</TableCell>
                  <TableCell align="left">{Number(peer.expectedGeneration).toFixed(2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableHeightContainer>
      </PeerIndividualContainer>
    </Container>
  );
};

ProspectDetailsTable.propTypes = {
  data: PropTypes.shape({
    getProspect: PropTypes.shape({
      expectedGeneration: PropTypes.number,
      isNonComm: PropTypes.bool,
      isSystemFailure: PropTypes.bool,
      isUnderPerformance: PropTypes.bool,
      meters: PropTypes.arrayOf(PropTypes.shape({
        isActive: PropTypes.bool,
        serialNumber: PropTypes.string,
      })),
      peers: PropTypes.arrayOf(PropTypes.shape({
        prospectId: PropTypes.string,
      })),
      prospectId: PropTypes.string,
      totalDeliveredKwhSum: PropTypes.number,
      vendor: PropTypes.arrayOf(PropTypes.string),
    }),
    getSysChars: PropTypes.shape({
      inverters: PropTypes.arrayOf(PropTypes.shape({
        activationDate: PropTypes.shape({
          value: PropTypes.string,
        }),
        manufacturer: PropTypes.string,
        model: PropTypes.string,
        serialNumber: PropTypes.string,
      })),
      meters: PropTypes.arrayOf(PropTypes.shape({
        activationDate: PropTypes.shape({
          value: PropTypes.string,
        }),
        manufacturer: PropTypes.string,
        meterType: PropTypes.string,
        model: PropTypes.string,
        serialNumber: PropTypes.string,
      })),
    }),
  }).isRequired,
};

export default ProspectDetailsTable;
