import {
  BLUE,
  DARK_RED,
  GREEN,
  RED,
  YELLOW,
} from './_colors';

export const validAlertTypes = {
  environ_event: DARK_RED,
  inv_fail: false,
  no_known_issues: GREEN,
  non_comm: BLUE,
  non_nighttime: false,
  over_performance: false,
  performance_decline: false,
  sudden_perf_drop: false,
  sys_fail: RED,
  sysact_non_comm: false,
  sysact_non_nighttime: false,
  sysact_sys_fail: false,
  sysact_under_performance: false,
  under_performance: YELLOW,
};
