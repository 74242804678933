const getSurroundingValues = (i, values, amountOfPrevious = 4, amountOfFutures = 4) => {
  let beginningIndex;
  if (i <= amountOfPrevious) {
    beginningIndex = 0;
  } else {
    beginningIndex = i - amountOfPrevious;
  }

  let endingIndex;
  if (i > values.length - amountOfFutures) {
    endingIndex = values.length;
  } else {
    endingIndex = i + amountOfFutures;
  }

  const surroundingValues = values.slice(beginningIndex, endingIndex);
  return surroundingValues;
};

export const transformValuesToMaxOfSurroundingValues = (lessNormalCoordinates) => {
  const moreNormals = lessNormalCoordinates
    .map((c, i) => {
      const valuesToConsider = getSurroundingValues(i, lessNormalCoordinates);
      const theBiggestValue = valuesToConsider
        .map(coord => coord.y)
        .reduce(
          (theBiggestNumberSoFar, number) => Math.max(theBiggestNumberSoFar, number), 0,
        );

      return { ...c, y: theBiggestValue };
    });

  return moreNormals;
};

export const transformValuesToAverageOfSurroundingValues = (wildCoordinates) => {
  const averagedCoordinates = wildCoordinates.map((c, i) => {
    const valuesToConsider = getSurroundingValues(i, wildCoordinates);
    const sum = valuesToConsider.reduce((acc, curr) => acc + Number(curr.y), 0);
    const average = sum / valuesToConsider.length;
    return { ...c, y: average };
  });

  return averagedCoordinates;
};

export const noTransform = coordinates => coordinates;
