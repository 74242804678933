// BLACKS
export const BLACK = '#000000';
export const CHARCOAL = '#4A4A4A';
export const MINE_SHAFT = '#333333';
export const VAMPIRE_BLACK = '#080808';

// BLUES
export const BLUE = '#0087FF';
export const LIGHT_BLUE = '#06A7E0';
export const NAVY = '#001A3C';

// GREENS
export const DARK_GREEN = '#357049';
export const GREEN = '#00B085';
export const OLIVE = '#8CC16F';
export const PRIMARY_GREEN = '#77C0A5';
export const PRIMARY_GREEN_OPAQUE_DARK = '#77C0A5BF';
export const PRIMARY_GREEN_OPAQUE_TEN = '#77C0A510';

// GREYS
export const CCC = '#CCCCCC';
export const DARK_GREY = '#808080';
export const DARK_SLATE_GREY = '#2f4f4f';
export const DOLPHIN_GREY = '#EEEEEE';
export const FADED_GREY = '#B2ADA6';
export const GREY = '#DEDBD6';
export const LIGHT_GREY = '#f8f8f8';
export const TRANSPARENT_GREY = '8282827F';

// REDS
export const DARK_RED = '#A5000A';
export const RED = '#FF0000';

// MISC
export const ORANGE = '#FFB26D';
export const PINK = '#E200C1';
export const WHITE = '#FFFFFF';
export const YELLOW = '#FFD45C';
