/* eslint-disable no-restricted-globals */
import { get } from 'lodash';

export const unwrapGraphQlList = (obj, pathToEdges) => get(obj, pathToEdges, []).map(edge => edge.node);

export const isNumeric = number => !isNaN(number);

export const createSort = (field, direction) => (a, b) => {
  if (!a[field]) return -direction;
  if (!b[field]) return direction;
  if (a[field] > b[field]) {
    return direction;
  }
  if (a[field] < b[field]) {
    return -direction;
  }
  return 0;
};
