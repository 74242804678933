import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { styled } from '@material-ui/core/styles';

const Container = styled(Paper)({
  margin: 20,
  padding: 20,
  display: 'flex',
  justifyContent: 'space-evenly',
});

const SystemFailureControls = ({
  endDate,
  handleSysFailureSubmit,
  isActionLoading,
  startDate,
  threshold,
}) => {
  const [pickerStartDate, setPickerStartDate] = useState(startDate);
  const [pickerEndDate, setPickerEndDate] = useState(endDate);
  const [pickerThreshold, setPickerThreshold] = useState(threshold);

  return (
    <Container elevation={3}>
      <DatePicker
        label="Start Date"
        value={pickerStartDate}
        onChange={setPickerStartDate}
        autoOk
        format="MM/dd/yyyy"
      />
      <DatePicker
        label="End Date"
        value={pickerEndDate}
        onChange={setPickerEndDate}
        autoOk
        format="MM/dd/yyyy"
      />
      <FormControl>
        <InputLabel>Threshold(kWh)</InputLabel>
        <Select
          value={pickerThreshold}
          onChange={e => setPickerThreshold(e.target.value)}
        >
          <MenuItem value="1">1</MenuItem>
          <MenuItem value="2">2</MenuItem>
          <MenuItem value="3">3</MenuItem>
          <MenuItem value="4">4</MenuItem>
          <MenuItem value="5">5</MenuItem>
          <MenuItem value="6">6</MenuItem>
          <MenuItem value="7">7</MenuItem>
          <MenuItem value="8">8</MenuItem>
          <MenuItem value="9">9</MenuItem>
          <MenuItem value="10">10</MenuItem>
        </Select>
      </FormControl>
      <Button
        disabled={isActionLoading}
        onClick={() => handleSysFailureSubmit({
          startDate: pickerStartDate,
          endDate: pickerEndDate,
          threshold: pickerThreshold,
        })}
        variant="contained"
      >
        Get System Failures
      </Button>
    </Container>
  );
};

SystemFailureControls.propTypes = {
  endDate: PropTypes.instanceOf(DateTime).isRequired,
  handleSysFailureSubmit: PropTypes.func.isRequired,
  isActionLoading: PropTypes.bool.isRequired,
  startDate: PropTypes.instanceOf(DateTime).isRequired,
  threshold: PropTypes.string.isRequired,
};

export default SystemFailureControls;
