const prodConfig = {
  anomalyDetectionService: {
    baseUrl: 'https://solar-flares-cf-m46t4v3h5q-uc.a.run.app/solarflares',
    apiKey: 'AIzaSyA6cPg4evOZTEjjxGTVdrUq0E_-TkAjb44',
  },
  dataPlatform: {
    root: 'prd-data-platform.appspot.com',
    graphQLUri: 'https://prd-data-platform.appspot.com/noc/graphql',
    versionUri: 'https://prd-data-platform.appspot.com/versions',
  },
  hybridAuth: {
    issuer: 'ausm2etks8CsoAl9Y0x7',
    url: 'https://gateway.sunrun.com/hybrid-auth/hybridTokens',
  },
  maps: {
    apiKey: 'AIzaSyA_s18K8ckhSfv_HzQ8Tju9B5NOjt30uis',
  },
  mixpanelKey: '91b8e74dd9640a825aa0a1f9fc509915',
  oktaConfigUrl: 'https://s3-us-west-2.amazonaws.com/prd-noc2-okta-client-config/prdOktaClientConfig.json',
  oktaEnvironment: 'Prd',
  performanceApiBaseUrl: 'https://gateway.sunrun.com/performance-api',
  rollbar: {
    accessToken: 'a0cfdd787e054a52a5258d843df5b3a7',
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: true,
    payload: {
      environment: 'prod',
      client: {
        javascript: {
          source_map_enabled: true,
        },
      },
    },
    transform(payload) {
      const { trace } = payload.body;
      const locRegex = /^(https?):\/\/[a-zA-Z0-9._-]+\.(noc.sunrun)\.com(.*)/;
      if (trace && trace.frames) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < trace.frames.length; i++) {
          const { filename } = trace.frames[i];
          if (filename) {
            const m = filename.match(locRegex);
            // changed m[1] => m[3] because (noc.sunrun) creates a new match group
            trace.frames[i].filename = `${m[1]}://dynamichost${m[3]}`;
            // m[1] = (https?), m[2] = (noc.sunrun), m[3] = bundle name (everything after .com)
            // example result: https://dynamichost/bundle.app.8as232as.js
          }
        }
      }
    },
  },
};

export default prodConfig;
