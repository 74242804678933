import { styled } from '@material-ui/core/styles';
import {
  Button,
  Chip,
  TableBody,
  TableCell,
} from '@material-ui/core';
import {
  BLACK,
  LIGHT_GREY,
  PRIMARY_GREEN,
  PRIMARY_GREEN_OPAQUE_DARK,
  PRIMARY_GREEN_OPAQUE_TEN,
  RED,
  WHITE,
} from '../../../general/constants/_colors';

export const StyledTabledCell = styled(TableCell)({
  border: 'none',
});

export const StyledButtonCancel = styled(Button)({
  color: BLACK,
  padding: '8px 11px',
  textDecoration: 'underline',
  '&:hover': {
    textDecoration: 'underline',
  },
});

export const StyledButtonContainer = styled('div')({
  alignItems: 'baseline',
  display: 'flex',
  '& > *': {
    marginRight: 32,
  },
});

export const StyledButtonUpload = styled(Button)({
  border: `2px solid ${PRIMARY_GREEN}`,
  backgroundColor: PRIMARY_GREEN,
  color: WHITE,
  '&:hover': {
    backgroundColor: PRIMARY_GREEN_OPAQUE_DARK,
  },
});

export const StyledErrorCell = styled('p')({
  marginBottom: 8,
  minHeight: 20,
});

export const StyledErrorHeader = styled('div')({
  alignItems: 'center',
  borderTop: `2px solid ${RED}`,
  color: RED,
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px 0',
});

export const StyledErrorText = styled('p')({
  color: RED,
  minHeight: 20,
});

export const StyledFileInputLabel = styled('div')({
  border: `2px solid ${PRIMARY_GREEN}`,
  borderRadius: 4,
  color: PRIMARY_GREEN,
  cursor: 'pointer',
  fontSize: 16,
  padding: '8px 11px',
  transition: 'background 250ms',
  '&:hover': {
    backgroundColor: PRIMARY_GREEN_OPAQUE_TEN,
  },
});

export const StyledPageContainer = styled('div')({
  color: BLACK,
  padding: 40,
});

export const StyledSampleCSVDownload = styled('div')({
  color: BLACK,
  cursor: 'pointer',
  fontSize: 16,
  padding: '8px 11px',
  transition: 'background 250ms',
  textDecoration: 'underline',
  '&:hover': {
    backgroundColor: LIGHT_GREY,
    textDecoration: 'underline',
  },
});

export const StyledSuccessChip = styled(Chip)({
  backgroundColor: PRIMARY_GREEN,
  color: WHITE,
});

export const StyledTableBody = styled(TableBody)({
  '& tr': {
    '&:nth-of-type(odd)': {
      backgroundColor: LIGHT_GREY,
    },
  },
});

export const adHocStyles = {
  fileInput: {
    height: 0,
    overflow: 'hidden',
    width: 0,
  },
  textSpacing: {
    marginBottom: 16,
  },
};
