import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Security, ImplicitCallback, SecureRoute } from '@okta/okta-react';
import mixpanel from 'mixpanel-browser';
import config from '../../config';
import { fetchJSONFile } from '../utils/JsonUtils';
import Routes from './Routes';
import 'react-vis/dist/style.css';
import ErrorBoundary from '../components/ErrorBoundary';

const AppContainer = () => {
  const [oktaConfig, setOktaConfig] = useState();
  const getConfig = useCallback(async () => {
    const configFile = await fetchJSONFile(config.oktaConfigUrl);
    setOktaConfig(configFile);
  }, []);
  useEffect(() => {
    mixpanel.init(config.mixpanelKey);
    getConfig();
  }, [getConfig]);
  if (!oktaConfig) return null;
  return (
    <ErrorBoundary>
      <Router>
        <Security
          issuer={oktaConfig.issuer}
          client_id={oktaConfig.clientId}
          redirect_uri={oktaConfig.redirectUri}
        >
          <Switch>
            <Route path="/implicit/callback" exact component={ImplicitCallback} />
            <SecureRoute path="/" component={Routes} />
          </Switch>
        </Security>
      </Router>
    </ErrorBoundary>
  );
};

export default AppContainer;
