import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { Tabs, Tab } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import config from '../config';
import SystemFailureControls from './SystemFailureControls';
import SystemFailureTable from './SystemFailureTable';
import NonCommControls from './NonCommControls';
import NonCommTable from './NonCommTable';
import UnderPerfControls from './UnderPerfControls';
import UnderPerfTable from './UnderPerfTable';
import ExponentialValueControls from './ExponentialValueControls';
import ExponentialValueTable from './ExponentialValueTable';
import ErrorSitesControls from './ErrorSitesControls';
import ErrorSitesTable from './ErrorSitesTable';
import AdsRulesControls from './AdsRulesControls';
import AdsRulesTable from './AdsRulesTable';
import ProspectDetailsControls from './ProspectDetailsControls';
import ProspectDetailsTable from './ProspectDetailsTable';

const adsUrl = config.anomalyDetectionService.baseUrl;
const adsKey = config.anomalyDetectionService.apiKey;

const AnomalyDetectionService = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [systemFailureState, setSystemFailureState] = useState({
    data: null,
    endDate: DateTime.local(),
    startDate: DateTime.local().minus({ days: 1 }),
    threshold: '2',
  });
  const [nonCommState, setNonCommState] = useState({
    data: null,
    dayCount: '10',
  });
  const [underPerfState, setUnderPerfState] = useState({
    data: null,
    endDate: DateTime.local(),
    startDate: DateTime.local().minus({ days: 3 }),
  });
  const [exponentialValueState, setExponentialValueState] = useState({
    data: null,
  });
  const [errorSitesState, setErrorSitesState] = useState({
    data: null,
    endDate: DateTime.local(),
    startDate: DateTime.local().minus({ days: 1 }),
  });
  const [adsRulesState, setAdsRulesState] = useState({
    activeEndpoints: {
      errorSites: true,
      nonComm: true,
      nullNan: true,
      sysFailure: true,
      underPerf: true,
    },
    data: null,
    dayCount: '10',
    endDate: DateTime.local(),
    startDate: DateTime.local().minus({ days: 3 }),
    threshold: '2',
  });

  const [prospectDetailsState, setProspectDetailsState] = useState({
    data: {},
    endDate: DateTime.local(),
    prospectId: '',
    startDate: DateTime.local().minus({ days: 7 }),
  });

  const handleSysFailureSubmit = async ({
    endDate,
    startDate,
    threshold,
  }) => {
    setIsActionLoading(true);
    const startString = startDate.toFormat('yyyy-MM-dd');
    const endString = endDate.toFormat('yyyy-MM-dd');
    const resp = await fetch(
      `${adsUrl}/ads/thresholdCheck?startDate=${startString}&endDate=${endString}&thresholdValue=${threshold}`,
      {
        method: 'GET',
        headers: {
          'x-api-key': adsKey,
        },
      },
    );
    const data = await resp.json();
    setSystemFailureState({
      data,
      endDate,
      startDate,
      threshold,
    });
    setIsActionLoading(false);
  };

  const handleNonCommSubmit = async ({
    dayCount,
  }) => {
    setIsActionLoading(true);
    const resp = await fetch(
      `${adsUrl}/ads/lastReadingBeforeDays?noOfDays=${dayCount}`,
      {
        method: 'GET',
        headers: {
          'x-api-key': adsKey,
        },
      },
    );
    const data = await resp.json();
    const shapedData = data.map(d => ({
      ...d,
      lastReadingDate: DateTime.fromISO(d.maxDate.value),
    }));
    setNonCommState({
      data: shapedData,
      dayCount,
    });
    setIsActionLoading(false);
  };

  const handleUnderPerfSubmit = async ({
    endDate,
    startDate,
  }) => {
    setIsActionLoading(true);
    const startString = startDate.toFormat('yyyy-MM-dd');
    const endString = endDate.toFormat('yyyy-MM-dd');
    const resp = await fetch(
      `${adsUrl}/ads/underPerformance?startDate=${startString}&endDate=${endString}`,
      {
        method: 'GET',
        headers: {
          'x-api-key': adsKey,
        },
      },
    );
    const data = await resp.json();
    setUnderPerfState({
      data,
      endDate,
      startDate,
    });
    setIsActionLoading(false);
  };

  const handleExponentialValueSubmit = async () => {
    setIsActionLoading(true);
    const resp = await fetch(
      `${adsUrl}/ads/exponentialCheck`,
      {
        method: 'GET',
        headers: {
          'x-api-key': adsKey,
        },
      },
    );
    const data = await resp.json();
    setExponentialValueState({
      data,
    });
    setIsActionLoading(false);
  };

  const handleErrorSitesSubmit = async ({
    endDate,
    startDate,
  }) => {
    setIsActionLoading(true);
    const startString = startDate.toFormat('yyyy-MM-dd');
    const endString = endDate.toFormat('yyyy-MM-dd');
    const resp = await fetch(
      `${adsUrl}/ads/listAllErrors?startDate=${startString}&endDate=${endString}`,
      {
        method: 'GET',
        headers: {
          'x-api-key': adsKey,
        },
      },
    );
    const data = await resp.json();
    setErrorSitesState({
      data,
      endDate,
      startDate,
    });
    setIsActionLoading(false);
  };

  const handleAdsRulesSubmit = ({
    activeEndpoints,
    dayCount,
    endDate,
    startDate,
    threshold,
  }) => {
    setIsActionLoading(true);
    const startString = startDate.toFormat('yyyy-MM-dd');
    const endString = endDate.toFormat('yyyy-MM-dd');
    const allRespData = {};
    const sysFailure = async () => {
      const resp = await fetch(
        `${adsUrl}/ads/thresholdCheck?startDate=${startString}&endDate=${endString}&thresholdValue=${threshold}`,
        {
          method: 'GET',
          headers: {
            'x-api-key': adsKey,
          },
        },
      );
      const data = await resp.json();
      allRespData.sysFailure = data;
    };
    const nonComm = async () => {
      const resp = await fetch(
        `${adsUrl}/ads/lastReadingBeforeDays?noOfDays=${dayCount}`,
        {
          method: 'GET',
          headers: {
            'x-api-key': adsKey,
          },
        },
      );
      const data = await resp.json();
      const shapedData = data.map(d => ({
        ...d,
        lastReadingDate: DateTime.fromISO(d.maxDate.value),
      }));
      allRespData.nonComm = shapedData;
    };
    const underPerf = async () => {
      const resp = await fetch(
        `${adsUrl}/ads/underPerformance?startDate=${startString}&endDate=${endString}`,
        {
          method: 'GET',
          headers: {
            'x-api-key': adsKey,
          },
        },
      );
      const data = await resp.json();
      allRespData.underPerf = data;
    };
    const nullNan = async () => {
      const resp = await fetch(
        `${adsUrl}/ads/nullNanCheck?startDate=${startString}&endDate=${endString}`,
        {
          method: 'GET',
          headers: {
            'x-api-key': adsKey,
          },
        },
      );
      const data = await resp.json();
      allRespData.nullNan = data;
    };
    const errorSites = async () => {
      const resp = await fetch(
        `${adsUrl}/ads/listAllErrors?startDate=${startString}&endDate=${endString}`,
        {
          method: 'GET',
          headers: {
            'x-api-key': adsKey,
          },
        },
      );
      const data = await resp.json();
      allRespData.errorSites = data;
    };
    const allEndpoints = {
      sysFailure, nonComm, underPerf, nullNan, errorSites,
    };
    const activeEndpointFunctions = Object.keys(activeEndpoints)
      .filter(e => activeEndpoints[e])
      .map(e => allEndpoints[e]());
    Promise.all(activeEndpointFunctions)
      .then(() => {
        const cleanedData = Object.keys(allRespData)
          .reduce((acc, key) => {
            allRespData[key].map((reading) => {
              if (!reading.prospectId) return reading;
              if (acc[reading.prospectId]) {
                acc[reading.prospectId][key] = 'True';
                return reading;
              }
              acc[reading.prospectId] = {
                prospectId: reading.prospectId,
                [key]: 'True',
              };
              return reading;
            });
            return acc;
          }, {});
        setAdsRulesState({
          activeEndpoints,
          data: Object.values(cleanedData),
          dayCount,
          endDate,
          startDate,
          threshold,
        });
        setIsActionLoading(false);
      });
  };

  const handleProspectDetailsSubmit = ({
    endDate,
    prospectId,
    startDate,
  }) => {
    if (!prospectId) return;
    setIsActionLoading(true);
    const startString = startDate.toFormat('yyyy-MM-dd');
    const endString = endDate.toFormat('yyyy-MM-dd');
    const allRespData = {};
    const getProspect = async () => {
      const resp = await fetch(
        `${adsUrl}/prospectDetails/getProspect?startDate=${startString}&endDate=${endString}&prospectId=${prospectId}`,
        {
          method: 'GET',
          headers: {
            'x-api-key': adsKey,
          },
        },
      );
      const data = await resp.json();
      allRespData.getProspect = data || {};
    };
    const getSysChars = async () => {
      const resp = await fetch(
        `${adsUrl}/prospectDetails/getSysChars?startDate=${startString}&endDate=${endString}&prospectId=${prospectId}`,
        {
          method: 'GET',
          headers: {
            'x-api-key': adsKey,
          },
        },
      );
      const data = await resp.json();
      const prospectData = data[0];
      allRespData.getSysChars = prospectData || {};
    };
    const getWeather = async () => {
      const resp = await fetch(
        `${adsUrl}/peerService/getWeather?startDate=${startString}&endDate=${endString}&prospectId=${prospectId}`,
        {
          method: 'GET',
          headers: {
            'x-api-key': adsKey,
          },
        },
      );
      const data = await resp.json();
      allRespData.getWeather = data;
    };
    Promise.all([
      getProspect(),
      getSysChars(),
      getWeather(),
    ])
      .then(() => {
        setProspectDetailsState({
          data: allRespData,
          endDate,
          prospectId,
          startDate,
        });
        setIsActionLoading(false);
      });
  };

  const handleTabChange = (e, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="System Failure" />
          <Tab label="Non Comm" />
          <Tab label="Under Performance" />
          <Tab label="Exponential Value" />
          <Tab label="Prospect With Errors" />
          <Tab label="ADS Rules" />
          <Tab label="Prospect Details" />
        </Tabs>
        {activeTab === 0 && (
          <>
            <SystemFailureControls
              {...systemFailureState}
              handleSysFailureSubmit={handleSysFailureSubmit}
              isActionLoading={isActionLoading}
            />
            {systemFailureState.data && (
              <SystemFailureTable data={systemFailureState.data} />
            )}
          </>
        )}
        {activeTab === 1 && (
          <>
            <NonCommControls
              {...nonCommState}
              handleNonCommSubmit={handleNonCommSubmit}
              isActionLoading={isActionLoading}
            />
            {nonCommState.data && (
              <NonCommTable data={nonCommState.data} />
            )}
          </>
        )}
        {activeTab === 2 && (
          <>
            <UnderPerfControls
              {...underPerfState}
              handleUnderPerfSubmit={handleUnderPerfSubmit}
              isActionLoading={isActionLoading}
            />
            {underPerfState.data && (
              <UnderPerfTable data={underPerfState.data} />
            )}
          </>
        )}
        {activeTab === 3 && (
          <>
            <ExponentialValueControls
              handleExponentialValueSubmit={handleExponentialValueSubmit}
              isActionLoading={isActionLoading}
            />
            {exponentialValueState.data && (
              <ExponentialValueTable data={exponentialValueState.data} />
            )}
          </>
        )}
        {activeTab === 4 && (
          <>
            <ErrorSitesControls
              {...errorSitesState}
              handleErrorSitesSubmit={handleErrorSitesSubmit}
              isActionLoading={isActionLoading}
            />
            {errorSitesState.data && (
              <ErrorSitesTable data={errorSitesState.data} />
            )}
          </>
        )}
        {activeTab === 5 && (
          <>
            <AdsRulesControls
              {...adsRulesState}
              handleAdsRulesSubmit={handleAdsRulesSubmit}
              isActionLoading={isActionLoading}
            />
            {adsRulesState.data && (
              <AdsRulesTable
                activeEndpoints={adsRulesState.activeEndpoints}
                data={adsRulesState.data}
              />
            )}
          </>
        )}
        {activeTab === 6 && (
          <>
            <ProspectDetailsControls
              {...prospectDetailsState}
              handleProspectDetailsSubmit={handleProspectDetailsSubmit}
              isActionLoading={isActionLoading}
            />
            {prospectDetailsState.data?.getProspect?.prospectId && (
              <ProspectDetailsTable data={prospectDetailsState.data} />
            )}
          </>
        )}
      </>
    </MuiPickersUtilsProvider>
  );
};

export default AnomalyDetectionService;
