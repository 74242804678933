import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import mixpanel from 'mixpanel-browser';
import styled from '@emotion/styled/macro';
import { DateTime } from 'luxon';
import memoize from 'memoize-one';
import Display from './Display';
import Interaction from './Interaction';
import DownloadOptions from './DownloadOptions';
import { DropDown } from '../../../general/components';
import { transformValuesToMaxOfSurroundingValues, noTransform, transformValuesToAverageOfSurroundingValues } from './transformers';
import {
  BLACK,
  BLUE,
  GREY,
  WHITE,
} from '../../../general/constants/_colors';
import { withDailyProduction } from '../../queries/withSiteQueries';
import { dailyProductionQueryType } from '../types';
import extrapolateColors, { rainbowColors } from './colors';
import { reduceCoordinatesByLabelToHoverValuesByDate } from './HistoricalCrosshair';
import { isNumeric } from '../../../general/utils/utils';

const isValidMeterReading = reading => isNumeric(reading.deliveredKwh);

const thisYear = new Date().getFullYear();
const setDateToThisYear = date => date.set({ year: thisYear });

const groupSiteProductionByYear = (siteProductionDaily) => {
  const yearsDictionary = siteProductionDaily
    .filter(isValidMeterReading)
    .reduce((dictionary, data) => {
      const theDate = DateTime.fromISO(data.readingEndTimeLocal);
      const year = theDate.get('year');
      // eslint-disable-next-line no-param-reassign
      dictionary[year] = dictionary[year] || [];
      dictionary[year].push({ ...data, dateTime: theDate });
      return dictionary;
    }, {});

  return yearsDictionary;
};

const getLineStyle = (color) => {
  return {
    stroke: color,
    strokeWidth: 1,
    fill: 'none',
  };
};

const Container = styled.div`
  display: flex;
`;

const Left = styled.div`
  flex: 1 1 auto;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 30px;
  justify-content: flex-end;
  padding-bottom: 10px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
`;

const Legend = styled.div`
  display: flex;
  justify-content: space-between;
`;

const YearToggle = styled.div`
  align-items: center;
  background-color: ${props => props.backgroundColor};
  color: ${WHITE};
  cursor: pointer;
  display: flex;
  font-size: 12px;
  height: 30px;
  line-height:20px;
  margin: 0 5px;
  padding: 0 10px;
`;

const ChartWrapper = styled.div`
  height: 310px;
  padding: 0 10px;
  position: relative;
`;

const DataGroupControl = styled.div`
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
`;

const DataGroupDropDown = styled(DropDown)`
  background-color: ${GREY};
  border: none;
  color: ${BLACK};
  height: 30px;
  margin-left: 10px;
  width: 150px;

  ul {
    background-color: ${GREY};
    border: none;
    top: 40px;
    left: 0;
    width: 100%;

    li {
      :hover {
        color: ${BLUE};
      }
    }
  }
`;

const FillAvailableSpace = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

class HistoricalPerformanceChart extends PureComponent {
  years = [];

  filteredYears = [];

  options = [{
    name: 'Normalized',
    value: 'normalized',
    transform: transformValuesToMaxOfSurroundingValues,
  },
  {
    name: 'Raw',
    value: 'raw',
    transform: noTransform,
  },
  {
    name: 'Averaged',
    value: 'averaged',
    transform: transformValuesToAverageOfSurroundingValues,
  }];

  transformReadingsToCoordinates = memoize(
    (readings, selectedOption) => {
      const yearsDictionary = groupSiteProductionByYear(readings);

      const colors = extrapolateColors(Object.keys(yearsDictionary).length, rainbowColors);

      const years = Object
        .entries(yearsDictionary)
        .map(([year, readingsWithDateTime], i) => {
          const coordinates = selectedOption
            .transform(readingsWithDateTime.map(readingWithDateTime => ({
            // Setting every date for each to this year, just on the chart, so we can overlay years on a time scale
            // Also only creating the DateTime object once in the group method it is expensive
              x: setDateToThisYear(readingWithDateTime.dateTime).startOf('day').toMillis(),
              y: Number(readingWithDateTime.deliveredKwh),
              ...readingWithDateTime,
            })));

          return {
            key: year,
            style: getLineStyle(colors[i]),
            coordinates,
          };
        });
      return years;
    },
  )

  reduceCoordinatesForHover = memoize((years) => {
    const hoverValues = reduceCoordinatesByLabelToHoverValuesByDate(
      years.reduce((obj, year) => {
        // eslint-disable-next-line no-param-reassign
        obj[year.key] = year.coordinates;
        return obj;
      }, {}),
    );
    return hoverValues;
  })

  constructor(props) {
    super(props);

    this.state = {
      disabledYears: new Set(),
      selectedOption: this.options[0],
    };
  }

  getTickValues = startDate => Array(12).fill(0)
    .map((el, index) => {
      return startDate.plus({ months: index }).toMillis();
    });

  getTickFormat = t => DateTime.fromMillis(t).toFormat('MMM');

  handleSetOption(option) {
    mixpanel.track('Historical Data Set Changed', { option });
    this.setState({ selectedOption: option });
  }

  handleToggleYear(year) {
    const { disabledYears: oldSet } = this.state;
    const disabledYears = new Set(oldSet);
    if (disabledYears.has(year)) {
      disabledYears.delete(year);
    } else {
      disabledYears.add(year);
    }

    this.setState({ disabledYears });
  }

  render() {
    const { dailyProductionQuery, prospectId } = this.props;
    const { siteProductionDaily } = dailyProductionQuery;
    if (!siteProductionDaily || !siteProductionDaily.length) return null;

    const axisStartDate = DateTime.local().startOf('year');
    const { disabledYears, selectedOption } = this.state;

    // Storing this value on the instance so that it can be used in a memoized function
    this.years = this.transformReadingsToCoordinates(siteProductionDaily, selectedOption);
    this.filteredYears = this.years
      .filter(y => !disabledYears.has(y.key));
    const hoverValues = this.reduceCoordinatesForHover(this.filteredYears);

    return (
      <Container>
        <Left>
          <Header>
            <DataGroupControl>
            Data Group
              <DataGroupDropDown
                iconName="DropdownIcon"
                name={selectedOption.name}
                onChange={(name, option) => this.handleSetOption(option)}
                options={this.options}
                placeholder={selectedOption.name}
              />
            </DataGroupControl>
            <Legend>
              {
              this.years.map(y => (
                <YearToggle key={y.key} backgroundColor={disabledYears.has(y.key) ? GREY : y.style.stroke} onClick={() => this.handleToggleYear(y.key)}>
                  {y.key}
                </YearToggle>
              ))
              }
            </Legend>
          </Header>

          <ChartWrapper>
            <Display
              axisStartDate={axisStartDate}
              years={this.filteredYears}
            />

            <FillAvailableSpace>
              <Interaction
                axisStartDate={axisStartDate}
                hoverValues={hoverValues}
                years={this.filteredYears}
              />
            </FillAvailableSpace>
          </ChartWrapper>
        </Left>
        <Right>
          <DownloadOptions
            anchorId="historical-performance-download-link"
            getDownloadableData={() => siteProductionDaily.map(reading => ({
              readingEndTimeLocal: reading.readingEndTimeLocal,
              deliveredKwh: reading.deliveredKwh,
            }))}
            fileName={`Historical Peformance ${prospectId}`}
          />

        </Right>
      </Container>
    );
  }
}

HistoricalPerformanceChart.propTypes = {
  dailyProductionQuery: dailyProductionQueryType.isRequired,
  prospectId: PropTypes.string.isRequired,
};

export default flowRight(withDailyProduction)(HistoricalPerformanceChart);
