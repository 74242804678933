import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import { Marker } from 'react-google-maps';
import PeerInfoBox from './PeerInfoBox';
import Map from './Map';
import { getMapIcon as getMarkerIcon } from './markerIcon';
import { GREEN } from '../../../general/constants/_colors';
import { validAlertTypes } from '../../../general/constants/_alertColors';
import { PlusIcon, MinusIcon } from './ControlIcons';
import {
  withOpenAlerts,
  withSitePeers,
  withSiteDetails,
} from '../../queries/withSiteQueries';

const housePath = 'M16.8896 34.7305V22.7305H24.8896V34.7305H34.8896V18.7305H40.8896L20.8896 0.730469L0.889648 18.7305H6.88965V34.7305H16.8896Z';

const houseIcon = color => (
  {
    path: housePath,
    fillColor: color,
    anchor: { x: 20, y: 17 },
    fillOpacity: 0.6,
    strokeWeight: 0,
    scale: 1,
    scaledSize: {
      width: 25,
      height: 35,
    },
  }
);

const markerIcon = color => (
  {
    url: `data:image/svg+xml;utf8, ${getMarkerIcon(color)}`,
    anchor: { x: 12, y: 17 },
    scaledSize: {
      width: 25,
      height: 35,
    },
  }
);

class PeerMapView extends PureComponent {
  constructor(props) {
    super(props);
    const { siteDetailsQuery } = props;
    this.handleSetZoom = this.handleSetZoom.bind(this);
    this.mapRef = null;
    this.state = {
      zoom: 15,
      infoBoxProspectId: null,
      center: {
        lat: Number(siteDetailsQuery.site.address.lat),
        lng: Number(siteDetailsQuery.site.address.lng) || Number(siteDetailsQuery.site.address.lon),
      },
    };
  }

  handleSetZoom(offset) {
    const { zoom } = this.state;
    if (zoom + offset > 0 && zoom + offset <= 20) {
      this.setState({
        zoom: zoom + offset,
      });
    }
  }

  render() {
    const {
      siteDetailsQuery: { site },
      openAlertsQuery: { openAlerts },
      sitePeersQuery: { sitePeers },
      prospectId,
    } = this.props;
    const {
      zoom,
      center,
      infoBoxProspectId,
    } = this.state;
    const houseAlert = openAlerts.length > 0 ? openAlerts[0] : null;
    const houseColor = houseAlert ? validAlertTypes[houseAlert.intRef] : GREEN;
    return (
      <Map
        mapProps={{
          options: {
            gestureHandling: 'greedy',
          },
          ref: (ref) => {
            this.mapRef = ref;
          },
          onClick: () => { this.setState({ infoBoxProspectId: null }); },
          zoom,
          center,
        }}
      >
        <Marker
          key={`marker-${prospectId}`}
          onClick={() => this.setState({ infoBoxProspectId: prospectId })}
          position={{
            lat: Number(site.address.lat),
            lng: Number(site.address.lng) || Number(site.address.lon),
          }}
          icon={houseIcon(houseColor)}
        />
        {
          sitePeers.map((peer) => {
            const { openAlerts: peerOpenAlerts } = peer;
            const peerAlert = peerOpenAlerts.edges.length > 0 ? peerOpenAlerts.edges[0].node : null;
            const color = peerAlert && validAlertTypes[peerAlert.intRef] ? validAlertTypes[peerAlert.intRef] : GREEN;
            return (
              <Marker
                key={`marker-${peer.prospectId}`}
                onClick={() => this.setState({ infoBoxProspectId: peer.prospectId })}
                position={{
                  lat: peer.lat,
                  lng: peer.lng || peer.lon,
                }}
                icon={markerIcon(color)}
              />
            );
          })
        }
        {
          infoBoxProspectId && (
            <PeerInfoBox prospectId={infoBoxProspectId} />
          )
        }
        <PlusIcon name="BluePlusIcon" onClick={() => this.handleSetZoom(1)} />
        <MinusIcon name="BlueMinusIcon" onClick={() => this.handleSetZoom(-1)} />
      </Map>
    );
  }
}

PeerMapView.propTypes = {
  siteDetailsQuery: PropTypes.shape({
    site: PropTypes.shape({
      address: PropTypes.shape({
        lat: PropTypes.string,
        lon: PropTypes.string,
        lng: PropTypes.string,
        street: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
      }).isRequired,
      systemSizeDC: PropTypes.string.isRequired,
    }),
  }).isRequired,
  openAlertsQuery: PropTypes.shape({
    openAlerts: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  sitePeersQuery: PropTypes.shape({
    sitePeers: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  prospectId: PropTypes.string.isRequired,
};

export default flowRight(
  withSiteDetails,
  withOpenAlerts,
  withSitePeers,
)(PeerMapView);
