import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { NAVY } from '../constants/_colors';

import LinksMenuItemComponent from './LinksMenuItemComponent';

const Wrapper = styled.div`
  align-items: flex-start;
  background-color: ${NAVY};
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 45px;
`;

export const Menu = styled.ul`
  background-color: ${NAVY};
  padding: 0 18px;
  position: fixed;
  right: 0;
  top: 42px;
  width: 250px;
  z-index: 1;
`;

const LinksMenu = ({
  links,
  renderIcon,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Wrapper
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {renderIcon(isOpen)}
      {isOpen
      && (
      <Menu>
        {links.map(({
          location, text, iconName,
        }) => (
          <LinksMenuItemComponent
            key={text}
            location={location}
            text={text}
            iconName={iconName}
          />
        ))}
      </Menu>
      )}
    </Wrapper>
  );
};

LinksMenu.propTypes = {
  renderIcon: PropTypes.func,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      iconFileName: PropTypes.string,
      location: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

LinksMenu.defaultProps = {
  renderIcon: undefined,
};

export default LinksMenu;
