import styled from '@emotion/styled/macro';
import { Icon } from '../../../general/components';
import { STREET_VIEW_ZINDEX } from '../../../general/constants/_zIndex';

export const PlusIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 40px;
  right: 10px;
  cursor: pointer;
  z-index: ${STREET_VIEW_ZINDEX};
`;

export const MinusIcon = styled(PlusIcon)`
  bottom: 15px;
`;
