import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const Container = styled(Paper)({
  margin: 20,
  padding: 20,
  display: 'flex',
  justifyContent: 'space-evenly',
});

const FormControlContainer = styled(FormControl)({
  width: 125,
});

const NonCommControls = ({
  handleNonCommSubmit,
  isActionLoading,
  dayCount,
}) => {
  const [pickerDayCount, setPickerDayCount] = useState(dayCount);

  return (
    <Container elevation={3}>
      <FormControlContainer>
        <InputLabel>Number Of Days</InputLabel>
        <Select
          value={pickerDayCount}
          onChange={e => setPickerDayCount(e.target.value)}
        >
          <MenuItem value="3">3</MenuItem>
          <MenuItem value="4">4</MenuItem>
          <MenuItem value="5">5</MenuItem>
          <MenuItem value="6">6</MenuItem>
          <MenuItem value="7">7</MenuItem>
          <MenuItem value="8">8</MenuItem>
          <MenuItem value="9">9</MenuItem>
          <MenuItem value="10">10</MenuItem>
          <MenuItem value="11">11</MenuItem>
          <MenuItem value="12">12</MenuItem>
          <MenuItem value="13">13</MenuItem>
          <MenuItem value="14">14</MenuItem>
          <MenuItem value="15">15</MenuItem>
        </Select>
      </FormControlContainer>
      <Button
        disabled={isActionLoading}
        onClick={() => handleNonCommSubmit({
          dayCount: pickerDayCount,
        })}
        variant="contained"
      >
        Get Non Comm Sites
      </Button>
    </Container>
  );
};

NonCommControls.propTypes = {
  dayCount: PropTypes.string.isRequired,
  handleNonCommSubmit: PropTypes.func.isRequired,
  isActionLoading: PropTypes.bool.isRequired,
};

export default NonCommControls;
