import React from 'react';
import PropTypes from 'prop-types';
import { SecureRoute } from '@okta/okta-react';

import NotAuthorized from '../components/NotAuthorized';

const PrivateRoute = (props) => {
  const { isAuthorized } = props;
  if (isAuthorized) return (<SecureRoute {...props} />);
  return (
    <NotAuthorized />
  );
};

PrivateRoute.propTypes = {
  isAuthorized: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  isAuthorized: false,
};

export default PrivateRoute;
