import React from 'react';
import { withAuth } from '@okta/okta-react';
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import AdminControlComponent, { ALERT_TYPE_PRIORITY_FRAGMENT } from './AdminControlComponent';
import { PageLoader } from '../../general/components';

export const ADMIN_QUERY = gql`
  query AlertType($first: Int!) {
    alertType(first: $first) {
      ...AlertTypePrioirty_alertType
    }
  }
  ${ALERT_TYPE_PRIORITY_FRAGMENT}
`;

const AdminControlContainer = () => {
  return (
    <Query query={ADMIN_QUERY} variables={{ first: 100 }}>
      {
        ({
          loading, error, data, refetch,
        }) => {
          if (loading) return <PageLoader />;
          if (error) return <div className="error">Error</div>;
          // Sort alerts types by priority
          data.alertType.edges = JSON.parse(JSON.stringify(data.alertType.edges)).sort((left, right) => {
            return right.node.casePriority - left.node.casePriority;
          });
          return (
            <AdminControlComponent
              data={data}
              refetch={refetch}
            />
          );
        }
      }
    </Query>
  );
};

export default withAuth(AdminControlContainer);
