import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import InternalLinkComponent from './InternalLinkComponent';
import ExternalLinkComponent from './ExternalLinkComponent';
import Icon from './Icon';
import { BLUE, WHITE } from '../constants/_colors';

import './LinksMenuItemComponent.scss';

const LinksMenuItemComponent = ({
  iconName,
  location,
  text,
}) => {
  const [isActive, setIsActive] = useState(false);

  const children = (
    <>
      {iconName && <Icon name={iconName} fill={isActive ? BLUE : WHITE} width={20} height={20} />}
      {text}
    </>
  );

  return (
    <li
      className={ClassNames('links-menu-item-component')}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      {/^\/.*$/.test(location)
        ? <InternalLinkComponent path={location} isActive={isActive}>{children}</InternalLinkComponent>
        : <ExternalLinkComponent url={location} isActive={isActive}>{children}</ExternalLinkComponent>
      }
    </li>
  );
};

LinksMenuItemComponent.propTypes = {
  iconName: PropTypes.string,
  location: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

LinksMenuItemComponent.defaultProps = {
  iconName: undefined,
};

export default LinksMenuItemComponent;
