import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import {
  Button,
  Paper,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { styled } from '@material-ui/core/styles';

const Container = styled(Paper)({
  margin: 20,
  padding: 20,
  display: 'flex',
  justifyContent: 'space-evenly',
});

const ErrorSitesControls = ({
  endDate,
  handleErrorSitesSubmit,
  isActionLoading,
  startDate,
}) => {
  const [pickerStartDate, setPickerStartDate] = useState(startDate);
  const [pickerEndDate, setPickerEndDate] = useState(endDate);

  return (
    <Container elevation={3}>
      <DatePicker
        label="Start Date"
        value={pickerStartDate}
        onChange={setPickerStartDate}
        autoOk
        format="MM/dd/yyyy"
      />
      <DatePicker
        label="End Date"
        value={pickerEndDate}
        onChange={setPickerEndDate}
        autoOk
        format="MM/dd/yyyy"
      />
      <Button
        disabled={isActionLoading}
        onClick={() => handleErrorSitesSubmit({
          startDate: pickerStartDate,
          endDate: pickerEndDate,
        })}
        variant="contained"
      >
        Get Sites With Errors
      </Button>
    </Container>
  );
};

ErrorSitesControls.propTypes = {
  endDate: PropTypes.instanceOf(DateTime).isRequired,
  handleErrorSitesSubmit: PropTypes.func.isRequired,
  isActionLoading: PropTypes.bool.isRequired,
  startDate: PropTypes.instanceOf(DateTime).isRequired,
};

export default ErrorSitesControls;
