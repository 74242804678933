import React from 'react';
import PropTypes from 'prop-types';
import { svgs } from '../images/svg-library';

const Icon = ({ name, ...rest }) => {
  const IconToRender = svgs[name];
  if (IconToRender) return <IconToRender className={name} {...rest} />;
  // if icon name not found return null
  return null;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Icon;
