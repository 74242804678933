import styled from '@emotion/styled/macro';
import { DOLPHIN_GREY } from '../../general/constants/_colors';

const PriorityItem = styled.div`
  border-bottom: 1px solid ${DOLPHIN_GREY};
  font-size: 18px;
  font-weight: 300;
  position: relative;
  padding: 20px 10px;

  &:last-child {
    border-bottom: none;
  }
`;


export default PriorityItem;
