import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { parse } from 'json2csv';
import mixpanel from 'mixpanel-browser';
import {
  NAVY,
  BLUE,
} from '../../../general/constants/_colors';
import { Tooltip } from '../../../general/components';

const TooltipIcon = () => (
  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15.8691" cy="15.6804" r="15" fill="#0087FF" />
    <path d="M21.6381 12.142H18.3414V8.75739H13.3963V12.142H10.0996L15.8688 18.0651L21.6381 12.142ZM10.0996 19.7574V21.4497H21.6381V19.7574H10.0996Z" fill="white" />
  </svg>
);
const DownloadWrap = styled.div`
  position:relative;
`;

const DownloadLink = styled.a`
  display: block;
  text-decoration: none;
  color: ${NAVY};
  padding: 6px 0;
`;

const BlueSpan = styled.span`
  color: ${BLUE};
`;

const DownloadOptions = ({
  anchorId,
  fileName,
  getDownloadableData,
}) => {
  const [downloadUrl, setDownloadUrl] = useState({ url: null, name: null });
  const downloadFile = () => {
    setTimeout(() => {
      const downloadLink = document.getElementById(anchorId);
      if (downloadLink) downloadLink.click();
    }, 1000);
  };

  const handleDownloadCSVClick = (e) => {
    e.preventDefault();
    const downloadableData = getDownloadableData();
    if (downloadableData && downloadableData.length > 0) {
      mixpanel.track('CSV Downloaded');
      const options = {
        fields: Object.keys(downloadableData[0]),
      };
      try {
        const csv = parse(downloadableData, options);
        setDownloadUrl({
          url: `data:text/plain;charset=utf-8,${encodeURIComponent(csv)}`,
          name: `${fileName}.csv`,
        });
        downloadFile();
      } catch (err) {
        console.log('err', err);
      }
    }
  };

  return (
    <DownloadWrap>
      <Tooltip style={{ top: 0 }} hoverTarget={<TooltipIcon name="DownloadIcon" style={{ cursor: 'pointer' }} />}>
        <DownloadLink href="#" onClick={handleDownloadCSVClick}>
          <BlueSpan>.CSV </BlueSpan>
          {' '}
            - Download CSV
        </DownloadLink>
      </Tooltip>
      {
        downloadUrl.url && (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a id={anchorId} href={downloadUrl.url} download={downloadUrl.name} />
        )
      }
    </DownloadWrap>
  );
};

DownloadOptions.defaultProps = {
};

DownloadOptions.propTypes = {
  anchorId: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  getDownloadableData: PropTypes.func.isRequired,
};

export default DownloadOptions;
