import React, { Fragment } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

const ButtonLink = styled.button`
  border: 0;
  outline: none;
  padding: 0;
  background-color: transparent;
  color: white;
  text-decoration: underline;
  cursor: pointer;
`;

const Name = styled.span`
  color: white;
  margin-right: 10px;
  font-size: 12px;
`;


const LoginComponent = ({
  isAuthenticated, name, onLogin,
}) => (
  <Fragment>
    {
      isAuthenticated && (
        <Fragment>
          <Name>
            {`Welcome back, ${name}!`}
          </Name>
        </Fragment>
      )
    }
    {
      !isAuthenticated && (
        <ButtonLink type="button" onClick={onLogin}>
          Log In
        </ButtonLink>
      )
    }
  </Fragment>
);

LoginComponent.propTypes = {
  isAuthenticated: PropTypes.bool,
  name: PropTypes.string,
  onLogin: PropTypes.func.isRequired,
};

LoginComponent.defaultProps = {
  isAuthenticated: false,
  name: undefined,
};

export default LoginComponent;
