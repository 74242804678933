/* eslint-disable no-param-reassign */
import { graphql } from '@apollo/client/react/hoc';
import { DateTime } from 'luxon';
import { unwrapGraphQlList } from '../../general/utils/utils';
import {
  ALL_ALERTS_QUERY,
  BATTERY_AGGREGATE_DAILY_QUERY,
  BATTERY_AGGREGATE_FIFTEEN_MINUTE_QUERY,
  BATTERY_MANUFACTURER_QUERY,
  CONTRACT_GENERATIONS_MONTHLY_QUERY,
  CONTRACT_GENERATIONS_QUERY,
  FIELD_SERVICE_ACTIVITY_QUERY,
  METER_INFO_QUERY,
  METER_INTERVAL_QUERY,
  OPEN_ALERTS_QUERY,
  PEER_ADJ_GENERATIONS_MONTHLY_QUERY,
  PEER_ADJ_GENERATIONS_QUERY,
  SITE_DETAILS_QUERY,
  SITE_PEERS_QUERY,
  SITE_POWER_FIFTEEN_MINUTE_QUERY,
  SITE_PRODUCTION_DAILY_QUERY,
  SITE_PRODUCTION_FIFTEEN_MINUTE_QUERY,
  SITE_PRODUCTION_MONTHLY_QUERY,
  SYSTEM_CHARACTERISTICS_QUERY,
} from './site';

const getDailyEndDate = (endDate) => {
  if (!endDate) return DateTime.local().endOf('day').toISO();
  return DateTime.fromISO(endDate).endOf('day').toISO();
};

export const withAllAlerts = graphql(ALL_ALERTS_QUERY, {
  props: ({ data, ...rest }) => {
    return ({
      allAlertsQuery: {
        allAlerts: unwrapGraphQlList(data, 'site.allAlerts.edges'),
        loading: data.loading,
        error: data.error,
      },
      ...rest,
    });
  },
  options: ({ prospectId }) => ({
    variables: {
      prospectId,
    },
  }),
});

export const withBatteryDaily = graphql(BATTERY_AGGREGATE_DAILY_QUERY, {
  props: ({ data, ...rest }) => {
    return ({
      batteryDailyQuery: {
        batteryDaily: unwrapGraphQlList(data, 'site.batteryDaily.edges'),
        loading: data.loading,
        error: data.error,
      },
      ...rest,
    });
  },
  options: ({
    endDate,
    prospectId,
    startDate,
  }) => ({
    variables: {
      endDate: getDailyEndDate(endDate),
      prospectId,
      startDate,
    },
  }),
});

export const withBatteryFifteenMinute = graphql(BATTERY_AGGREGATE_FIFTEEN_MINUTE_QUERY, {
  props: ({ data, ...rest }) => {
    return ({
      batteryFifteenMinuteQuery: {
        batteryFifteenMinute: unwrapGraphQlList(data, 'site.batteryFifteenMinute.edges'),
        loading: data.loading,
        error: data.error,
      },
      ...rest,
    });
  },
  options: ({
    endDate,
    prospectId,
    startDate,
  }) => ({
    variables: {
      endDate,
      prospectId,
      startDate,
    },
  }),
});

export const withBatteryManufacturer = graphql(BATTERY_MANUFACTURER_QUERY, {
  props: ({ data, ...rest }) => {
    return ({
      batteryQuery: {
        battery: unwrapGraphQlList(data, 'site.battery.edges'),
        loading: data.loading,
        error: data.error,
      },
      ...rest,
    });
  },
  options: ({ prospectId }) => ({
    variables: {
      prospectId,
      // This call is extremely slow if you do pass dates
      startDate: DateTime.local().minus({ day: 1 }).startOf('day').toISO(),
      endDate: DateTime.local().startOf('day').toISO(),
    },
  }),
});

export const withContractGenerations = graphql(CONTRACT_GENERATIONS_QUERY, {
  props: ({ data, ...rest }) => {
    return ({
      contractGenerationsQuery: {
        contractGenerations: unwrapGraphQlList(data, 'site.contractGenerations.edges'),
        loading: data.loading,
        error: data.error,
      },
      ...rest,
    });
  },
  options: ({
    endDate,
    prospectId,
    startDate,
  }) => ({
    variables: {
      endDate,
      prospectId,
      startDate,
    },
  }),
});

export const withContractGenerationsMonthly = graphql(CONTRACT_GENERATIONS_MONTHLY_QUERY, {
  props: ({ data, ...rest }) => {
    return ({
      contractGenerationsMonthlyQuery: {
        contractGenerations: unwrapGraphQlList(data, 'site.contractGenerationsMonthly.edges'),
        loading: data.loading,
        error: data.error,
      },
      ...rest,
    });
  },
  options: ({
    endDate,
    prospectId,
    startDate,
  }) => ({
    variables: {
      endDate,
      prospectId,
      startDate,
    },
  }),
});

export const withDailyProduction = graphql(SITE_PRODUCTION_DAILY_QUERY, {
  props: ({ data, ...rest }) => {
    return ({
      dailyProductionQuery: {
        siteProductionDaily: unwrapGraphQlList(data, 'site.siteProductionDaily.edges'),
        loading: data.loading,
        error: data.error,
      },
      ...rest,
    });
  },
  options: ({
    prospectId,
    ptoDate,
    startDate,
    endDate,
  }) => ({
    variables: {
      prospectId,
      startDate: ptoDate || startDate,
      endDate: getDailyEndDate(endDate),
    },
  }),
});

const combineFieldServiceActivityByCase = (fieldServiceActivity) => {
  const groupedCaseNumbers = fieldServiceActivity
    .reduce((caseDictionary, c) => {
      if (!caseDictionary[c.caseNumber]) {
        caseDictionary[c.caseNumber] = {
          actionCodeNames: [],
          actionNames: [],
          componentCodeNames: [],
          dispatchNames: [],
          findings: [],
          ...c,
        };
      }

      caseDictionary[c.caseNumber].actionCodeNames.push(c.actionCodeName);
      caseDictionary[c.caseNumber].actionNames.push({ name: c.actionName, id: c.actionId });
      caseDictionary[c.caseNumber].componentCodeNames.push(c.componentCodeName);
      caseDictionary[c.caseNumber].dispatchNames.push({ name: c.dispatchName, id: c.dispatchId });
      caseDictionary[c.caseNumber].findings.push(c.dispatchFindings);
      return caseDictionary;
    }, {});

  const combinedStages = Object.values(groupedCaseNumbers);

  return combinedStages;
};

export const withFieldServiceActivity = graphql(FIELD_SERVICE_ACTIVITY_QUERY, {
  props: ({ data, ...rest }) => {
    return ({
      fieldServiceActivityQuery: {
        fieldServiceActivity: unwrapGraphQlList(data, 'site.fieldServiceActivity.edges'),
        fieldServiceActivityGroupedByCase: combineFieldServiceActivityByCase(unwrapGraphQlList(data, 'site.fieldServiceActivity.edges')),
        loading: data.loading,
        error: data.error,
      },
      ...rest,
    });
  },
  options: ({ prospectId }) => ({
    variables: {
      prospectId,
    },
  }),
});

export const withFifteenMinutePower = graphql(SITE_POWER_FIFTEEN_MINUTE_QUERY, {
  props: ({ data, ...rest }) => {
    return ({
      sitePowerFifteenMinuteQuery: {
        sitePowerFifteenMinute: unwrapGraphQlList(data, 'site.sitePowerFifteenMinute.edges'),
        loading: data.loading,
        error: data.error,
      },
      ...rest,
    });
  },
  options: ({
    prospectId,
    startDate,
    endDate,
  }) => ({
    variables: {
      prospectId,
      startDate,
      endDate: endDate || DateTime.local().startOf('day').toISO(),
    },
  }),
});

export const withFifteenMinuteProduction = graphql(SITE_PRODUCTION_FIFTEEN_MINUTE_QUERY, {
  props: ({ data, ...rest }) => {
    return ({
      siteProductionFifteenMinuteQuery: {
        siteProductionFifteenMinute: unwrapGraphQlList(data, 'site.siteProductionFifteenMinute.edges'),
        loading: data.loading,
        error: data.error,
      },
      ...rest,
    });
  },
  options: ({
    prospectId,
    startDate,
    endDate,
  }) => ({
    variables: {
      prospectId,
      startDate,
      endDate: endDate || DateTime.local().startOf('day').toISO(),
    },
  }),
});

export const withMeterFifteenMinute = graphql(METER_INTERVAL_QUERY, {
  props: ({ data, ...rest }) => {
    return ({
      meterFifteenMinuteQuery: {
        meterFifteenMinute: unwrapGraphQlList(data, 'site.meterInterval.edges'),
        loading: data.loading,
        error: data.error,
      },
      ...rest,
    });
  },
  options: ({
    endDate,
    prospectId,
    startDate,
  }) => ({
    variables: {
      endDate,
      prospectId,
      startDate,
    },
  }),
});

export const withMeterInfo = graphql(METER_INFO_QUERY, {
  props: ({ data, ...rest }) => {
    return ({
      meterInfoQuery: {
        meterInfo: unwrapGraphQlList(data, 'site.meterInfo.edges'),
        loading: data.loading,
        error: data.error,
      },
      ...rest,
    });
  },
  options: ({ prospectId }) => ({
    variables: {
      prospectId,
    },
  }),
});

export const withMonthlyProduction = graphql(SITE_PRODUCTION_MONTHLY_QUERY, {
  props: ({ data, ...rest }) => {
    return ({
      monthlyProductionQuery: {
        siteProductionMonthly: unwrapGraphQlList(data, 'site.siteProductionMonthly.edges'),
        loading: data.loading,
        error: data.error,
      },
      ...rest,
    });
  },
  options: ({
    prospectId,
    ptoDate,
    startDate,
    endDate,
  }) => ({
    variables: {
      prospectId,
      startDate: ptoDate || startDate,
      endDate: endDate || DateTime.local().startOf('day').toISO(),
    },
  }),
});

export const withOpenAlerts = graphql(OPEN_ALERTS_QUERY, {
  props: ({ data, ...rest }) => {
    return ({
      openAlertsQuery: {
        openAlerts: unwrapGraphQlList(data, 'site.openAlerts.edges'),
        loading: data.loading,
        error: data.error,
      },
      ...rest,
    });
  },
  options: ({ prospectId }) => ({
    variables: {
      prospectId,
    },
  }),
});

export const withPeerAdjGenerations = graphql(PEER_ADJ_GENERATIONS_QUERY, {
  props: ({ data, ...rest }) => {
    return ({
      peerAdjGenerationsQuery: {
        peerAdjGenerations: unwrapGraphQlList(data, 'site.peerAdjGenerations.edges'),
        loading: data.loading,
        error: data.error,
      },
      ...rest,
    });
  },
  options: ({
    endDate,
    prospectId,
    startDate,
  }) => ({
    variables: {
      endDate,
      prospectId,
      startDate,
    },
  }),
});

export const withPeerAdjGenerationsMonthly = graphql(PEER_ADJ_GENERATIONS_MONTHLY_QUERY, {
  props: ({ data, ...rest }) => {
    return ({
      peerAdjGenerationsMonthlyQuery: {
        peerAdjGenerations: unwrapGraphQlList(data, 'site.peerAdjGenerationsMonthly.edges'),
        loading: data.loading,
        error: data.error,
      },
      ...rest,
    });
  },
  options: ({
    endDate,
    prospectId,
    startDate,
  }) => ({
    variables: {
      endDate,
      prospectId,
      startDate,
    },
  }),
});

export const withSiteDetails = graphql(SITE_DETAILS_QUERY, {
  props: ({ data, ...rest }) => {
    return ({
      siteDetailsQuery: {
        site: data.site,
        loading: data.loading,
        error: data.error,
      },
      ...rest,
    });
  },
  options: ({ prospectId }) => ({
    variables: {
      prospectId,
    },
  }),
});

export const withSitePeers = graphql(SITE_PEERS_QUERY, {
  props: ({ data, ...rest }) => {
    return ({
      sitePeersQuery: {
        sitePeers: unwrapGraphQlList(data, 'site.sitePeers.edges'),
        loading: data.loading,
        error: data.error,
      },
      ...rest,
    });
  },
  options: ({ prospectId }) => ({
    variables: {
      prospectId,
    },
  }),
});

export const withSystemCharacteristics = graphql(SYSTEM_CHARACTERISTICS_QUERY, {
  props: ({ data, ...rest }) => {
    return ({
      systemsCharacteristicsQuery: {
        systemsCharacteristics: unwrapGraphQlList(data, 'site.systemsCharacteristics.edges'),
        loading: data.loading,
        error: data.error,
      },
      ...rest,
    });
  },
  options: ({ prospectId }) => ({
    variables: {
      prospectId,
    },
  }),
});
