import { graphql } from '@apollo/client/react/hoc';
import { unwrapGraphQlList } from '../../general/utils/utils';
import { ALERT_TYPES_QUERY } from './alertType';

export const withAlertTypes = graphql(ALERT_TYPES_QUERY, {
  props: ({ data, ...rest }) => {
    return ({
      alertTypesQuery: {
        alertType: unwrapGraphQlList(data, 'alertType.edges').sort((left, right) => {
          if (right.casePriority < left.casePriority) {
            return -1;
          }
          return right.casePriority > left.casePriority;
        }),
        loading: data.loading,
        error: data.error,
      },
      ...rest,
    });
  },
  options: ({ prospectId }) => ({
    variables: {
      prospectId,
    },
  }),
});
