import React from 'react';

const GridPage = () => {
  return (
    <div>
      Is grid!
    </div>
  );
};

export default GridPage;
