import { gql } from '@apollo/client';

export const ALL_ALERTS_QUERY = gql`
  query AllAlertsQuery($prospectId: String!) {
    site(prospectId: $prospectId) {
      allAlerts: alerts(first: 100, openAlertsOnly: false) {
        edges {
          node {
            color
            casePriority
            caseReason
            closedDateUTC
            createdDateUTC
            currentSeverity
            alertId: id
            initialSeverity
            intRef
            name
          }
        }
      }
    }
  }
`;

export const BATTERY_AGGREGATE_DAILY_QUERY = gql`
  query BatteryAggregateDailyQuery($prospectId: String!, $startDate: String!, $endDate: String!) {
    site(prospectId: $prospectId) {
      batteryDaily(startDate: $startDate, endDate: $endDate) {
        edges {
            node {
            isActive
            performanceData {
              endDateUtc
              energyChargedKwh
              energyDischargedKwh
              maxBatteryPercentageState
              minBatteryPercentageState
              readingEndTimeLocal
              serialNumber
              startDateUtc
              source {
                isEstimated
              }
            }
            serialNumber
            vendorAssets {
              connectedInverterSn
              connectedTo
              manufacturer
              name
            }
            vendorName
          }
        }
      }
    }
  }
`;

export const BATTERY_AGGREGATE_FIFTEEN_MINUTE_QUERY = gql`
  query BatteryAggregateFifteenMinuteQuery($prospectId: String!, $startDate: String!, $endDate: String!) {
    site(prospectId: $prospectId) {
      batteryFifteenMinute(startDate: $startDate, endDate: $endDate) {
        edges {
          node {
            isActive
            name
            performanceData {
              averageBatteryPercentageState
              averagePowerKw
              endDateUtc
              energyChargedKwh
              energyDischargedKwh
              maxBatteryPercentageState
              maxPowerKw
              minBatteryPercentageState
              minPowerKw
              readingEndTimeLocal
              serialNumber
              startDateUtc
              source {
                isEstimated
              }
            }
            serialNumber
            vendorAssets {
              connectedInverterSn
              connectedTo
              manufacturer
              name
            }
            vendorName
          }
        }
      }
    }
  }
`;

// This query is extremely slow without dates
export const BATTERY_MANUFACTURER_QUERY = gql`
  query BatteryManufacturerQuery($prospectId: String!, $startDate: String!, $endDate: String!) {
    site(prospectId: $prospectId) {
      battery(startDate: $startDate, endDate: $endDate) {
        edges {
          node {
            manufacturer
            serialNumber
          }
        }
      }
    }
  }
`;

export const BATTERY_QUERY = gql`
  query BatteryQuery($prospectId: String!, $startDate: String!, $endDate: String!) {
    site(prospectId: $prospectId) {
      battery(startDate: $startDate, endDate: $endDate) {
        edges {
          node {
            connectedTo
            performanceData {
              batteryPercentageState
              batteryState
              batteryState
              internalTemp
              lifeTimeEnergyCharged
              lifeTimeEnergyDischarged
              power
              timeStamp
              timeStampUTC
            }
          }
        }
      }
    }
  }
`;

export const CONTRACT_GENERATIONS_CUMULATIVE_DAILY_QUERY = gql`
  query ContractGenerationsCumulativeDaily($prospectId: String!, $startDate: String, $endDate: String) {
    site(prospectId: $prospectId) {
      contractGenerationsCumulativeDaily(startDate: $startDate, endDate: $endDate) {
        edges {
          node {
            readingEndTimeLocal
            deliveredKwh
            cumulativeKwh
          }
        }
      }
    }
  }
`;

export const CONTRACT_GENERATIONS_QUERY = gql`
  query ContractGenerationsQuery ($prospectId: String!, $startDate: String!, $endDate: String!){
    site(prospectId: $prospectId) {
      contractGenerations(startDate: $startDate, endDate: $endDate) {
        edges {
          node {
            date
            gen
          }
        }
      }
    }
  }
`;

export const CONTRACT_GENERATIONS_CUMULATIVE_MONTHLY_QUERY = gql`
  query ContractGenerationsCumulativeMonthly($prospectId: String!, $startDate: String, $endDate: String) {
    site(prospectId: $prospectId) {
      contractGenerationsCumulativeMonthly(startDate: $startDate, endDate: $endDate) {
        edges {
          node {
            readingEndTimeLocal
            deliveredKwh
            cumulativeKwh
          }
        }
      }
    }
  }
`;

export const CONTRACT_GENERATIONS_MONTHLY_QUERY = gql`
  query ContractMonthlyQuery ($prospectId: String!, $startDate: String!, $endDate: String!){
    site(prospectId: $prospectId) {
      contractGenerationsMonthly(startDate: $startDate, endDate: $endDate) {
        edges {
          node {
            date
            gen
          }
        }
      }
    }
  }
`;

export const DATELESS_METER_INTERVAL_QUERY = gql`
  query DatelessMeterIntervalQuery($prospectId: String!) {
    site(prospectId: $prospectId) {
      meterInterval {
        edges {
          node {
            id
            manufacturer
            serialNumber
            inServiceDate
            status
          }
        }
      }
    }
  }
`;

export const FIELD_SERVICE_ACTIVITY_QUERY = gql`
  query FieldServiceActivityQuery ($prospectId: String!) {
    site(prospectId: $prospectId) {
      fieldServiceActivity {
        edges {
          node {
            actionCodeName
            actionDescription
            actionId
            actionName
            caseId: id
            caseNumber
            closedDate
            componentCode
            componentCodeName
            createdDate
            dispatchFindings
            dispatchId
            dispatchName
            dispatchTime
            origin
            reason
            serviceContractName
            status
            subsetReason
          }
        }
      }
    }
  }
`;

export const INTERVAL_QUERY = gql`
  query IntervalQuery($prospectId: String!, $startDate: String!, $endDate: String!) {
    site(prospectId: $prospectId) {
      meterInterval(startDate: $startDate, endDate: $endDate) {
        edges {
          node {
            manufacturer
            performanceData {
              readingEndTimeLocal
              deliveredKwh
              consumptionKwh
              feedInKwh
            }
          }
        }
      }
      battery(startDate: $startDate, endDate: $endDate) {
        edges {
          node {
            connectedTo
            manufacturer
            performanceData {
              batteryState
              lifeTimeEnergyCharged
              lifeTimeEnergyDischarged
              internalTemp
              batteryState
              timeStamp
              timeStampUTC
              batteryPercentageState
              power
            }
          }
        }
      }
    }
  }
`;

export const METER_INFO_QUERY = gql`
  query MeterInfoQuery($prospectId: String!) {
    site(prospectId: $prospectId) {
      meterInfo {
        edges {
          node {
            inServiceDate
            manufacturer
            serialNumber
            status
            statusDetails {
              readingEndTimeLocal
            }
            types
            vendorName
          }
        }
      }
    }
  }
`;

export const METER_INTERVAL_QUERY = gql`
  query MeterIntervalQuery($prospectId: String!, $startDate: String!, $endDate: String!) {
    site(prospectId: $prospectId) {
      meterInterval(startDate: $startDate, endDate: $endDate) {
        edges {
          node {
            manufacturer
            performanceData {
              readingEndTimeLocal
              deliveredKwh
              consumptionKwh
              feedInKwh
            }
          }
        }
      }
    }
  }
`;

export const OPEN_ALERTS_QUERY = gql`
  query OpenAlertsQuery($prospectId: String!) {
    site(prospectId: $prospectId) {
      openAlerts: alerts(first: 50, openAlertsOnly: true) {
        edges {
          node {
            color
            casePriority
            caseReason
            createdDateUTC
            currentSeverity
            alertId: id
            intRef
          }
        }
      }
    }
  }
`;

export const PEER_ADJ_GENERATIONS_CUMULATIVE_DAILY_QUERY = gql`
  query PeerAdjGenerationsCumulativeDaily($prospectId: String!, $startDate: String, $endDate: String) {
    site(prospectId: $prospectId) {
      peerAdjGenerationsCumulativeDaily(startDate: $startDate, endDate: $endDate) {
        edges {
          node {
            readingEndTimeLocal
            deliveredKwh
            cumulativeKwh
          }
        }
      }
    }
  }
`;

export const PEER_ADJ_GENERATIONS_CUMULATIVE_MONTHLY_QUERY = gql`
  query PeerAdjGenerationsCumulativeMonthly($prospectId: String!, $startDate: String, $endDate: String) {
    site(prospectId: $prospectId) {
      peerAdjGenerationsCumulativeMonthly(startDate: $startDate, endDate: $endDate) {
        edges {
          node {
            readingEndTimeLocal
            deliveredKwh
            cumulativeKwh
          }
        }
      }
    }
  }
`;

export const PEER_ADJ_GENERATIONS_MONTHLY_QUERY = gql`
  query PeerAdjGenerationsMonthly($prospectId: String!, $startDate: String, $endDate: String) {
    site(prospectId: $prospectId) {
      peerAdjGenerationsMonthly(startDate: $startDate, endDate: $endDate) {
        edges {
          node {
            aggEndDate
            peerAdjustedMedianAgg
            peerAdjustedNormalizedAgg
          }
        }
      }
    }
  }
`;

export const PEER_ADJ_GENERATIONS_QUERY = gql`
  query PeerAdjGenerationsQuery($prospectId: String!, $startDate: String, $endDate: String) {
    site(prospectId: $prospectId) {
      peerAdjGenerations(startDate: $startDate, endDate: $endDate) {
        edges {
          node {
            generationDate
            peerAdjustedMedian
            peerAdjustedNormalized
          }
        }
      }
    }
  }
`;

export const SITE_DETAILS_QUERY = gql`
  query SiteDetailsQuery($prospectId: String!) {
    site(prospectId: $prospectId) {
      prospectId
      address {
        lat
        lon
        street
        state
        city
        zip
      }
      billingType
      brightBox
      contractId
      customerName
      fundName
      fyge
      installPartnerName
      m1Date
      m2Date
      storageMode
      storageProfileId
      storageProfileDetails {
        chargeLimit
        dailyStorageProfiles {
          description
          mode
          modeChanges {
            Description
            End
            Mode
            Start
          }
          name
        }
        description
        name
        reservedBackup
        seasonalStorageProfiles {
          dailyProfiles
          description
          from
          name
          to
        }
        specialDayStorageProfiles {
          dailyProfile
          day
          description
          name
          recurring
        }
        uuid
      }
      productOffering {
        hasConsumption
        hasSolar
        hasStorage
        hasIndependentStorage
      }
      ptoDate
      ptoRecordedDate
      salesPartnerName
      serviceContractName
      status
      systemSizeDC
      timeZone
      tzName
      utilityCompany
      welcomeDate
    }
  }
`;

export const SITE_PEERS_QUERY = gql`
  query SitePeersQuery($prospectId: String!) {
    site(prospectId: $prospectId) {
      sitePeers {
        edges {
          node {
            prospectId
            lat
            lng
            street
            city
            state
            ptoDate
            systemSizeDC
            openAlerts(first: 1) {
              edges {
                node {
                  caseReason
                  casePriority
                  id
                  intRef
                  color
                  serviceContractName
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const SITE_POWER_FIFTEEN_MINUTE_QUERY = gql`
  query SiteFifteenMinutePowerQuery($prospectId: String!, $startDate: String!, $endDate: String!) {
    site(prospectId: $prospectId) {
      sitePowerFifteenMinute(startDate: $startDate, endDate: $endDate) {
        edges {
          node {
            averageBatteryPowerKw
            averageChargeKw
            averageDischargeKw
            deliveredKw
            consumptionKw
            estimatedConsumptionKw
            estimatedProductionKw
            estimatedSelfConsumptionKw
            feedInKw
            purchasedKw
            source {
              isEstimated
            }
            readingEndTimeUtc
            readingEndTimeLocal
            startDateUtc
            endDateUtc
          }
        }
      }
    }
  }
`;

export const SITE_PRODUCTION_DAILY_QUERY = gql`
  query SiteDailyProductionQuery($prospectId: String!, $startDate: String!, $endDate: String!) {
    site(prospectId: $prospectId) {
      siteProductionDaily(startDate: $startDate, endDate: $endDate) {
        edges {
          node {
            consumptionKwh
            deliveredKwh
            estimatedConsumptionKwh
            estimatedProductionKwh
            estimatedSelfConsumptionKwh
            expectedDaytimeCounts
            feedInKwh
            gatewayConsumptionKwh
            gatewayProductionKwh
            gatewaySelfConsumptionKwh
            readingEndTimeLocal
            startDateUtc
            readingCounts {
              DELIVERED {
                totalMissing
                totalDaytime
                totalMissingDaytime
                total
              }
            }
            source {
              isEstimated
            }
          }
        }
      }
    }
  }
`;

export const SITE_PRODUCTION_FIFTEEN_MINUTE_QUERY = gql`
  query SiteFifteenMinuteProductionQuery($prospectId: String!, $startDate: String!, $endDate: String!) {
    site(prospectId: $prospectId) {
      siteProductionFifteenMinute(startDate: $startDate, endDate: $endDate) {
        edges {
          node {
            consumptionKwh
            deliveredKwh
            estimatedConsumptionKwh
            estimatedProductionKwh
            estimatedSelfConsumptionKwh
            expectedDaytimeCounts
            feedInKwh
            readingEndTimeLocal
            endDateUtc
            startDateUtc
            readingCounts {
              DELIVERED {
                totalMissing
                totalDaytime
                totalMissingDaytime
                total
              }
            }
            source {
              isEstimated
            }
          }
        }
      }
    }
  }
`;

export const SITE_PRODUCTION_MONTHLY_QUERY = gql`
  query SiteProductionMonthlyQuery ($prospectId: String!, $startDate: String!, $endDate: String!){
    site(prospectId: $prospectId) {
      siteProductionMonthly(startDate: $startDate, endDate: $endDate) {
        edges {
          node {
            consumptionKwh
            deliveredKwh
            estimatedConsumptionKwh
            estimatedProductionKwh
            estimatedSelfConsumptionKwh
            expectedDaytimeCounts
            feedInKwh
            gatewayConsumptionKwh
            gatewayProductionKwh
            gatewaySelfConsumptionKwh
            readingEndTimeLocal
            startDateUtc
            readingCounts {
              DELIVERED {
                totalMissing
                totalDaytime
                totalMissingDaytime
                total
              }
            }
            source {
              isEstimated
            }
          }
        }
      }
    }
  }
`;

export const SYSTEM_CHARACTERISTICS_QUERY = gql`
  query SystemCharacteristicsQuery($prospectId: String!) {
    site(prospectId: $prospectId) {
      systemsCharacteristics {
        edges {
          node {
            tilt
            azimuth
            roofOffset
            numPanels
            numInverters
            inverterMfg
            inverterModel
            panelMfg
            panelModel
            janShading
            febShading
            marShading
            aprShading
            mayShading
            junShading
            julShading
            augShading
            sepShading
            octShading
            novShading
            decShading
          }
        }
      }
    }
  }
`;
