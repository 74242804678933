import styled from '@emotion/styled/macro';

const Main = styled.main`
  display: block;
  min-height: calc(100vh - 95px);
  overflow-x: hidden;
  padding-top: 65px;
`;

export default Main;
