import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';
import { DateTime } from 'luxon';
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { unwrapGraphQlList } from '../../../general/utils/utils';
import { validAlertTypes } from '../../../general/constants/_alertColors';

const SiteInfo = styled.div`
width: 200px;
padding: 12px 14px;
color: white;
font-size: 12px;
background: ${props => props.background};
border-radius: 5px;
margin-left: 15px;
opacity: 0.9;
`;

const SiteItem = styled.p`
margin-bottom: 12px;
`;

const SiteLink = styled(Link)`
cursor: pointer;
color: inherit;
text-decoration: none;
`;

const PEER_INFO_BOX_QUERY = gql`
  query PeerInfoBoxQuery($prospectId: String!) {
    site(prospectId: $prospectId) {
      prospectId
      address {
        lat
        lon
        street
        state
        city
        zip
      }
      serviceContractName
      ptoDate
      systemSizeDC
      openAlerts: alerts(first: 1, openAlertsOnly: true) {
        edges {
          node {
            caseReason
            color
            id
            intRef
            name
          }
        }
      }
    }
  }
`;

const PeerInfoBox = ({ prospectId }) => (
  <Query query={PEER_INFO_BOX_QUERY} variables={{ prospectId }}>
    {
    ({ data }) => {
      if (data && data.site) {
        const {
          site: {
            address: {
              city,
              lat,
              lon,
              state,
              street,
            },
            serviceContractName,
            ptoDate,
            systemSizeDC,
            openAlerts,
          },
        } = data;
        const alerts = unwrapGraphQlList(openAlerts, 'edges');
        const alert = alerts[0] && validAlertTypes[alerts[0].intRef] ? alerts[0] : { name: 'No Known Issues', intRef: 'no_known_issues' };
        return (
          <InfoBox
            position={new window.google.maps.LatLng(lat, lon)}
            options={{ closeBoxURL: '' }}
          >
            <SiteInfo background={`${validAlertTypes[alert.intRef]}`}>
              <SiteLink to={`/site/${prospectId}`}>
                <SiteItem>
                  {`SCN - ${serviceContractName}`}
                </SiteItem>
              </SiteLink>
              <SiteItem>
                {`Address - ${street}, ${city}, ${state}`}
              </SiteItem>
              <SiteItem>
                {`PTO Date - ${DateTime.fromSQL(ptoDate).toFormat('LLL d, yyyy')}`}
              </SiteItem>
              <SiteItem>
                {`Size (DC) - ${systemSizeDC} kW`}
              </SiteItem>
              <SiteItem>
                {`Alert Type - ${alert.name}`}
              </SiteItem>
            </SiteInfo>
          </InfoBox>
        );
      }
      return null;
    }
  }
  </Query>
);

PeerInfoBox.propTypes = {
  prospectId: PropTypes.string.isRequired,
};

export default PeerInfoBox;
