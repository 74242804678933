/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { Grid, Col, Row } from 'react-flexbox-grid';
import { flowRight } from 'lodash';
import { Settings, DateTime } from 'luxon';
import { withSiteDetails } from '../queries/withSiteQueries';
import { BLUE } from '../../general/constants/_colors';
import {
  Card,
  SectionHeader,
} from '../../general/components';
import RecentPerformanceContainer from './RecentPerformance/RecentPerformanceContainer';
import HistoricalPerformanceChart from './HistoricalPerformance/HistoricalPerformanceChart';
import StreetMapView from './Maps/StreetMapView';
import AerialMapView from './Maps/AerialMapView';
import PeerMapAlertWrapper from './PeerMapAlertWrapper';
import ErrorBoundary from '../../general/components/ErrorBoundary';
import './SiteComponent.scss';
import Summary from './Summary';
import SystemDetails from './SystemDetails';

export const SiteCard = styled(Card)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const SiteInfoContainer = styled(SiteCard)`
  border: none;
`;

const MapsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const MapsContainer = styled.div`
 display: flex;
 width: 100%;

 @media only screen and (min-width: 1800px) {
   width: 50%;
 }
`;

const MapsColumns = styled.div`
  margin-right: 10px;
  width: 50%;
`;

const RowContainer = styled(Row)`
  border-bottom: 1px solid #B2ADA6;
`;

const RowContainerWithPadding = styled(RowContainer)`
  padding-top: 20px;
`;

const SectionHeaderPTOStatus = styled.span`
  color: ${BLUE};
  font-size: 16px;
  margin-left: 20px;
`;

export const ptoDateOrMinDate = (ptoDate, m2Date) => {
  const ptoDateTime = DateTime.fromISO(ptoDate);
  if (ptoDateTime.isValid) {
    return ptoDateTime.toISO();
  }
  const m2DateTime = DateTime.fromISO(m2Date);
  if (m2DateTime.isValid) {
    return m2DateTime.toISO();
  }
  return DateTime.local().startOf('day').minus({ year: 1 }).toISO();
};

const SiteComponent = ({
  siteDetailsQuery,
  prospectId,
}) => {
  if (!siteDetailsQuery.site) return null;

  const {
    m2Date,
    ptoDate,
    storageMode,
    storageProfileDetails,
    tzName,
  } = siteDetailsQuery.site;
  Settings.defaultZoneName = tzName;
  const minDateISO = ptoDateOrMinDate(ptoDate, m2Date);
  return (
    <Grid fluid>
      <Summary prospectId={prospectId} />
      <RowContainerWithPadding>
        <Col xs={12}>
          <SectionHeader>
            Recent Performance
            {!ptoDate && (<SectionHeaderPTOStatus>No PTO Date Set</SectionHeaderPTOStatus>)}
          </SectionHeader>
          <RecentPerformanceContainer
            key={prospectId}
            minDateISO={minDateISO}
            prospectId={prospectId}
            storageProfile={{
              storageMode,
              storageProfileDetails: storageProfileDetails && storageProfileDetails[0],
            }}
            tzName={tzName}
          />
        </Col>
      </RowContainerWithPadding>
      <RowContainerWithPadding style={{ borderBottom: 'none' }}>
        <MapsRow>
          <MapsContainer>
            <MapsColumns>
              <SectionHeader>
                Street View
              </SectionHeader>
              <SiteCard>
                <ErrorBoundary>
                  <StreetMapView key={prospectId} prospectId={prospectId} />
                </ErrorBoundary>
              </SiteCard>
              <div className="invisible-text">sunrun</div>
            </MapsColumns>
            <MapsColumns>
              <SectionHeader>
                Aerial View
              </SectionHeader>
              <SiteCard>
                <ErrorBoundary>
                  <AerialMapView key={prospectId} prospectId={prospectId} />
                </ErrorBoundary>
              </SiteCard>
              <div className="invisible-text">sunrun</div>
            </MapsColumns>
          </MapsContainer>
          <MapsContainer>
            <PeerMapAlertWrapper prospectId={prospectId} />
          </MapsContainer>
        </MapsRow>
      </RowContainerWithPadding>
      <RowContainerWithPadding>
        <Col xs={12}>
          <SectionHeader>Historical PV Performance</SectionHeader>
          <SiteInfoContainer>
            <ErrorBoundary>
              <HistoricalPerformanceChart key={prospectId} prospectId={prospectId} ptoDate={minDateISO} />
            </ErrorBoundary>
          </SiteInfoContainer>
        </Col>
      </RowContainerWithPadding>
      <SystemDetails prospectId={prospectId} />
    </Grid>
  );
};

SiteComponent.propTypes = {
  prospectId: PropTypes.string.isRequired,
  siteDetailsQuery: PropTypes.shape({
    site: PropTypes.shape({
      m2Date: PropTypes.string,
      ptoDate: PropTypes.string,
      storageMode: PropTypes.string,
      storageProfileDetails: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        }),
      ),
      tzName: PropTypes.string,
    }),
  }).isRequired,
};


export default flowRight(withSiteDetails)(SiteComponent);
