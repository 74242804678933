import React from 'react';
import styled from '@emotion/styled/macro';
import Loader from './Loader';


const LoaderWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 65px);
`;

const PageLoader = () => (
  <LoaderWrapper>
    <Loader />
  </LoaderWrapper>
);

export default PageLoader;
