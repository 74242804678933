import React from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '@okta/okta-react';
import { flowRight } from 'lodash';
import mixpanel from 'mixpanel-browser';
import SiteComponent from './SiteComponent';
import { PageLoader } from '../../general/components';
import { withSiteDetails } from '../queries/withSiteQueries';

const SiteComposer = ({ prospectId, siteDetailsQuery }) => {
  const { loading } = siteDetailsQuery;
  if (loading) return <PageLoader />;
  mixpanel.track('Site Page Loaded');
  return (
    <SiteComponent prospectId={prospectId} />
  );
};

SiteComposer.propTypes = {
  prospectId: PropTypes.string.isRequired,
  siteDetailsQuery: PropTypes.shape({
    site: PropTypes.shape({
    }),
    loading: PropTypes.bool,
  }).isRequired,
};

const SiteComponentWithData = flowRight(
  withSiteDetails,
)(SiteComposer);

const SitePage = (props) => {
  const { match: { params: { prospectId } } } = props;
  return (
    <SiteComponentWithData prospectId={prospectId} />
  );
};

SitePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      prospectId: PropTypes.string,
    }),
  }).isRequired,
};

export default withAuth(SitePage);
