import React from 'react';
import PropTypes from 'prop-types';

export const svgs = {
  ActiveMetersIcon: ({ className }) => (
    <svg className={className} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.6904" cy="10.6865" r="9" stroke="#0087FF" strokeWidth="2" />
      <circle cx="10.6909" cy="10.6865" r="6.57171" stroke="#0087FF" strokeDasharray="1 1" />
      <path d="M10.6909 10.6865L13.1571 6.95373" stroke="#0087FF" strokeWidth="2" strokeLinecap="round" />
    </svg>
  ),
  AdminIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-admin" viewBox="0 0 21 25" {...rest}>
      <path d="M10.5 0L0 4.54545V11.3636C0 17.6705 4.48 23.5682 10.5 25C16.52 23.5682 21 17.6705 21 11.3636V4.54545L10.5 0ZM8.16667 18.1818L3.5 13.6364L5.145 12.0341L8.16667 14.9659L15.855 7.47727L17.5 9.09091L8.16667 18.1818Z" />
    </svg>
  ),
  AlertIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-alert" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="9" cy="9" r="8.5" stroke="#06A7E0" />
      <path d="M9.704 4V7.95028C9.704 8.15746 9.69867 8.36004 9.688 8.55801C9.67733 8.75599 9.664 8.95626 9.648 9.15884C9.632 9.35681 9.61067 9.56169 9.584 9.77348C9.56267 9.98066 9.536 10.204 9.504 10.4434H8.544C8.512 10.204 8.48267 9.98066 8.456 9.77348C8.43467 9.56169 8.416 9.35681 8.4 9.15884C8.384 8.95626 8.37067 8.75599 8.36 8.55801C8.34933 8.36004 8.344 8.15746 8.344 7.95028V4H9.704ZM8 13.1367C8 13.017 8.024 12.9042 8.072 12.7983C8.12533 12.6924 8.19733 12.6004 8.288 12.5221C8.37867 12.4438 8.48267 12.3817 8.6 12.3356C8.72267 12.2896 8.856 12.2666 9 12.2666C9.13867 12.2666 9.26667 12.2896 9.384 12.3356C9.50667 12.3817 9.61333 12.4438 9.704 12.5221C9.79467 12.6004 9.86667 12.6924 9.92 12.7983C9.97333 12.9042 10 13.017 10 13.1367C10 13.261 9.97333 13.3762 9.92 13.482C9.86667 13.5833 9.79467 13.6731 9.704 13.7514C9.61333 13.8296 9.50667 13.8895 9.384 13.9309C9.26667 13.977 9.13867 14 9 14C8.856 14 8.72267 13.977 8.6 13.9309C8.48267 13.8895 8.37867 13.8296 8.288 13.7514C8.19733 13.6731 8.12533 13.5833 8.072 13.482C8.024 13.3762 8 13.261 8 13.1367Z" fill="#06A7E0" />
    </svg>
  ),
  AlertGreyIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-alert-grey" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="9" cy="9" r="8.5" stroke="#B2ADA6" />
      <path d="M9.704 4V7.95028C9.704 8.15746 9.69867 8.36004 9.688 8.55801C9.67733 8.75599 9.664 8.95626 9.648 9.15884C9.632 9.35681 9.61067 9.56169 9.584 9.77348C9.56267 9.98066 9.536 10.204 9.504 10.4434H8.544C8.512 10.204 8.48267 9.98066 8.456 9.77348C8.43467 9.56169 8.416 9.35681 8.4 9.15884C8.384 8.95626 8.37067 8.75599 8.36 8.55801C8.34933 8.36004 8.344 8.15746 8.344 7.95028V4H9.704ZM8 13.1367C8 13.017 8.024 12.9042 8.072 12.7983C8.12533 12.6924 8.19733 12.6004 8.288 12.5221C8.37867 12.4438 8.48267 12.3817 8.6 12.3356C8.72267 12.2896 8.856 12.2666 9 12.2666C9.13867 12.2666 9.26667 12.2896 9.384 12.3356C9.50667 12.3817 9.61333 12.4438 9.704 12.5221C9.79467 12.6004 9.86667 12.6924 9.92 12.7983C9.97333 12.9042 10 13.017 10 13.1367C10 13.261 9.97333 13.3762 9.92 13.482C9.86667 13.5833 9.79467 13.6731 9.704 13.7514C9.61333 13.8296 9.50667 13.8895 9.384 13.9309C9.26667 13.977 9.13867 14 9 14C8.856 14 8.72267 13.977 8.6 13.9309C8.48267 13.8895 8.37867 13.8296 8.288 13.7514C8.19733 13.6731 8.12533 13.5833 8.072 13.482C8.024 13.3762 8 13.261 8 13.1367Z" fill="#B2ADA6" />
    </svg>
  ),
  ArrowIcon: ({ className, ...rest }) => (
    <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M1.84277 1.96289L6.84277 7.23119L11.8428 1.96289" stroke="#0087FF" strokeWidth="2" strokeLinecap="round" />
    </svg>
  ),
  BlueCircleIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-blue-circle" width="13" height="13" viewBox="0 0 13 13" fill="none" {...rest}>
      <circle cx="6.15186" cy="6.00122" r="6" fill="#0087FF" />
    </svg>
  ),
  BlueMinusIcon: ({ className, ...rest }) => (
    <svg className={className} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect x="0.065918" y="0.490723" width="25" height="25" fill="#0087FF" />
      <path d="M8.06592 12.4907H16.0659V14.4907H8.06592V12.4907Z" fill="white" />
    </svg>
  ),
  BluePlusIcon: ({ className, ...rest }) => (
    <svg className={className} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect x="0.065918" y="0.490723" width="25" height="25" fill="#0087FF" />
      <path d="M16.0659 13.6211H12.8823V16.4907H11.2331V13.6211H8.06592V12.3464H11.2331V9.49072H12.8823V12.3464H16.0659V13.6211Z" fill="white" />
    </svg>
  ),
  BoltIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-bolt" viewBox="0 0 15 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M0.46582 14.2576L14.2158 0.507568L9.02714 11.5076H14.2158L0.46582 25.5076L6.17337 14.2576H0.46582Z" fill="#EF8666" />
    </svg>
  ),
  CaretDownIcon: ({ className, ...rest }) => (
    <svg className={className} width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M5 7.96387L0.669872 0.463868L9.33013 0.463867L5 7.96387Z" fill="#0087FF" />
    </svg>
  ),
  CaretUpIcon: ({ className, ...rest }) => (
    <svg className={className} width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M5 0L9.33013 7.5L0.669873 7.5L5 0Z" fill="#0087FF" />
    </svg>
  ),
  ChartAxisIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-chart-axis" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M0.63324 1.10924V18.2518H20.6332" stroke="black" strokeLinecap="round" />
    </svg>
  ),
  ChartXLineIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-chart-x-line" viewBox="0 0 18 3" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M1.17648 1.68083H16.1766" strokeWidth="2" strokeLinecap="round" />
    </svg>
  ),
  CheckIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-check" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M0.193359 10.6727L1.15721 7.38903L4.11959 11.4936L12.5785 0.10791L14.4345 4.06968L4.11959 13.9563L0.193359 10.6727Z" fill="#00B085" />
    </svg>
  ),
  ClipboardIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-clipboard" width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect x="0.5" y="0.5" width="15.7143" height="17" rx="0.5" stroke="#FF0000" />
      <line x1="3.21387" y1="14.2857" x2="13.4996" y2="14.2857" stroke="#F44D45" />
      <line x1="3.21387" y1="12.3571" x2="13.4996" y2="12.3571" stroke="#F44D45" />
      <line x1="3.21387" y1="10.4286" x2="13.4996" y2="10.4286" stroke="#F44D45" />
      <line x1="3.21387" y1="8.5" x2="13.4996" y2="8.5" stroke="#F44D45" />
      <line x1="3.21387" y1="6.57141" x2="13.4996" y2="6.57141" stroke="#F44D45" />
      <circle cx="12.5356" cy="3.5357" r="0.5" stroke="#FF0000" strokeWidth="0.928571" />
      <circle cx="8.67815" cy="3.5357" r="0.5" stroke="#FF0000" strokeWidth="0.928571" />
      <circle cx="4.49958" cy="3.2143" r="1.28571" fill="#FF0000" />
    </svg>
  ),
  ClockIcon: ({ className, ...rest }) => (
    <svg className={className} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="10.0771" cy="10.5642" r="9.5" stroke="#0087FF" />
      <path d="M9.68424 11.2825L13.0176 4.61588" stroke="#0087FF" strokeWidth="2" strokeLinecap="round" />
    </svg>
  ),
  CloseXIcon: ({ className }) => (
    <svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.78418 1.00017L10.7842 10.9998" stroke="black" strokeWidth="2" />
      <path d="M10.7842 1L0.784583 11" stroke="black" strokeWidth="2" />
    </svg>
  ),
  CloudIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-cloud" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M19.8562 7.98189C19.7771 7.69929 19.6379 7.43711 19.4481 7.21326C19.2583 6.98942 19.0224 6.80919 18.7566 6.68489C18.4702 6.52042 18.1539 6.41496 17.8261 6.37474C17.705 6.35423 17.5823 6.34479 17.4596 6.34654C17.5688 5.63277 17.4504 4.90275 17.1212 4.26006C16.8993 3.81741 16.5901 3.42431 16.2121 3.10448C15.8341 2.78464 15.3952 2.5447 14.922 2.39914C14.6006 2.30893 14.2688 2.26153 13.9351 2.25817C13.5225 2.25254 13.1122 2.31934 12.7227 2.45554L12.6099 2.48373C12.5817 2.45554 12.5817 2.39914 12.4971 2.31456C12.1262 1.73945 11.6475 1.24157 11.0873 0.848382C10.3345 0.277275 9.40983 -0.0209927 8.46514 0.00251067C8.09676 -0.00356616 7.72862 0.0247521 7.36551 0.0870976C6.53281 0.281794 5.76515 0.689918 5.13805 1.27132C4.69739 1.69317 4.33468 2.18952 4.06661 2.73749C3.92054 3.05407 3.80717 3.38474 3.72826 3.72434C3.66904 3.99348 3.63133 4.2669 3.61548 4.54202C3.24374 4.52964 2.87244 4.57725 2.51585 4.683C2.15727 4.79587 1.81578 4.95714 1.5008 5.16232C1.03588 5.49454 0.659241 5.9354 0.403695 6.44649C0.148149 6.95758 0.0214459 7.52341 0.0346236 8.09468C0.0430615 8.64434 0.178141 9.18466 0.429363 9.67364C0.703384 10.186 1.10068 10.622 1.58539 10.9424C2.07586 11.2872 2.65095 11.4919 3.24893 11.5346H17.1212C17.4137 11.562 17.7087 11.543 17.9953 11.4782C18.4384 11.381 18.8473 11.1668 19.1795 10.8579C19.5699 10.5031 19.8435 10.0384 19.9644 9.52493C20.0852 9.01149 20.0475 8.47347 19.8562 7.98189Z" fill="#B2ADA6" />
    </svg>
  ),
  CollapseIcon: ({ className, ...rest }) => (
    <svg className={className} width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="15" cy="15.1929" r="14.5" stroke="#0087FF" />
      <path d="M20.3569 15.9058H15.7985H14.1786H9.64258V14.4477H14.1786H15.7985H20.3569V15.9058Z" fill="#0087FF" />
    </svg>

  ),
  DoubleArrowIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-double-arrow" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.28582 7.38439H0.000147314L7.50007 0.403076L15 7.38439L10.7143 7.38439V11.3737H10.7142V15.0004H14.9999L7.49993 21.9817L0 15.0004H4.28567V11.0111H4.28582L4.28582 7.38439Z" fill="#0087FF" />
    </svg>
  ),
  DownArrowIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-down-arrow" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M6.06875 8.34904L6.06875 0.758975L10.1986 0.758975L14.3285 0.758975L14.3285 8.34904L20.1865 8.34904L10.1986 18.7566L0.186524 8.34904L6.06875 8.34904Z" fill="#001A3C" />
    </svg>
  ),
  DownChartIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-down-chart" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M1.63379 1.67078L6.55461 6.5916L10.024 3.12224L14.6616 7.75986" stroke="#EF8666" strokeWidth="2" />
      <path d="M15.2012 4.10803V8.34435H11.1016" stroke="#EF8666" strokeWidth="2" />
    </svg>
  ),
  DownloadIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-download" width="21" height="20" viewBox="0 0 21 20" fill="none" {...rest}>
      <circle cx="10.269" cy="10" r="10" fill="#0087FF" />
      <path d="M14.1152 7.64102H11.9174V5.38461H8.62071V7.64102H6.42291L10.2691 11.5897L14.1152 7.64102ZM6.42291 12.7179V13.8462H14.1152V12.7179H6.42291Z" fill="white" />
    </svg>
  ),
  DropdownIcon: ({ className, ...rest }) => (
    <svg className={className} width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M4.88379 7.68042L0.553661 0.180421L9.21392 0.18042L4.88379 7.68042Z" fill="#000000" {...rest} />
    </svg>
  ),
  ExpandIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-expand" viewBox="0 0 20 20" fill="none" {...rest}>
      <circle cx="10" cy="10" r="10" fill="#0087FF" />
      <path d="M13.5716 10.4752H10.5327V13.5714H9.45269V10.4752H6.42871V9.50319H9.45269V6.42859H10.5327V9.50319H13.5716V10.4752Z" fill="white" />
    </svg>
  ),
  ExternalLinksIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-external-links" width="21" height="20" viewBox="0 0 21 20" {...rest}>
      <path
        d="M10.5 0C4.98 0 0.5 4.48 0.5 10C0.5 15.52 4.98 20 10.5 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.5 0ZM9.5 17.93C5.55 17.44 2.5 14.08 2.5 10C2.5 9.38 2.58 8.79 2.71 8.21L7.5 13V14C7.5 15.1 8.4 16 9.5 16V17.93ZM16.4 15.39C16.14 14.58 15.4 14 14.5 14H13.5V11C13.5 10.45 13.05 10 12.5 10H6.5V8H8.5C9.05 8 9.5 7.55 9.5 7V5H11.5C12.6 5 13.5 4.1 13.5 3V2.59C16.43 3.78 18.5 6.65 18.5 10C18.5 12.08 17.7 13.97 16.4 15.39Z"
      />
    </svg>
  ),
  FullscreenIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-fullscreen" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path opacity="0.8" d="M21.269 5.62036V2.62036C21.269 1.51579 20.3736 0.620361 19.269 0.620361H16.269" stroke="#2F80ED" />
      <path opacity="0.8" d="M16.269 20.6204L19.269 20.6204C20.3736 20.6204 21.269 19.7249 21.269 18.6204L21.269 15.6204" stroke="#2F80ED" />
      <path opacity="0.8" d="M1.26904 15.6204L1.26904 18.6204C1.26904 19.7249 2.16447 20.6204 3.26904 20.6204L6.26904 20.6204" stroke="#2F80ED" />
      <path opacity="0.8" d="M6.26904 0.620362L3.26904 0.620362C2.16447 0.620361 1.26904 1.51579 1.26904 2.62036L1.26904 5.62036" stroke="#2F80ED" />
      <rect opacity="0.8" x="4.26904" y="3.62036" width="14" height="14" rx="2" fill="#2F80ED" />
    </svg>
  ),
  HistoryIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-history" viewBox="0 0 12 13" fill="none" {...rest}>
      <path d="M6.3426 2.14977C7.31669 2.14977 8.26891 2.43862 9.07885 2.9798C9.88878 3.52098 10.52 4.29017 10.8928 5.19012C11.2656 6.09007 11.3631 7.08035 11.1731 8.03572C10.983 8.9911 10.514 9.86868 9.82518 10.5575C9.13639 11.2463 8.25882 11.7153 7.30344 11.9054C6.34806 12.0954 5.35779 11.9979 4.45784 11.6251C3.55789 11.2523 2.78869 10.6211 2.24751 9.81113C1.70633 9.0012 1.41748 8.04898 1.41748 7.07489" stroke="#0087FF" />
      <path d="M7.34131 3.64221L5.84886 2.14985L7.34131 0.657405" stroke="#0087FF" strokeLinecap="round" />
      <circle cx="6.34301" cy="7.07494" r="1.73485" fill="#0087FF" />
    </svg>
  ),
  InfoIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-info" width="21" height="21" viewBox="0 0 21 21" fill="none" {...rest}>
      <circle cx="10.269" cy="10.5" r="10" fill="#0087FF" />
      <path d="M10.9377 8.96527V14.5H9.59992V8.96527H10.9377ZM11.1212 7.35233C11.1212 7.46741 11.0978 7.5753 11.051 7.67599C11.0043 7.77669 10.9413 7.8648 10.8622 7.94032C10.7867 8.01585 10.6968 8.07698 10.5925 8.12374C10.4882 8.16689 10.3767 8.18847 10.258 8.18847C10.143 8.18847 10.0333 8.16689 9.92898 8.12374C9.82828 8.07698 9.74017 8.01585 9.66465 7.94032C9.58913 7.8648 9.52799 7.77669 9.48124 7.67599C9.43808 7.5753 9.4165 7.46741 9.4165 7.35233C9.4165 7.23365 9.43808 7.12216 9.48124 7.01787C9.52799 6.91358 9.58913 6.82367 9.66465 6.74815C9.74017 6.67262 9.82828 6.61328 9.92898 6.57013C10.0333 6.52338 10.143 6.5 10.258 6.5C10.3767 6.5 10.4882 6.52338 10.5925 6.57013C10.6968 6.61328 10.7867 6.67262 10.8622 6.74815C10.9413 6.82367 11.0043 6.91358 11.051 7.01787C11.0978 7.12216 11.1212 7.23365 11.1212 7.35233Z" fill="white" />
    </svg>
  ),
  InternalLinksIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-internal-links" width="17" height="15" viewBox="0 0 17 15" {...rest}>
      <path
        d="M0.5 10.0189H16.5V8.01788H0.5V10.0189ZM0.5 14.0208H16.5V12.0199H0.5V14.0208ZM0.5 6.01689H16.5V4.0159H0.5V6.01689ZM0.5 0.013916V2.01491H16.5V0.013916H0.5Z"
      />
    </svg>
  ),
  LogoIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-logo" width="91" height="36" viewBox="0 0 91 36" fill="none" {...rest}>
      <path d="M87.5954 3.15159C87.938 3.15159 88.1663 3.03689 88.1663 2.6928C88.1663 2.3487 87.938 2.23401 87.5954 2.23401H87.1387V3.15159H87.5954ZM86.5678 1.66052H87.7096C88.3947 1.66052 88.9656 2.00461 88.9656 2.5781C88.9656 3.03689 88.7372 3.26629 88.2805 3.38099L89.0797 4.75736H88.3947L87.5954 3.49568H87.2529V4.75736H86.5678V1.66052ZM90.2215 3.26629C90.2215 1.66052 89.0797 0.628238 87.5954 0.628238C86.1111 0.628238 84.9693 1.77522 84.9693 3.26629C84.9693 4.87206 86.1111 5.90434 87.5954 5.90434C89.0797 5.90434 90.2215 4.87206 90.2215 3.26629ZM84.3984 3.26629C84.3984 1.43112 85.7686 0.0547485 87.5954 0.0547485C89.4223 0.0547485 90.7924 1.43112 90.7924 3.26629C90.7924 5.10145 89.4223 6.47783 87.5954 6.47783C85.8828 6.47783 84.3984 5.10145 84.3984 3.26629Z" fill="#FFD45C" />
      <path d="M43.0018 6.47739C35.6567 6.47739 31.0098 10.1995 31.0098 17.1969V22.1101V23.3011V25.2366C31.0098 32.0852 35.8066 35.9561 43.0018 35.9561C50.3469 35.9561 54.8439 32.383 54.8439 25.3855V23.1522V22.1101V16.8992C54.8439 10.0506 50.3469 6.47739 43.0018 6.47739ZM48.8479 24.4922C48.8479 28.3631 47.3489 30.4475 43.0018 30.4475C38.3549 30.4475 37.1557 28.0654 37.1557 24.3433V23.1522V21.9612V17.9413C37.1557 14.2193 38.5048 11.8372 43.0018 11.8372C47.3489 11.8372 48.8479 13.9215 48.8479 17.7925V22.1101V23.1522V24.4922Z" fill="#FFD45C" />
      <path d="M60.5645 17.1969C60.5645 10.1995 65.2114 6.47739 72.5565 6.47739C79.9016 6.47739 84.3986 10.0506 84.3986 16.8992L78.4026 16.8415C78.4026 12.9706 76.9036 11.8372 72.5565 11.8372C68.0595 11.8372 66.7104 14.2193 66.7104 17.9413V21.9612V23.1522V24.3433C66.7104 28.0654 67.9096 30.4475 72.5565 30.4475C76.9036 30.4475 78.4026 29.3141 78.4026 25.4431L84.3986 25.3855C84.3986 32.383 79.9016 35.9561 72.5565 35.9561C65.3613 35.9561 60.5645 32.0852 60.5645 25.2366V23.3011V22.1101V17.1969Z" fill="#FFD45C" />
      <path d="M12.8958 6.42566C20.2032 6.42566 24.6561 10.096 24.6561 17.0926V34.3786C24.6561 34.608 24.4278 34.9521 24.1994 34.9521H19.0614C18.7189 34.9521 18.6047 34.608 18.6047 34.3786V17.8954C18.6047 13.9957 17.0062 11.9312 12.7816 11.9312C8.21452 11.9312 6.84439 14.3398 6.84439 18.1248V34.608C6.84439 34.7227 6.61603 35.0668 6.38767 35.0668H1.24967C1.02132 35.0668 0.792969 34.8374 0.792969 34.608V17.2073C0.792969 10.2107 5.58844 6.42566 12.8958 6.42566Z" fill="#FFD45C" />
    </svg>
  ),
  LogoutIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-logout" viewBox="0 0 21 28" {...rest}>
      <path
        d="M18.375 9.33333H17.0625V6.66667C17.0625 2.98667 14.1225 0 10.5 0C6.8775 0 3.9375 2.98667 3.9375 6.66667V9.33333H2.625C1.18125 9.33333 0 10.5333 0 12V25.3333C0 26.8 1.18125 28 2.625 28H18.375C19.8188 28 21 26.8 21 25.3333V12C21 10.5333 19.8188 9.33333 18.375 9.33333ZM10.5 21.3333C9.05625 21.3333 7.875 20.1333 7.875 18.6667C7.875 17.2 9.05625 16 10.5 16C11.9438 16 13.125 17.2 13.125 18.6667C13.125 20.1333 11.9438 21.3333 10.5 21.3333ZM14.5688 9.33333H6.43125V6.66667C6.43125 4.38667 8.25562 2.53333 10.5 2.53333C12.7444 2.53333 14.5688 4.38667 14.5688 6.66667V9.33333Z"
      />
    </svg>
  ),
  MapMarkerIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-map-marker" width="41" height="58" viewBox="0 0 41 58" fill="none" {...rest}>
      <circle cx="20.6207" cy="20.5595" r="7.13046" fill="white" />
      <path d="M20.6206 0.624878C9.56346 0.624878 0.620605 9.56774 0.620605 20.6249C0.620605 35.6249 20.6206 57.7677 20.6206 57.7677C20.6206 57.7677 40.6206 35.6249 40.6206 20.6249C40.6206 9.56774 31.6777 0.624878 20.6206 0.624878ZM20.6206 27.7677C16.6777 27.7677 13.4777 24.5677 13.4777 20.6249C13.4777 16.682 16.6777 13.482 20.6206 13.482C24.5635 13.482 27.7635 16.682 27.7635 20.6249C27.7635 24.5677 24.5635 27.7677 20.6206 27.7677Z" fill="#0087FF" />
    </svg>
  ),
  MettaIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-meta" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <ellipse cx="10.4609" cy="3.41135" rx="10" ry="2.45456" fill="#FFB26D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.460938 4.99976V8.37428C0.460938 9.7299 4.93809 10.8288 10.4609 10.8288C15.9838 10.8288 20.4609 9.7299 20.4609 8.37428V4.99976C20.4609 6.35536 15.9837 7.45428 10.4609 7.45428C4.93814 7.45428 0.461023 6.35536 0.460938 4.99976Z" fill="#FFB26D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.4609 12.219C15.9838 12.219 20.4609 11.12 20.4609 9.7644V13.1389C20.4609 14.4945 15.9838 15.5935 10.4609 15.5935C4.93809 15.5935 0.460938 14.4945 0.460938 13.1389V9.7644C0.460938 11.12 4.93809 12.219 10.4609 12.219Z" fill="#FFB26D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10.4609 16.9839C15.9838 16.9839 20.4609 15.8849 20.4609 14.5293V17.9038C20.4609 19.2594 15.9838 20.3584 10.4609 20.3584C4.93809 20.3584 0.460938 19.2594 0.460938 17.9038V14.5293C0.46095 15.8849 4.9381 16.9839 10.4609 16.9839Z" fill="#FFB26D" />
    </svg>
  ),
  NetworkErrorIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-network-error" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M12.2061 0.802612L15.6724 4.26893" stroke="#FF0000" strokeLinecap="round" />
      <path d="M15.6724 0.802612L12.206 4.26893" stroke="#FF0000" strokeLinecap="round" />
      <path d="M5.09488 15.8024C5.09488 15.2216 4.98049 14.6465 4.75824 14.11C4.53599 13.5734 4.21023 13.0859 3.79956 12.6752C3.38889 12.2645 2.90135 11.9388 2.36479 11.7165C1.82822 11.4943 1.25314 11.3799 0.672363 11.3799" stroke="#0087FF" strokeWidth="2.5" />
      <path d="M13.9389 15.8023C13.9389 14.0601 13.5958 12.335 12.929 10.7254C12.2623 9.11585 11.2851 7.65336 10.0532 6.42145C8.82131 5.18953 7.35882 4.21233 5.74925 3.54562C4.13968 2.87892 2.41455 2.53577 0.672363 2.53577" stroke="#0087FF" strokeWidth="2.5" />
      <path d="M9.66415 15.8023C9.66415 14.6215 9.43157 13.4523 8.97969 12.3613C8.52781 11.2704 7.86548 10.2791 7.03052 9.44418C6.19555 8.60922 5.2043 7.94689 4.11337 7.49501C3.02244 7.04313 1.85318 6.81055 0.672363 6.81055" stroke="#0087FF" strokeWidth="2.5" />
    </svg>
  ),
  OpenIssuesIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-open-issues" viewBox="0 0 23 24" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 23.5C17.8513 23.5 23 18.3513 23 12C23 5.64873 17.8513 0.5 11.5 0.5C5.14873 0.5 0 5.64873 0 12C0 18.3513 5.14873 23.5 11.5 23.5ZM12.292 10.632V5.48401H10.762V10.632C10.762 10.902 10.768 11.166 10.78 11.424C10.792 11.682 10.807 11.943 10.825 12.207C10.843 12.465 10.864 12.732 10.888 13.008C10.918 13.278 10.951 13.569 10.987 13.881H12.067C12.103 13.569 12.133 13.278 12.157 13.008C12.187 12.732 12.211 12.465 12.229 12.207C12.247 11.943 12.262 11.682 12.274 11.424C12.286 11.166 12.292 10.902 12.292 10.632ZM10.456 16.95C10.402 17.088 10.375 17.235 10.375 17.391C10.375 17.553 10.402 17.703 10.456 17.841C10.516 17.973 10.597 18.09 10.699 18.192C10.801 18.294 10.918 18.372 11.05 18.426C11.188 18.486 11.338 18.516 11.5 18.516C11.656 18.516 11.8 18.486 11.932 18.426C12.07 18.372 12.19 18.294 12.292 18.192C12.394 18.09 12.475 17.973 12.535 17.841C12.595 17.703 12.625 17.553 12.625 17.391C12.625 17.235 12.595 17.088 12.535 16.95C12.475 16.812 12.394 16.692 12.292 16.59C12.19 16.488 12.07 16.407 11.932 16.347C11.8 16.287 11.656 16.257 11.5 16.257C11.338 16.257 11.188 16.287 11.05 16.347C10.918 16.407 10.801 16.488 10.699 16.59C10.597 16.692 10.516 16.812 10.456 16.95Z"
      />
    </svg>
  ),
  ProfileIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-profile" viewBox="0 0 23 23" {...rest}>
      <path
        d="M11.5 0C5.152 0 0 5.152 0 11.5C0 17.848 5.152 23 11.5 23C17.848 23 23 17.848 23 11.5C23 5.152 17.848 0 11.5 0ZM11.5 3.45C13.409 3.45 14.95 4.991 14.95 6.9C14.95 8.809 13.409 10.35 11.5 10.35C9.591 10.35 8.05 8.809 8.05 6.9C8.05 4.991 9.591 3.45 11.5 3.45ZM11.5 19.78C8.625 19.78 6.0835 18.308 4.6 16.077C4.6345 13.7885 9.2 12.535 11.5 12.535C13.7885 12.535 18.3655 13.7885 18.4 16.077C16.9165 18.308 14.375 19.78 11.5 19.78Z"
      />
    </svg>
  ),
  SearchIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-search" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M13.6858 11.9904H12.8727L12.5846 11.7126C13.5932 10.5393 14.2004 9.01616 14.2004 7.35921C14.2004 3.66453 11.2055 0.669678 7.51083 0.669678C3.81614 0.669678 0.821289 3.66453 0.821289 7.35921C0.821289 11.0539 3.81614 14.0488 7.51083 14.0488C9.16777 14.0488 10.6909 13.4415 11.8642 12.433L12.142 12.7211V13.5342L17.2878 18.6697L18.8213 17.1362L13.6858 11.9904ZM7.51083 11.9904C4.94822 11.9904 2.87961 9.92182 2.87961 7.35921C2.87961 4.79661 4.94822 2.728 7.51083 2.728C10.0734 2.728 12.142 4.79661 12.142 7.35921C12.142 9.92182 10.0734 11.9904 7.51083 11.9904Z" fill="#0087FF" />
    </svg>
  ),
  SettingsIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-settings" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M11 0.835371L12.5331 2.88728C12.8097 3.25752 13.304 3.38997 13.7287 3.20764L16.0823 2.19717L16.384 4.74072C16.4385 5.19966 16.8003 5.56151 17.2593 5.61596L19.8028 5.91769L18.7924 8.27133C18.61 8.69601 18.7425 9.1903 19.1127 9.46692L21.1646 11L19.1127 12.5331C18.7425 12.8097 18.61 13.304 18.7924 13.7287L19.8028 16.0823L17.2593 16.384C16.8003 16.4385 16.4385 16.8003 16.384 17.2593L16.0823 19.8028L13.7287 18.7924C13.304 18.61 12.8097 18.7425 12.5331 19.1127L11 21.1646L9.46692 19.1127C9.1903 18.7425 8.696 18.61 8.27132 18.7924L5.91769 19.8028L5.61596 17.2593C5.56151 16.8003 5.19966 16.4385 4.74072 16.384L2.19717 16.0823L3.20764 13.7287C3.38997 13.304 3.25752 12.8097 2.88728 12.5331L0.835371 11L2.88728 9.46692C3.25752 9.1903 3.38997 8.696 3.20764 8.27133L2.19717 5.91769L4.74072 5.61596C5.19966 5.56151 5.56151 5.19966 5.61596 4.74072L5.91769 2.19717L8.27133 3.20764C8.696 3.38997 9.1903 3.25752 9.46692 2.88728L11 0.835371Z" stroke="#EEA331" />
      <circle cx="11.2773" cy="11.2778" r="5" stroke="#EEA331" />
    </svg>
  ),
  SolarIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-solar" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M1.92236 1.20996L8.51061 9.20996H21.9224L15.3342 1.20996H1.92236Z" stroke="#F44D45" />
      <path d="M17.8209 9.09576L11.4443 1.35278" stroke="#F44D45" />
      <path d="M12.9419 9.09568L6.58887 1.38135" stroke="#F44D45" />
      <path d="M4.17041 3.74268H17.2997" stroke="#F44D45" />
      <path d="M6.58887 6.7218H19.7182" stroke="#F44D45" />
      <path d="M4.2749 4.22961V13.4539H17.3778V8.9692" stroke="#F44D45" />
    </svg>
  ),
  TruckIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-truck" viewBox="0 0 22 14" fill="none" {...rest}>
      <path d="M14.8063 3.81131H17.9075C18.1617 3.81131 18.4158 3.94146 18.5429 4.1497L19.8648 6.59656H20.2461C20.6782 6.59656 21.0341 6.88289 21.0341 7.48159V10.1887C21.0341 10.6313 20.6782 10.9957 20.2461 10.9957H19.458" stroke="#0087FF" strokeMiterlimit="10" />
      <path d="M17.9328 13C18.8032 13 19.5088 12.2891 19.5088 11.4122C19.5088 10.5352 18.8032 9.82431 17.9328 9.82431C17.0624 9.82431 16.3568 10.5352 16.3568 11.4122C16.3568 12.2891 17.0624 13 17.9328 13Z" stroke="#0087FF" strokeMiterlimit="10" />
      <path d="M7.38381 13C8.25422 13 8.95982 12.2891 8.95982 11.4122C8.95982 10.5352 8.25422 9.82431 7.38381 9.82431C6.5134 9.82431 5.8078 10.5352 5.8078 11.4122C5.8078 12.2891 6.5134 13 7.38381 13Z" stroke="#0087FF" strokeMiterlimit="10" />
      <path d="M14.8063 11.0217H16.3823" stroke="#0087FF" strokeMiterlimit="10" />
      <path d="M12.4991 3.85562H9.35467M9.35467 7.00001H3.96429" stroke="#0087FF" strokeWidth="2" />
      <path d="M8.32787 1H1.26904V10.8824H5.85728" stroke="#0087FF" />
      <path d="M8.32843 1H14.6814V11.2353H9.38725" stroke="#0087FF" />
    </svg>
  ),
  WavyChartLineIcon: ({ className, ...rest }) => (
    <svg className={className} id="icon-wavy-chart-line" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M0.833252 3.55623L2.58324 1.67816L4.33324 3.55623L6.08325 1.67816L7.83325 3.55623" strokeLinecap="round" />
    </svg>
  ),
};

const svgPropTypes = {
  className: PropTypes.string.isRequired,
};

svgs.ActiveMetersIcon.propTypes = svgPropTypes;
svgs.AdminIcon.propTypes = svgPropTypes;
svgs.AlertIcon.propTypes = svgPropTypes;
svgs.AlertGreyIcon.propTypes = svgPropTypes;
svgs.ArrowIcon.propTypes = svgPropTypes;
svgs.BlueCircleIcon.propTypes = svgPropTypes;
svgs.BlueMinusIcon.propTypes = svgPropTypes;
svgs.BluePlusIcon.propTypes = svgPropTypes;
svgs.BoltIcon.propTypes = svgPropTypes;
svgs.CaretDownIcon.propTypes = svgPropTypes;
svgs.CaretUpIcon.propTypes = svgPropTypes;
svgs.ChartAxisIcon.propTypes = svgPropTypes;
svgs.ChartXLineIcon.propTypes = svgPropTypes;
svgs.CheckIcon.propTypes = svgPropTypes;
svgs.ClipboardIcon.propTypes = svgPropTypes;
svgs.ClockIcon.propTypes = svgPropTypes;
svgs.CloseXIcon.propTypes = svgPropTypes;
svgs.CloudIcon.propTypes = svgPropTypes;
svgs.CollapseIcon.propTypes = svgPropTypes;
svgs.DoubleArrowIcon.propTypes = svgPropTypes;
svgs.DownArrowIcon.propTypes = svgPropTypes;
svgs.DownChartIcon.propTypes = svgPropTypes;
svgs.DownloadIcon.propTypes = svgPropTypes;
svgs.DropdownIcon.propTypes = svgPropTypes;
svgs.ExpandIcon.propTypes = svgPropTypes;
svgs.ExternalLinksIcon.propTypes = svgPropTypes;
svgs.FullscreenIcon.propTypes = svgPropTypes;
svgs.HistoryIcon.propTypes = svgPropTypes;
svgs.InfoIcon.propTypes = svgPropTypes;
svgs.InternalLinksIcon.propTypes = svgPropTypes;
svgs.LogoIcon.propTypes = svgPropTypes;
svgs.LogoutIcon.propTypes = svgPropTypes;
svgs.MapMarkerIcon.propTypes = svgPropTypes;
svgs.MettaIcon.propTypes = svgPropTypes;
svgs.NetworkErrorIcon.propTypes = svgPropTypes;
svgs.SearchIcon.propTypes = svgPropTypes;
svgs.SettingsIcon.propTypes = svgPropTypes;
svgs.SolarIcon.propTypes = svgPropTypes;
svgs.OpenIssuesIcon.propTypes = svgPropTypes;
svgs.ProfileIcon.propTypes = svgPropTypes;
svgs.TruckIcon.propTypes = svgPropTypes;
svgs.WavyChartLineIcon.propTypes = svgPropTypes;
