import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-flexbox-grid';

const Versions = ({ data }) => {
  const getVersions = () => {
    return Object.entries(data.versions.PACKAGE_VERSIONS)
      .map(([k, v]) => (
        <Row key={k} between="xs">
          <Col>{k.replace('@sunrun/', '')}</Col>
          <Col>{v}</Col>
        </Row>
      ));
  };
  return (
    <Fragment>
      &nbsp;
      <h4>Version Info</h4>
      &nbsp;
      <Grid>
        <Row key="ui" between="xs">
          <Col>NOC-UI:</Col>
          <Col>2.0</Col>
        </Row>
        <Row key="sha" between="xs">
          <Col>SHA:</Col>
          <Col>{data && data.versions && data.versions.SHA}</Col>
        </Row>
        <Row key="packageversions" between="xs">
          <Col>PACKAGE VERSIONS:</Col>
        </Row>
        {data && data.versions && getVersions()}
      </Grid>
    </Fragment>
  );
};

Versions.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default Versions;
