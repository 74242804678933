import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Icon from './Icon';

import './ToggleButton.scss';

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
`;

const ToggleButton = ({ isExpanded, clickHandler, className }) => (
  <Button className={className} type="button" onClick={clickHandler}>
    {
      isExpanded && (
        <Icon name="CollapseIcon" width={30} height={30} />
      )
    }
    {
      !isExpanded && (
        <Icon name="ExpandIcon" width={30} height={30} />
      )
    }
  </Button>
);

ToggleButton.propTypes = {
  isExpanded: PropTypes.bool,
  className: PropTypes.string,
  clickHandler: PropTypes.func,
};

ToggleButton.defaultProps = {
  isExpanded: false,
  className: undefined,
  clickHandler: undefined,
};

export default ToggleButton;
