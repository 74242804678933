import React from 'react';
import PropTypes from 'prop-types';
import { flowRight, get } from 'lodash';
import { dateFormat } from '../../../general/utils/dateFormatUtils';
import { withMeterInfo } from '../../queries/withSiteQueries';
import Table from '../../../general/components/Table';
import { meterInfoType } from '../types';

const meteringColumns = [
  {
    key: 'meter',
    name: 'Meter',
    render: meter => meter.index,
  },
  {
    key: 'serial_number',
    name: 'Serial Number',
    render: meter => meter.serialNumber,
  },
  {
    key: 'manufacturer',
    name: 'Manufacturer',
    render: meter => meter.manufacturer || meter.vendorName,
  },
  {
    key: 'install_date',
    name: 'Install Date',
    render: meter => dateFormat(meter.inServiceDate),
  },
  {
    key: 'last_read_date',
    name: 'Last Read Date',
    render: (meter) => {
      const readingEndTimeLocal = get(meter, 'statusDetails.readingEndTimeLocal', '');
      if (readingEndTimeLocal) {
        return dateFormat(readingEndTimeLocal);
      }
      return '';
    },
  },
];

const getMeteringColumns = (hideInactive) => {
  return hideInactive
    ? meteringColumns
    : meteringColumns.concat([{
      key: 'is_active',
      name: 'Active / Inactive',
      render: meter => meter.status,
    }]);
};

export const MeteringDetailsTableComponent = ({ meterInfoQuery, hideInactive }) => {
  return (
    <Table
      columns={getMeteringColumns(hideInactive)}
      getUniqueKey={row => row.serialNumber}
      rows={meterInfoQuery.meterInfo
        .filter(meter => meter.status !== 'In Error')
        .filter(meter => (hideInactive ? meter.status !== 'Inactive' : true))
        .map((interval, i) => {
          return { ...interval, index: i + 1 };
        })
      }
    />
  );
};

MeteringDetailsTableComponent.propTypes = {
  hideInactive: PropTypes.bool,
  meterInfoQuery: PropTypes.shape({
    meterInfo: meterInfoType,
  }).isRequired,
};

MeteringDetailsTableComponent.defaultProps = {
  hideInactive: false,
};

export default flowRight(withMeterInfo)(MeteringDetailsTableComponent);
