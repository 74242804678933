import styled from '@emotion/styled/macro';
import { BLUE } from '../constants/_colors';

const ButtonLink = styled.button`
  background: transparent;
  border: 0;
  color: ${props => props.color || BLUE}};
  cursor: pointer;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  outline: none;
`;

export default ButtonLink;
