import React from 'react';
import { PropTypes } from 'prop-types';
import styled from '@emotion/styled/macro';
import Icon from '../Icon';
import { BLUE, CCC } from '../../constants/_colors';

const BlueTh = styled.th`
  color: ${BLUE};
  font-size: 12px;
  font-weight: normal;
  padding: 10px;
  text-transform: uppercase;
  white-space: nowrap;
`;

const Td = styled.td`
  ${props => (props.isBorderless ? '' : `border-bottom: 1px solid ${CCC};`)}
  font-size: 16px;
  padding: 10px;
  text-align: center;
  white-space: nowrap;
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  tr:last-of-type ${Td} {
    border-bottom: none;
  }
  width: 100%;
`;

const TableWrap = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const Table = ({
  columns, rows, isBorderless, getUniqueKey,
}) => {
  const handleHeaderClick = (column) => {
    if (column.clickHandler) {
      column.clickHandler();
    }
  };

  const getSortIcon = (sortIcon) => {
    if (!sortIcon) return null;
    return sortIcon.sortAscending
      ? <Icon name="CaretDownIcon" style={{ marginBottom: 1, marginLeft: 8 }} />
      : <Icon name="CaretUpIcon" style={{ marginBottom: 1, marginLeft: 8 }} />;
  };

  return (
    <TableWrap>
      <StyledTable>
        <thead>
          <tr>
            {
              columns.map((column) => {
                return (
                  <BlueTh
                    key={`th-${column.key}`}
                    onClick={() => handleHeaderClick(column)}
                    style={column.headStyle ? column.headStyle : {}}
                  >
                    {column.name}
                    {getSortIcon(column.sortIcon)}
                  </BlueTh>
                );
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            rows.map((row, rowIndex) => {
              const uniqueId = getUniqueKey(row, rowIndex);
              return (
                <tr key={`tr-${uniqueId}-`}>
                  {
                    columns.map((column) => {
                      return (
                        <Td
                          isBorderless={isBorderless}
                          key={`td-${column.key}-${uniqueId}`}
                          style={column.style ? column.style : {}}
                        >
                          { column.render && column.render(row, rowIndex) }
                        </Td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </StyledTable>
    </TableWrap>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    headStyle: PropTypes.shape({}),
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    render: PropTypes.func.isRequired,
    clickHandler: PropTypes.func,
    sortIcon: PropTypes.shape({
      sortAscending: PropTypes.bool,
    }),
    style: PropTypes.object,
  })).isRequired,
  getUniqueKey: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isBorderless: PropTypes.bool,
};

Table.defaultProps = {
  isBorderless: false,
};

export default Table;
