import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import { DateTime } from 'luxon';
import { dateFormat } from '../../../general/utils/dateFormatUtils';
import CircleWithTooltip from '../../../general/components/CircleWithTooltip';
import Table from '../../../general/components/Table';
import { withFieldServiceActivity } from '../../queries/withSiteQueries';
import { BLACK, BLUE } from '../../../general/constants/_colors';

const makeSfdcLinkAddress = suffix => `https://na26.salesforce.com/${suffix}`;

const staticHeaderStyle = {
  color: BLACK,
};

const getHeaderStyle = (activeSortColumn, name) => {
  return {
    color: activeSortColumn === name ? BLUE : BLACK,
    cursor: 'pointer',
  };
};

const sortServiceHistory = (column, isAscending, serviceHistory) => {
  if (column === 'reason' || column === 'subsetReason') {
    if (isAscending) {
      return serviceHistory.sort((a, b) => {
        if (a[column] > b[column]) return 1;
        if (a[column] < b[column]) return -1;
        return 0;
      });
    }
    return serviceHistory.sort((a, b) => {
      if (a[column] > b[column]) return -1;
      if (a[column] < b[column]) return 1;
      return 0;
    });
  }
  if (isAscending) {
    return serviceHistory.sort((a, b) => DateTime.fromISO(a[column]).toMillis() - DateTime.fromISO(b[column]).toMillis());
  }
  return serviceHistory.sort((a, b) => DateTime.fromISO(b[column]).toMillis() - DateTime.fromISO(a[column]).toMillis());
};

const FieldServiceActivity = ({ fieldServiceActivityQuery }) => {
  const [sortAscending, setSortAscending] = useState(false);
  const [activeSortColumn, setActiveSortColumn] = useState('createdDate');
  if (fieldServiceActivityQuery.fieldServiceActivityGroupedByCase.length === 0) return null;
  const columns = [
    {
      headStyle: staticHeaderStyle,
      key: 'Case Number',
      name: 'Case Number',
      render: c => (
        <a
          href={makeSfdcLinkAddress(c.caseId)}
          rel="noopener noreferrer"
          target="_blank"
        >
          {c.caseNumber}
        </a>
      ),
    },
    {
      headStyle: staticHeaderStyle,
      key: 'Case Status',
      name: 'Case Status',
      render: c => c.status,
    },
    {
      headStyle: staticHeaderStyle,
      key: 'Case Origin',
      name: 'Case Origin',
      render: c => c.origin,
    },
    {
      clickHandler: () => {
        if (activeSortColumn === 'reason') return setSortAscending(!sortAscending);
        setSortAscending(true);
        return setActiveSortColumn('reason');
      },
      headStyle: getHeaderStyle(activeSortColumn, 'reason'),
      key: 'Case Reason',
      name: 'Case Reason',
      render: c => c.reason,
      sortIcon: {
        sortAscending: activeSortColumn === 'reason' ? sortAscending : true,
      },
    },
    {
      clickHandler: () => {
        if (activeSortColumn === 'subsetReason') return setSortAscending(!sortAscending);
        setSortAscending(true);
        return setActiveSortColumn('subsetReason');
      },
      headStyle: getHeaderStyle(activeSortColumn, 'subsetReason'),
      key: 'Case Subset Reason',
      name: 'Case Subset Reason',
      render: c => c.subsetReason,
      sortIcon: {
        sortAscending: activeSortColumn === 'subsetReason' ? sortAscending : true,
      },
    },
    {
      clickHandler: () => {
        if (activeSortColumn === 'createdDate') return setSortAscending(!sortAscending);
        setSortAscending(false);
        return setActiveSortColumn('createdDate');
      },
      headStyle: getHeaderStyle(activeSortColumn, 'createdDate'),
      key: 'Case Open',
      name: 'Case Open',
      render: c => dateFormat(c.createdDate),
      sortIcon: {
        sortAscending: activeSortColumn === 'createdDate' ? !sortAscending : true,
      },
    },
    {
      clickHandler: () => {
        if (activeSortColumn === 'closedDate') return setSortAscending(!sortAscending);
        setSortAscending(false);
        return setActiveSortColumn('closedDate');
      },
      headStyle: getHeaderStyle(activeSortColumn, 'closedDate'),
      key: 'Case Closed',
      name: 'Case Closed',
      render: c => dateFormat(c.closedDate),
      sortIcon: {
        sortAscending: activeSortColumn === 'closedDate' ? !sortAscending : true,
      },
    },
    {
      headStyle: staticHeaderStyle,
      key: 'Dispatches',
      name: 'Dispatch',
      render: (c) => {
        return (
          <ul>
            {c.dispatchNames.map((dispatch, index) => (
              <li key={`${dispatch.id}-${dispatch.name}-${index + 1}`}>
                <a
                  href={makeSfdcLinkAddress(dispatch.id)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {dispatch.name}
                </a>
              </li>
            ))}
          </ul>
        );
      },
    },
    {
      headStyle: staticHeaderStyle,
      key: 'Findings',
      name: 'Findings',
      render: c => c.findings.filter(Boolean).map((finding, index) => (
        <CircleWithTooltip key={`${finding}-${index + 1}`}>
          {finding}
        </CircleWithTooltip>
      )),
    },
    {
      headStyle: staticHeaderStyle,
      key: 'Action Name',
      name: 'Action Name',
      render: (c) => {
        return (
          <ul>
            {c.actionCodeNames.map((actionCodeName, index) => <li key={`actionCodeName-${actionCodeName}-${index + 1}`}>{actionCodeName}</li>)}
          </ul>
        );
      },
    },
    {
      headStyle: staticHeaderStyle,
      key: 'Action',
      name: 'Action',
      render: (c) => {
        return (
          <ul>
            {c.actionNames.map((action, index) => (
              <li key={`${action.id}-${action.name}-${index + 1}`}>
                <a
                  href={makeSfdcLinkAddress(action.id)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {action.name}
                </a>
              </li>
            ))}
          </ul>
        );
      },
    },
    {
      headStyle: staticHeaderStyle,
      key: 'Component',
      name: 'Component',
      render: (c) => {
        return (
          <ul>
            {c.componentCodeNames.map((component, index) => <li key={`component-${component}-${index + 1}`}>{component}</li>)}
          </ul>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      rows={sortServiceHistory(activeSortColumn, sortAscending, fieldServiceActivityQuery.fieldServiceActivityGroupedByCase)}
      getUniqueKey={(row, index) => `${row.actionId}-${index}`}
    />
  );
};

FieldServiceActivity.propTypes = {
  fieldServiceActivityQuery: PropTypes.shape({
    fieldServiceActivityGroupedByCase: PropTypes.arrayOf(
      PropTypes.shape({
        actionCodeName: PropTypes.string,
        actionDescription: PropTypes.string,
        actionId: PropTypes.string,
        actionName: PropTypes.string,
        caseNumber: PropTypes.string,
        closedDate: PropTypes.string,
        componentCode: PropTypes.string,
        componentCodeName: PropTypes.string,
        createdDate: PropTypes.string,
        dispatchFindings: PropTypes.string,
        dispatchName: PropTypes.string,
        dispatchTime: PropTypes.string,
        origin: PropTypes.string,
        reason: PropTypes.string,
        status: PropTypes.string,
        subsetReason: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default flowRight(withFieldServiceActivity)(FieldServiceActivity);
