import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import {
  Card,
  SectionHeader,
} from '../../general/components';
import ErrorBoundary from '../../general/components/ErrorBoundary';
import PeerMapView from './Maps/PeerMapView';
import { AlertTable } from './Tables';

const MapsColumns = styled.div`
  margin-right: 10px;
  width: 50%;
`;

export const SiteCard = styled(Card)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const AlertTableCard = styled(SiteCard)`
  height: 302px;
  margin-top: 44px;
`;

// The goal of this components is to have the map say which markers are
// visible and display how many peers are on the map and filter the
// alert table by the shown peers.
class PeerMapAlertWrapper extends PureComponent {
  render() {
    const { prospectId } = this.props;
    return (
      <>
        <MapsColumns>
          <SectionHeader>
            Peer View
          </SectionHeader>
          <SiteCard>
            <ErrorBoundary>
              <PeerMapView key={prospectId} prospectId={prospectId} updateShownPeers={this.updateShownPeers} />
            </ErrorBoundary>
          </SiteCard>
          <div className="invisible-text">sunrun</div>
        </MapsColumns>
        <MapsColumns>
          <AlertTableCard>
            <AlertTable key={prospectId} prospectId={prospectId} />
          </AlertTableCard>
        </MapsColumns>
      </>
    );
  }
}

PeerMapAlertWrapper.propTypes = {
  prospectId: PropTypes.string.isRequired,
};

export default PeerMapAlertWrapper;
