export default [
  {
    location: '/admin',
    text: 'Admin',
  },
  {
    location: '/adhoc',
    text: 'Ad Hoc Meter Readings',
  },
  {
    location: '/logout',
    text: 'Log Out',
  },
];

export const nonAdminInternalLinks = [
  {
    location: '/logout',
    text: 'Log Out',
  },
];
