import styled from '@emotion/styled/macro';
import { BLUE } from '../../general/constants/_colors';

const Button = styled.button`
  width: 100%;
  background: ${BLUE};
  text-transform: uppercase;
  border-radius: 18px;
  outline: none;  
  color: white;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  padding: 6px;
  cursor: pointer;
  opacity: 1;
  transition: .3s;

  &:hover {
    opacity: .8;
  }
`;

export default Button;
