import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';

import { BLUE, DARK_GREY, GREY } from '../constants/_colors';
import { DROPDOWN_WRAP_ZINDEX, DROPDOWN_ZINDEX } from '../constants/_zIndex';
import Icon from './Icon';


const Wrap = styled.div`
  border: 1px solid ${DARK_GREY};
  cursor: pointer;
  color: ${BLUE};
  display: flex;
  align-items: center;
  font-size: 16px;
  height: 40px;
  position: relative;
  z-index: ${props => (props.isOpen ? DROPDOWN_WRAP_ZINDEX : 'unset')};
`;

const Title = styled.div`
  align-items: center;
  display:flex;
  font-weight: 400;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
`;


const ItemsWrap = styled.ul`
  position: absolute;
  top: 100%;
  left: -1px;
  width: calc(100% + 2px);
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border: 1px solid ${DARK_GREY};
  background: white;
`;

const Option = styled.li`
  font-size: inherit;
  padding: 4px 20px;
  &:hover {
    background: ${GREY};
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${DROPDOWN_ZINDEX};
`;

const DropDown = ({
  className,
  iconName,
  name,
  onChange,
  options,
  placeholder,
  value,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleTitleClick = () => setIsOpen(!isOpen);
  const handleBackgroundClick = () => setIsOpen(false);
  const handleOptionClick = (option, index) => {
    setIsOpen(false);
    onChange(name, option, index);
  };
  const selectedOption = options.find(option => option.value === value);

  return (
    <>
      <Wrap isOpen={isOpen} className={className}>
        <Title onClick={handleTitleClick}>
          { selectedOption ? selectedOption.name : placeholder }

          <Icon name={iconName} />
        </Title>
        {
          isOpen && (
            <ItemsWrap>
              {
                options.map((option, index) => {
                  return (
                    <Option
                      key={option.value}
                      onClick={() => handleOptionClick(option, index)}
                    >
                      { option.name }
                    </Option>
                  );
                })
              }
            </ItemsWrap>
          )
        }
      </Wrap>
      {
        isOpen && (
          <Background onClick={handleBackgroundClick} />
        )
      }
    </>
  );
};

DropDown.defaultProps = {
  className: '',
  iconName: 'ArrowIcon',
  value: null,
};

DropDown.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
    ]).isRequired,
  })).isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default DropDown;
