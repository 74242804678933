import React, { PureComponent, Fragment } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { Grid, Col, Row } from 'react-flexbox-grid';
import mixpanel from 'mixpanel-browser';

import { CCC } from '../../general/constants/_colors';
import {
  Card, Icon, SectionHeader, CenteredLoader,
} from '../../general/components';
import AlertSettingsForm from './AlertSettingsForm';
import Button from './Button';
import PriorityItem from './PriorityItem';

export const SiteCard = styled(Card)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Faded = styled.span`
  color: ${CCC};
  margin-right: 10px;
`;

const PriorityCard = styled(Card)`
  padding: 33px;
  height: calc(100% - 32px);
  position: relative;
`;

const DoubleArrow = styled(Icon)`
  position: absolute;
  right: 0;
  bottom: 23px;
  width: 12px;
  height: 15px;
`;
const DraggablePriorityItem = styled(PriorityItem)`
  height: ${props => (props.isBeingDragged ? '0px' : 'unset')};
  padding: ${(props) => {
    if (props.isBeingDragged) {
      return '0px';
    }
    if (props.isDraggedOver) {
      return '70px 10px 20px 10px';
    }
    return '20px 10px';
  }};
  overflow: hidden;
  transition: ${props => (props.isDragging ? 'padding-top 0.2s' : '0s')};
`;

export const ALERT_TYPE_PRIORITY_FRAGMENT = gql`
  fragment AlertTypePrioirty_alertType on AlertTypeConnection {
    edges {
      node {
        id
        intRef
        name
        activeForCaseCreation
        closedCaseWaitPeriod
        caseCreateLatency
        caseReason
        caseSubsetReason
        caseSubject
        caseDescription
        sfdcCaseRecordTypeId
        sfdcCaseOwnerId
        sfdcCaseOrigin
        sfdcCaseStatus
        sfdcCasePriority
        currentSeverityThreshold
        severityLabel
        severityMultiplier
        alertColor
        createdDate
        lastUpdated
        casePriority
        relevantCases(first: 50) {
          edges {
            node {
              alertTypeId
              id
              reason
              reasonId
              caseSubsetReasons(first: 50) {
                edges {
                  node {
                    id
                    subsetReason
                    subsetReasonId
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const UPDATE_ALERT_PRIOIRTY = gql`
  mutation AlertTypePriority($intRefsSortedByPriority: [String!]!) {
    alertTypePriority(intRefsSortedByPriority: $intRefsSortedByPriority)
  }
`;

class AdminControlComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleOnDrop = this.handleOnDrop.bind(this);
    this.state = {
      draggedItem: null,
      draggedOver: null,
      priorityOrder: JSON.parse(JSON.stringify(props.data.alertType.edges)),
    };
  }

  handleOnClick = () => {
    mixpanel.track('Alert Priority Updated');
  }

  handleAlertPrioritySubmit(mutateAlertPriority) {
    const { priorityOrder } = this.state;
    const mutationParams = {
      variables: {
        intRefsSortedByPriority: priorityOrder.map(p => p.node.intRef).reverse(),
      },
    };
    mutateAlertPriority(mutationParams);
  }

  handleDragOver(event, item) {
    event.stopPropagation();
    event.preventDefault();
    const { draggedOver, draggedItem } = this.state;
    if (!draggedOver || (draggedOver.node.intRef !== item.node.intRef && draggedItem.node.intRef !== item.node.intRef)) {
      this.setState({ draggedOver: item });
    }
  }

  handleOnDrop() {
    const { draggedItem, priorityOrder, draggedOver } = this.state;
    const draggedItemIndex = priorityOrder.findIndex(item => item.node.intRef === draggedItem.node.intRef);
    let droppedItemIndex = priorityOrder.findIndex(item => item.node.intRef === draggedOver.node.intRef);
    if (droppedItemIndex > 0 && draggedItemIndex < droppedItemIndex) droppedItemIndex -= 1;
    if (draggedItemIndex !== -1 && droppedItemIndex !== -1) {
      priorityOrder.splice(droppedItemIndex, 0, priorityOrder.splice(draggedItemIndex, 1)[0]);
      this.setState({
        priorityOrder,
        draggedItem: null,
        draggedOver: null,
      });
    }
  }

  handleDragStart(event, item) {
    // setTimeout so that the drag item styles are not ruined
    setTimeout(() => {
      this.setState({ draggedItem: item });
    }, 1);
  }

  render() {
    const {
      priorityOrder, draggedItem, draggedOver,
    } = this.state;
    const { refetch, data: { alertType } } = this.props;
    return (
      <Grid fluid>
        <Row>
          <Col xs={4}>
            <SectionHeader>
              Alert Priority
            </SectionHeader>
            <PriorityCard>
              <Mutation mutation={UPDATE_ALERT_PRIOIRTY}>
                {(mutateAlertPriority, { loading }) => (
                  <Fragment>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        this.handleAlertPrioritySubmit(mutateAlertPriority);
                      }}
                    >
                      <div>
                        {
                          priorityOrder.map((item, index) => {
                            return (
                              <DraggablePriorityItem
                                id={`drag-prioirity-${item.id}`}
                                key={item.node.id}
                                isBeingDragged={draggedItem && draggedItem.node.intRef === item.node.intRef}
                                isDraggedOver={draggedOver && draggedOver.node.intRef === item.node.intRef}
                                draggable
                                isDragging={draggedItem}
                                onDragStart={e => this.handleDragStart(e, item)}
                                onDrop={this.handleOnDrop}
                                onDragOver={e => this.handleDragOver(e, item)}
                              >
                                <Faded>{`${index + 1}. `}</Faded>
                                {item.node.name}
                                <DoubleArrow name="DoubleArrowIcon" />
                              </DraggablePriorityItem>
                            );
                          })
                        }
                      </div>
                      <Button type="submit" onClick={this.handleOnClick}>
                        Update
                      </Button>
                    </form>
                    {
                      loading && (
                        <CenteredLoader />
                      )
                    }
                  </Fragment>
                )}
              </Mutation>
            </PriorityCard>
          </Col>
          <Col xs={8}>
            <SectionHeader>
              Alert Settings
            </SectionHeader>
            <PriorityCard>
              <AlertSettingsForm
                priorityItems={alertType.edges}
                handleRefetch={() => refetch()}
              />
            </PriorityCard>
          </Col>
        </Row>
      </Grid>
    );
  }
}

AdminControlComponent.propTypes = {
  data: PropTypes.shape({
    alertType: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape({
          id: PropTypes.string.isRequired,
          intRef: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          activeForCaseCreation: PropTypes.number.isRequired,
          closedCaseWaitPeriod: PropTypes.number.isRequired,
          caseCreateLatency: PropTypes.number.isRequired,
          caseReason: PropTypes.string,
          caseSubsetReason: PropTypes.string,
          caseSubject: PropTypes.string,
          caseDescription: PropTypes.string,
          sfdcCaseRecordTypeId: PropTypes.string.isRequired,
          sfdcCaseOwnerId: PropTypes.string.isRequired,
          sfdcCaseOrigin: PropTypes.string.isRequired,
          sfdcCaseStatus: PropTypes.string.isRequired,
          sfdcCasePriority: PropTypes.string.isRequired,
          currentSeverityThreshold: PropTypes.number.isRequired,
          severityLabel: PropTypes.string.isRequired,
          severityMultiplier: PropTypes.number.isRequired,
          alertColor: PropTypes.string.isRequired,
          createdDate: PropTypes.string.isRequired,
          lastUpdated: PropTypes.string.isRequired,
        }),
      })),
    }).isRequired,
  }).isRequired,
  refetch: PropTypes.func.isRequired,
};

export default AdminControlComponent;
