import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  LineSeries,
} from 'react-vis';
import styled from '@emotion/styled/macro';
import { DateTime } from 'luxon';
import ResizableXYPlot from './ResizableXYPlot';
import { invisibleLineStyle } from './styles';
import {
  HistoricalPerformanceCrossHair,
  StyledCrosshairDate,
} from './HistoricalCrosshair';
import { LineKey } from './Legend';

const formatHoverValue = (value) => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value)) {
    return '--';
  }
  return `${Number(value).toFixed(1)} kWh`;
};

const HoverValue = styled.div`
  display: flex;
  justify-content: space-between;
`;

class Interaction extends PureComponent {
  state = { coordinateCrosshairValues: null };

  render() {
    const { coordinateCrosshairValues } = this.state;
    const {
      axisStartDate,
      hoverValues,
      years,
    } = this.props;
    return (
      <ResizableXYPlot
        animation="noAnimation"
        height={300}
        margin={{ left: 70, right: 0 }}
        xType="time"
        onMouseLeave={() => this.setState({ coordinateCrosshairValues: null })}
      >
        <LineSeries
          data={[
            { x: axisStartDate.toMillis(), y: 1 },
            { x: axisStartDate.endOf('year').toMillis(), y: 1 },
          ]}
          style={invisibleLineStyle}
        />
        <LineSeries
          data={Object.values(hoverValues)}
          style={invisibleLineStyle}
          onNearestX={value => this.setState({ coordinateCrosshairValues: value })}
        />
        <HistoricalPerformanceCrossHair coordinate={coordinateCrosshairValues}>
          {
            coordinateCrosshairValues && (
              <>
                <StyledCrosshairDate>{DateTime.fromMillis(coordinateCrosshairValues.x).toFormat('L/d')}</StyledCrosshairDate>
                {
                Object
                  .entries(coordinateCrosshairValues)
                  .filter(([k]) => k !== 'x' && k !== 'y')
                  .map(([k, v]) => (
                    <HoverValue key={k}>
                      <span style={{ display: 'flex' }}>
                        <LineKey color={years.find(year => year.key === k).style.stroke} />
                        {`${k} `}
                      </span>
                      <span style={{ marginLeft: 20, whiteSpace: 'nowrap' }}>{formatHoverValue(v)}</span>
                    </HoverValue>
                  ))
                }
              </>
            )
          }
        </HistoricalPerformanceCrossHair>
      </ResizableXYPlot>
    );
  }
}

Interaction.propTypes = {
  axisStartDate: PropTypes.instanceOf(DateTime).isRequired,
  hoverValues: PropTypes.shape({}),
  years: PropTypes.arrayOf(PropTypes.shape({})),
};

Interaction.defaultProps = {
  hoverValues: {},
  years: [{}],
};

export default Interaction;
