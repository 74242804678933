import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { DateTime } from 'luxon';
import { flowRight, get } from 'lodash';
import Table from '../../../general/components/Table';
import { withAllAlerts } from '../../queries/withSiteQueries';
import { dateTimeFormat } from '../../../general/utils/dateFormatUtils';
import { BLACK, BLUE } from '../../../general/constants/_colors';

const Wrap = styled.div`
  max-width: 100%;
  overflow: auto;
`;

const getHeaderStyle = (activeSortColumn, name) => {
  return {
    color: activeSortColumn === name ? BLUE : BLACK,
    cursor: 'pointer',
  };
};

const staticHeaderStyle = {
  color: BLACK,
};

const getSeverityDisplay = (c) => {
  if (c.name === 'Under-performance' || c.name === 'Over-performance') {
    return `${Math.round((c.currentSeverity ? c.currentSeverity : get(c, 'initialSeverity', 0)) * 100)}%`;
  }
  return `${c.currentSeverity ? c.currentSeverity : get(c, 'initialSeverity', 'N/A')} Days`;
};

const sortAlerts = (column, isAscending, alerts) => {
  if (column === 'name') {
    if (isAscending) {
      return alerts.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
    }
    return alerts.sort((a, b) => {
      if (a.name > b.name) return -1;
      if (a.name < b.name) return 1;
      return 0;
    });
  }
  if (isAscending) {
    return alerts.sort((a, b) => {
      if (a[column].includes('0000-00-00')) return 1;
      if (b[column].includes('0000-00-00')) return -1;
      if (a[column].includes('0000-00-00') && b[column].includes('0000-00-00')) return 0;
      return DateTime.fromISO(a[column]).toMillis() - DateTime.fromISO(b[column]).toMillis();
    });
  }
  return alerts.sort((a, b) => {
    if (a[column].includes('0000-00-00')) return -1;
    if (b[column].includes('0000-00-00')) return 1;
    if (a[column].includes('0000-00-00') && b[column].includes('0000-00-00')) return 0;
    return DateTime.fromISO(b[column]).toMillis() - DateTime.fromISO(a[column]).toMillis();
  });
};

const AlertHistoryTable = ({ allAlertsQuery }) => {
  const [sortAscending, setSortAscending] = useState(false);
  const [activeSortColumn, setActiveSortColumn] = useState('createdDateUTC');
  if (allAlertsQuery.allAlerts.length === 0) return null;
  const columns = [
    {
      clickHandler: () => {
        if (activeSortColumn === 'name') return setSortAscending(!sortAscending);
        setSortAscending(true);
        return setActiveSortColumn('name');
      },
      headStyle: getHeaderStyle(activeSortColumn, 'name'),
      key: 'name',
      name: 'Alert Name',
      render: (c) => {
        return c.name;
      },
      sortIcon: {
        sortAscending: activeSortColumn === 'name' ? sortAscending : true,
      },
    },
    {
      headStyle: staticHeaderStyle,
      key: 'currentSeverity',
      name: 'Severity',
      render: (c) => {
        return getSeverityDisplay(c);
      },
    },
    {
      clickHandler: () => {
        if (activeSortColumn === 'createdDateUTC') return setSortAscending(!sortAscending);
        setSortAscending(false);
        return setActiveSortColumn('createdDateUTC');
      },
      headStyle: getHeaderStyle(activeSortColumn, 'createdDateUTC'),
      key: 'createdDateUTC',
      name: 'Open Date',
      render: c => (DateTime.fromISO(c.createdDateUTC).isValid ? dateTimeFormat(c.createdDateUTC) : 'fff'),
      sortIcon: {
        sortAscending: activeSortColumn === 'createdDateUTC' ? !sortAscending : true,
      },
    },
    {
      clickHandler: () => {
        if (activeSortColumn === 'closedDateUTC') return setSortAscending(!sortAscending);
        setSortAscending(false);
        return setActiveSortColumn('closedDateUTC');
      },
      headStyle: getHeaderStyle(activeSortColumn, 'closedDateUTC'),
      key: 'closedDateUTC',
      name: 'Closed Date',
      render: c => (DateTime.fromISO(c.closedDateUTC).isValid ? dateTimeFormat(c.closedDateUTC) : 'Not CLosed'),
      sortIcon: {
        sortAscending: activeSortColumn === 'closedDateUTC' ? !sortAscending : true,
      },
    },
  ];

  return (
    <Wrap>
      <Table
        columns={columns}
        rows={sortAlerts(activeSortColumn, sortAscending, allAlertsQuery.allAlerts)}
        getUniqueKey={row => row.alertId}
      />
    </Wrap>
  );
};

AlertHistoryTable.propTypes = {
  allAlertsQuery: PropTypes.shape({
    allAlerts: PropTypes.arrayOf(
      PropTypes.shape({
        alertId: PropTypes.string.isRequired,
        caseReason: PropTypes.string,
        closedDateUTC: PropTypes.string,
        createdDateUTC: PropTypes.string.isRequired,
        currentSeverity: PropTypes.number,
        initialSeverity: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default flowRight(withAllAlerts)(AlertHistoryTable);
