import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import './MonthlyGraph.scss';

const MonthlyGraph = ({
  months,
  size,
}) => {
  const [showData, setShowData] = useState(false);
  useEffect(() => {
    const animate = setTimeout(() => {
      setShowData(true);
    }, 500);
    return () => clearTimeout(animate);
  });
  const isLarge = size === 'large';
  const multiplier = isLarge ? 120 : 30;

  return (
    <div className={`sr-monthly-graph ${size}`}>
      {
        months.map((month) => {
          const percentStyle = {
            position: 'relative',
            bottom: month.percent < 11 ? 14 : 0,
          };
          return (
            <div className={`sr-monthly-graph__bar-wrap ${size}`} key={`monthly-graph-${month.name}`}>
              <div className={`sr-monthly-graph__bar ${size}`} style={{ height: showData ? (month.percent / 100) * multiplier : 0 }}>
                <div style={percentStyle}>{ isLarge && showData && month.percent }</div>
              </div>
              { isLarge && month.name }
            </div>
          );
        })
      }
    </div>
  );
};

MonthlyGraph.defaultProps = {
  size: 'small',
};

MonthlyGraph.propTypes = {
  months: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    percent: PropTypes.number.isRequired,
  })).isRequired,
  size: PropTypes.string,
};

export default MonthlyGraph;
