import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withAuth, SecureRoute } from '@okta/okta-react';
import { Switch, Route } from 'react-router-dom';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RestLink } from 'apollo-link-rest';
import mixpanel from 'mixpanel-browser';
import Rollbar from 'rollbar';
import styled from '@emotion/styled/macro';
import config from '../../config';
import HomeComponent from '../../home/components/HomeComponent';
import SitePage from '../../site/components/SitePage';
import AdminControlContainer from '../../admin/components/AdminControlContainer';
import AdHocContainer from '../../admin/components/AdHocMeter';
import LookerPocComponent from '../../lookerPoc/components/LookerPocComponent';
import PageNotFoundComponent from '../components/PageNotFoundComponent';
import { HeaderComponent, Main } from '../components';
import LoginHandler from '../components/LoginHandler';
import LogoutHandler from '../components/LogoutHandler';
import GridPage from '../../grid/components/GridPage';
import PrivateRoute from './PrivateRoute';
import withAuthorizationCheck from './withAuthorizationCheck';
import { NAVY, WHITE } from '../constants/_colors';

const Copyright = styled.div`
  background-color: ${NAVY};
  color: ${WHITE};
  font-size: 12px;
  margin-top: -1px;
  padding: 7px 0;
  text-align: center;
  width: 100%;
`;

const GridRoute = withAuthorizationCheck(
  PrivateRoute,
  [`GridServices-${config.oktaEnvironment}-Admin`],
);

const NocAdminRoute = withAuthorizationCheck(
  PrivateRoute,
  [
    `NOC-${config.oktaEnvironment}-Admin`,
    'NOC-Admin',
  ],
);

const mixpanelUserInit = ({
  department,
  email,
  groupId,
  name,
}) => {
  mixpanel.identify(email);
  mixpanel.register({
    department,
    groupId,
    name,
  });
};

const Routes = ({ auth }) => {
  const [rollbar, setRollbar] = useState(null);
  const errorLink = onError(({
    graphQLErrors, networkError, operation,
  }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        const errorMessage = `[GraphQL error]: Message: ${message}\n Operation: ${operation.operationName} \n Variables: ${JSON.stringify(operation.variables)}`;
        rollbar.error(errorMessage);
      });
    }
    if (networkError) {
      rollbar.error(networkError);
    }
  });
  const authMiddleware = setContext(() => {
    return auth.getAccessToken().then(token => ({ headers: { Authorization: `Bearer ${token}` } }));
  });
  const httpLink = new HttpLink({
    uri: config.dataPlatform.graphQLUri,
  });
  const restLink = new RestLink({ uri: config.dataPlatform.versionUri });
  const link = ApolloLink.from([
    authMiddleware,
    errorLink,
    restLink,
    httpLink,
  ]);
  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    const getUser = async () => {
      const user = await auth.getUser();
      if (user) {
        const rollbarSetup = new Rollbar(config.rollbar);
        const {
          department,
          email,
          group_id: groupId,
          name,
        } = user;
        mixpanelUserInit({
          department,
          email,
          groupId,
          name,
        });
        mixpanel.track('User Logged In');
        rollbarSetup.configure({ payload: { person: { id: email } } });
        setRollbar(rollbarSetup);
      }
    };
    getUser();
  }, [auth]);

  if (!rollbar) return null;
  return (
    <ApolloProvider client={client}>
      <HeaderComponent />
      <Main>
        <Switch>
          <SecureRoute path="/" exact component={HomeComponent} />
          <NocAdminRoute path="/adhoc" component={AdHocContainer} />
          <NocAdminRoute path="/admin" component={AdminControlContainer} />
          <GridRoute path="/grid" component={GridPage} />
          <SecureRoute path="/looker-poc" component={LookerPocComponent} />
          <SecureRoute path="/site/:prospectId" component={SitePage} />
          <Route path="/login" component={LoginHandler} />
          <Route path="/logout" component={LogoutHandler} />
          <Route render={() => <PageNotFoundComponent />} />
        </Switch>
      </Main>
      <Copyright>
        Copyright Sunrun NOC | 2019
      </Copyright>
    </ApolloProvider>
  );
};

Routes.propTypes = {
  auth: PropTypes.shape({
    getAccessToken: PropTypes.func,
    getUser: PropTypes.func,
  }).isRequired,
};

export default withAuth(Routes);
