import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '@okta/okta-react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Card, SectionHeader, PageLoader } from '../../general/components';
import Welcome from './Welcome';
import AnomalyDetectionService from '../../anomalyDetectionService';

export const HomeComponentPreAuth = ({ auth, className }) => {
  const [user, setUser] = useState(null);
  useEffect(() => {
    const getUser = async () => {
      const userData = await auth.getUser();
      setUser(userData);
    };
    getUser();
  }, [auth]);

  if (!user) return (<PageLoader />);
  if (user.group_id.includes('NOC-Anomaly-Detection')) {
    return (
      <AnomalyDetectionService />
    );
  }
  return (
    <Grid className={className}>
      <Row>
        <Col lg={12}>
          <SectionHeader>Welcome</SectionHeader>
          <Card>
            <Welcome user={user} />
          </Card>

        </Col>
      </Row>

    </Grid>
  );
};

HomeComponentPreAuth.propTypes = {
  auth: PropTypes.shape({
    getUser: PropTypes.func,
  }).isRequired,
  className: PropTypes.string,
};

HomeComponentPreAuth.defaultProps = {
  className: '',
};

export default withAuth(HomeComponentPreAuth);
