export const fetchJSONFile = (url) => {
  return fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.status);
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
}