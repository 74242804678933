import React, { Fragment } from 'react';
import { Card, SectionHeader } from '../../general/components';

const LookerPocComponent = () => (
  <Fragment>
    <h1>Looker Integration POC</h1>
    <SectionHeader>Sample 1</SectionHeader>
    <Card>
      <iframe
        src="https://orion.sunrun.com:9999/embed/looks/696?allow_login_screen=true&toggle=det,fil,pik"
        width="600"
        height="300"
        frameBorder="0"
        title="test 1"
      />
    </Card>
    <SectionHeader>Sample 2</SectionHeader>
    <Card>
      <iframe
        src="https://rigel.sunrun.com:9999/embed/looks/840?allow_login_screen=true"
        width="600"
        height="300"
        frameBorder="0"
        title="test 2"
      />
    </Card>
  </Fragment>
);

export default LookerPocComponent;
