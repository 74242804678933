import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { styled } from '@material-ui/core/styles';

const Container = styled(Paper)({
  display: 'flex',
  justifyContent: 'space-evenly',
  margin: 20,
  padding: 20,
});

const InnerContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  width: '85%',
});

const OptionsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-evenly',
});

const FormControlContainer = styled(FormControl)({
  width: 125,
  marginBottom: 16,
});

const SizedButton = styled(Button)({
  height: 48,
  alignSelf: 'center',
});

const AdsRulesControls = ({
  activeEndpoints,
  dayCount,
  endDate,
  handleAdsRulesSubmit,
  isActionLoading,
  startDate,
  threshold,
}) => {
  const [pickerStartDate, setPickerStartDate] = useState(startDate);
  const [pickerEndDate, setPickerEndDate] = useState(endDate);
  const [pickerThreshold, setPickerThreshold] = useState(threshold);
  const [pickerDayCount, setPickerDayCount] = useState(dayCount);
  const [checkboxState, setCheckboxState] = useState({ ...activeEndpoints });

  const handleCheckboxChange = field => (e) => {
    setCheckboxState({
      ...checkboxState,
      [field]: e.target.checked,
    });
  };

  return (
    <Container elevation={3}>
      <InnerContainer>
        <OptionsContainer>
          <DatePicker
            label="Start Date"
            value={pickerStartDate}
            onChange={setPickerStartDate}
            autoOk
            format="MM/dd/yyyy"
          />
          <DatePicker
            label="End Date"
            value={pickerEndDate}
            onChange={setPickerEndDate}
            autoOk
            format="MM/dd/yyyy"
          />
          <FormControlContainer>
            <InputLabel>Number Of Days</InputLabel>
            <Select
              value={pickerDayCount}
              onChange={e => setPickerDayCount(e.target.value)}
            >
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6">6</MenuItem>
              <MenuItem value="7">7</MenuItem>
              <MenuItem value="8">8</MenuItem>
              <MenuItem value="9">9</MenuItem>
              <MenuItem value="10">10</MenuItem>
              <MenuItem value="11">11</MenuItem>
              <MenuItem value="12">12</MenuItem>
              <MenuItem value="13">13</MenuItem>
              <MenuItem value="14">14</MenuItem>
              <MenuItem value="15">15</MenuItem>
            </Select>
          </FormControlContainer>
          <FormControl>
            <InputLabel>Threshold(kWh)</InputLabel>
            <Select
              value={pickerThreshold}
              onChange={e => setPickerThreshold(e.target.value)}
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6">6</MenuItem>
              <MenuItem value="7">7</MenuItem>
              <MenuItem value="8">8</MenuItem>
              <MenuItem value="9">9</MenuItem>
              <MenuItem value="10">10</MenuItem>
            </Select>
          </FormControl>
        </OptionsContainer>
        <OptionsContainer>
          <FormControlLabel
            control={(
              <Checkbox
                checked={checkboxState.sysFailure}
                onChange={handleCheckboxChange('sysFailure')}
              />
            )}
            label="System Failure"
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={checkboxState.nonComm}
                onChange={handleCheckboxChange('nonComm')}
              />
            )}
            label="Non Comm"
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={checkboxState.underPerf}
                onChange={handleCheckboxChange('underPerf')}
              />
            )}
            label="Under Performance"
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={checkboxState.errorSites}
                onChange={handleCheckboxChange('errorSites')}
              />
            )}
            label="Sites With Errors"
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={checkboxState.nullNan}
                onChange={handleCheckboxChange('nullNan')}
              />
            )}
            label="Consecutive Null/NaN Values"
          />
        </OptionsContainer>
      </InnerContainer>
      <SizedButton
        disabled={isActionLoading}
        onClick={() => handleAdsRulesSubmit({
          activeEndpoints: checkboxState,
          dayCount: pickerDayCount,
          endDate: pickerEndDate,
          startDate: pickerStartDate,
          threshold: pickerThreshold,
        })}
        variant="contained"
      >
        Get ADS Rules
      </SizedButton>
    </Container>
  );
};

AdsRulesControls.propTypes = {
  activeEndpoints: PropTypes.shape({
    errorSites: PropTypes.bool,
    nonComm: PropTypes.bool,
    nullNan: PropTypes.bool,
    sysFailure: PropTypes.bool,
    underPerf: PropTypes.bool,
  }).isRequired,
  dayCount: PropTypes.string.isRequired,
  endDate: PropTypes.instanceOf(DateTime).isRequired,
  handleAdsRulesSubmit: PropTypes.func.isRequired,
  isActionLoading: PropTypes.bool.isRequired,
  startDate: PropTypes.instanceOf(DateTime).isRequired,
  threshold: PropTypes.string.isRequired,
};

export default AdsRulesControls;
