import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';
import { withSiteDetails } from '../../queries/withSiteQueries';
import Map from './Map';
import { PlusIcon, MinusIcon } from './ControlIcons';

class AerialMapView extends PureComponent {
  constructor() {
    super();
    this.handleSetZoom = this.handleSetZoom.bind(this);
    this.onZoomChange = this.onZoomChange.bind(this);
    this.mapRef = null;
    this.state = {
      zoom: 20,
    };
  }

  onZoomChange() {
    const { zoom } = this.state;
    if (this.mapRef) {
      this.handleSetZoom(this.mapRef.getZoom() - zoom);
    }
  }

  handleSetZoom(offset) {
    const { zoom } = this.state;
    if (zoom + offset > 0 && zoom + offset <= 20) {
      this.setState({
        zoom: zoom + offset,
      });
    }
  }

  render() {
    const { siteDetailsQuery: { site } } = this.props;
    const { zoom } = this.state;
    return (
      <Map
        mapProps={{
          zoom,
          options: {
            gestureHandling: 'greedy',
            rotateControl: true,
            rotateControlOptions: {
              // window.google.maps.ControlPosition.RIGHT_TOP = 7
              position: 7,
            },
            panControl: true,
          },
          ref: (ref) => {
            this.mapRef = ref;
          },
          center: {
            lat: Number(site.address.lat),
            lng: Number(site.address.lng) || Number(site.address.lon),
          },
          onZoomChanged: this.onZoomChange,
          mapTypeId: 'satellite',
          tilt: 45,
        }}
      >
        <MarkerWithLabel
          position={{
            lat: Number(site.address.lat),
            lng: Number(site.address.lng) || Number(site.address.lon),
          }}
          labelAnchor={{ x: -25, y: 32 }}
          icon="/images/markers/blue_transparent.svg"
          labelStyle={{
            fontSize: '18px',
            color: 'white',
            textShadow: '-2px 0 black, 0 2px black, 2px 0 black, 0 -2px black',
          }}
        >
          <div>{site.address.street}</div>
        </MarkerWithLabel>
        <PlusIcon name="BluePlusIcon" onClick={() => this.handleSetZoom(1)} />
        <MinusIcon name="BlueMinusIcon" onClick={() => this.handleSetZoom(-1)} />
      </Map>
    );
  }
}

AerialMapView.propTypes = {
  siteDetailsQuery: PropTypes.shape({
    site: PropTypes.shape({
      address: PropTypes.shape({
        lat: PropTypes.string,
        lng: PropTypes.string,
        lon: PropTypes.string,
        street: PropTypes.string,
      }),
      lat: PropTypes.string,
      lng: PropTypes.string,
    }),
  }).isRequired,
};

export default flowRight(withSiteDetails)(AerialMapView);
