export function convertNumberToHex(val) {
  let col = val.toString(16);
  const index = col.indexOf('.');
  if (index !== -1) {
    col = col.substring(0, index);
  }
  if (col.length === 1) {
    col = 0 + col;
  }
  return col;
}

export function convertRGBToHex(rgb) {
  let final = '#';
  final += convertNumberToHex(rgb[0]);
  final += convertNumberToHex(rgb[1]);
  final += convertNumberToHex(rgb[2]);
  return final;
}

export function findMiddle(rgb1, rgb2, count) {
  const r = rgb2[0] - rgb1[0];
  const g = rgb2[1] - rgb1[1];
  const b = rgb2[2] - rgb1[2];

  const rinterval = r / count;
  const ginterval = g / count;
  const binterval = b / count;

  let startr = rgb1[0];
  let startg = rgb1[1];
  let startb = rgb1[2];

  count -= 1;

  const hexCodes = [];
  hexCodes.push(convertRGBToHex(rgb1));
  for (let i = 0; i < count; i += 1) {
    startr += rinterval;
    startg += ginterval;
    startb += binterval;
    hexCodes.push(convertRGBToHex([startr, startg, startb]));
  }
  return hexCodes;
}

const vib = [148, 0, 211];
const indigo = [75, 0, 130];
const blue = [0, 0, 255];
const green = [0, 255, 0];
const yellow = [255, 255, 0];
const orange = [255, 127, 0];
const red = [255, 0, 0];

export const rainbowColors = [
  vib,
  indigo,
  blue,
  green,
  yellow,
  orange,
  red,
];

export default function extrapolateColors(countOfColorsToReturn, rgbColors) {
  let result = [];

  if (countOfColorsToReturn <= rgbColors.length) {
    for (let i = 0; i < countOfColorsToReturn; i += 1) {
      result.push(convertRGBToHex(rgbColors[i]));
    }
  } else {
    let numOfColors = rgbColors.length;
    const numberOfColorsToFill = countOfColorsToReturn - numOfColors;
    numOfColors -= 1;

    let iterations = numberOfColorsToFill / numOfColors;
    iterations = parseInt(iterations, 10);
    // By default we need atleast 1 iteration
    iterations += 1;

    const extras = numberOfColorsToFill % numOfColors;
    if (extras > 0) {
      iterations += 1;
    }
    let ties = 0;
    for (let k = 0; k < numOfColors; k += 1) {
      result = result.concat(findMiddle(rgbColors[k], rgbColors[k + 1], iterations));
      if (extras > 0) {
        if (ties === (extras - 1)) {
          iterations -= 1;
        } else {
          ties += 1;
        }
      }
    }
    result.push(convertRGBToHex(rgbColors[numOfColors]));
  }
  return result;
}
