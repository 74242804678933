import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ClassNames from 'classnames';
import mixpanel from 'mixpanel-browser';

import './InternalLinkComponent.scss';

const handleOnClick = path => () => mixpanel.track('Internal Link Clicked', { path });

const InternalLinkComponent = ({ path, children, isActive }) => (
  <Link className={ClassNames('internal-link-component', { active: isActive })} to={path} onClick={handleOnClick(path)}>
    {children}
  </Link>
);

InternalLinkComponent.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  isActive: PropTypes.bool,
};

InternalLinkComponent.defaultProps = {
  isActive: false,
};

export default InternalLinkComponent;
