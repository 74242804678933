import React from 'react';
import { render } from 'react-dom';

import AppContainer from './general/containers/AppContainer';
import 'normalize.css';
import './index.scss';

render(
  <AppContainer />,
  document.getElementById('root'),
);
