import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { Col, Row } from 'react-flexbox-grid';
import mixpanel from 'mixpanel-browser';

import { BLUE, FADED_GREY } from '../../general/constants/_colors';
import {
  ButtonLink,
  Card,
  SectionHeader,
} from '../../general/components';
import {
  MeteringDetailsTable,
  SystemDetailsTable,
} from './Tables';

const RowContainer = styled(Row)`
  border-bottom: 1px solid #B2ADA6;
`;

const RowContainerWithPadding = styled(RowContainer)`
  padding-top: 20px;
`;

const ToggleDivider = styled.span`
  margin-left: 10px;
  margin-right: 10px;
`;

const SiteCard = styled(Card)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const SiteInfoContainer = styled(SiteCard)`
  border: none;
`;

const SYSTEM_DETAILS_TABLE = 'system_details_table';
const METERING_TABLE = 'metering_table';

function SystemDetails({ prospectId }) {
  const [activeTable, setActiveTable] = useState(SYSTEM_DETAILS_TABLE);
  return (
    <RowContainerWithPadding>
      <Col xs={6}>
        <SectionHeader>
          { activeTable === SYSTEM_DETAILS_TABLE ? 'System Details' : 'Metering' }
        </SectionHeader>
      </Col>
      <Col xs={6}>
        <Row end="xs" style={{ color: FADED_GREY }}>
          <ButtonLink
            color={activeTable === SYSTEM_DETAILS_TABLE ? BLUE : FADED_GREY}
            onClick={() => {
              mixpanel.track('Active Table Changed', { table: SYSTEM_DETAILS_TABLE });
              setActiveTable(SYSTEM_DETAILS_TABLE);
            }}
          >
          Arrays
          </ButtonLink>
          <ToggleDivider>|</ToggleDivider>
          <ButtonLink
            color={activeTable === METERING_TABLE ? BLUE : 'inherit'}
            onClick={() => {
              mixpanel.track('Active Table Changed', { table: METERING_TABLE });
              setActiveTable(METERING_TABLE);
            }}
          >
            Metering
          </ButtonLink>
        </Row>
      </Col>
      <Col xs={12}>
        <SiteInfoContainer>
          {activeTable === SYSTEM_DETAILS_TABLE && (<SystemDetailsTable prospectId={prospectId} />)}
          {activeTable === METERING_TABLE && <MeteringDetailsTable prospectId={prospectId} />}
        </SiteInfoContainer>
      </Col>
    </RowContainerWithPadding>
  );
}

SystemDetails.propTypes = {
  prospectId: PropTypes.string.isRequired,
};

export default SystemDetails;
