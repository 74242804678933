import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import mixpanel from 'mixpanel-browser';

import {
  ButtonLink,
  Icon,
  Modal,
  ModalContent,
  ModalHeader,
  SectionHeader,
} from '../../general/components';
import { BLUE, FADED_GREY } from '../../general/constants/_colors';
import {
  FieldServiceActivityTable,
  AlertHistoryTable,
} from './Tables';

const ToggleDivider = styled.span`
  margin-left: 10px;
  margin-right: 10px;
`;

export const HistoryLink = styled(ButtonLink)`
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: center;
  line-height: 20px;
  text-transform: uppercase;
`;

const HistoryIcon = styled(Icon)`
  height: 20px;
  margin-left: 3px;
  width: 20px;
`;

const FIELD_SERVICE_HISTORY_TABLE = 'FIELD_SERVICE_HISTORY_TABLE';
const ALERT_HISTORY_TABLE = 'ALERT_HISTORY_TABLE';

function History({ prospectId }) {
  const [activeTable, setActiveTable] = useState(FIELD_SERVICE_HISTORY_TABLE);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <HistoryLink onClick={() => {
        setIsOpen(true);
        mixpanel.track('History Modal Opened');
      }}
      >
        {'History '}
        <HistoryIcon name="HistoryIcon" />
      </HistoryLink>
      <Modal isOpen={isOpen} closeModal={() => setIsOpen(false)}>
        <ModalContent>
          <ModalHeader middle="xs" between="xs">
            <SectionHeader>
              {activeTable === FIELD_SERVICE_HISTORY_TABLE ? 'Field Service Activity' : 'Alert History' }
            </SectionHeader>
            <div style={{ paddingLeft: '10px' }}>
              <ButtonLink
                color={activeTable === FIELD_SERVICE_HISTORY_TABLE ? BLUE : FADED_GREY}
                onClick={() => setActiveTable(FIELD_SERVICE_HISTORY_TABLE)}
              >
            Field Service History
              </ButtonLink>
              <ToggleDivider>|</ToggleDivider>
              <ButtonLink
                color={activeTable === ALERT_HISTORY_TABLE ? BLUE : FADED_GREY}
                onClick={() => setActiveTable(ALERT_HISTORY_TABLE)}
              >
            Alert History
              </ButtonLink>
            </div>
          </ModalHeader>
          {activeTable === FIELD_SERVICE_HISTORY_TABLE && (
          <FieldServiceActivityTable prospectId={prospectId} />
          )}
          {activeTable === ALERT_HISTORY_TABLE && (
          <AlertHistoryTable prospectId={prospectId} />
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

History.propTypes = {
  prospectId: PropTypes.string.isRequired,
};

export default History;
