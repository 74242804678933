/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '@okta/okta-react';
import { Redirect } from 'react-router-dom';

const LoginHandler = ({ auth }) => {
  const [authenticated, setAuthenticated] = useState(null);
  useEffect(() => {
    const getAuthentication = async () => {
      const authentication = await auth.isAuthenticated();
      if (authentication !== authenticated) {
        setAuthenticated(authentication);
      }
      if (!authentication) {
        auth.login('/');
      }
    };
    getAuthentication();
  }, [auth, authenticated]);

  if (authenticated === null) return null;

  if (authenticated) {
    return <Redirect to="/" />;
  }

  return (<div>Redirecting...</div>);
};

LoginHandler.propTypes = {
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
  }).isRequired,
};

export default withAuth(LoginHandler);
