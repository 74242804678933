import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { Row, Col, Grid } from 'react-flexbox-grid';
import mixpanel from 'mixpanel-browser';
import {
  BLUE,
  DOLPHIN_GREY,
  NAVY,
  WHITE,
} from '../../../general/constants/_colors';
import { dateFormat } from '../../../general/utils/dateFormatUtils';
import { SALESFORCE_ROOT } from '../../../general/constants/urls';
import {
  withBatteryManufacturer,
  withMeterInfo,
  withSystemCharacteristics,
  withSiteDetails,
} from '../../queries/withSiteQueries';
import {
  batteryType,
  meterType,
  siteSystemCharacteristicsType,
  siteDetailsType,
} from '../types';

const Category = styled(Col)`
  border-right: 1px solid ${DOLPHIN_GREY};
  &:last-of-type{
    border-right: none;
  }
  padding: 0 25px;
  &:first-of-type {
    padding-left: 0;
  }
  min-width: 315px;
`;

const Item = styled.div`
  ${props => (props.highlighted ? `color: ${BLUE};` : '')}
  font-family: sans-serif;
  font-size: 16px;
  line-height: 20px;
`;

const BatterySystemHeader = styled.div`
  background-color: ${NAVY};
  border-radius: 4px;
  color: ${WHITE};
  padding: 2px 5px;
  width: fit-content;
`;

const noValue = () => '--';
const valueOrEmpty = text => text || noValue();
const locationFormat = ({ city, state, zip }) => `${city}, ${state} ${zip}`;

const unique = array => [...new Set(array)];
const removeFalsyItems = array => array.filter(Boolean);

const createColumns = (site, battery, systemsCharacteristics, meterInfo) => {
  const meterTypesList = meterInfo
    .filter(e => e.status.toLowerCase() === 'active')
    .map(e => e.vendorName);

  const meterTypes = unique(removeFalsyItems(meterTypesList)).join(', ');

  const inverterManufacturers = unique(systemsCharacteristics
    .map(e => e.inverterMfg))
    .join(', ');

  const batteryManufacturers = unique(battery
    .map(e => e.manufacturer))
    .join(', ');

  const batterySerialNumbers = unique(battery
    .map(e => e.serialNumber));

  const panelMfgs = systemsCharacteristics
    .map(e => e.panelMfg);

  const panelManufacturers = unique(removeFalsyItems(panelMfgs)).join(',');
  const makeBatterySnElements = () => batterySerialNumbers.map((sn, i) => {
    return { label: `Battery ${i + 1} SN`, value: sn };
  });

  const columns = [
    {
      category: 'Basic Information',
      displayByDefault: true,
      elements: [
        <ServiceContractName key="serviceContractName" scn={site.serviceContractName} contractId={site.contractId} />,
        <SystemHeader
          batteryManufacturers={batteryManufacturers}
          isBrightBox={site.brightBox === 'true'}
          key="systemHeader"
          panelManufacturers={panelManufacturers}
        />,
        <KeyValuePair key="Name" label="Name" value={site.customerName} />,
        <KeyValuePair key="Address" label="Address" value={site.address.street} />,
        <KeyValuePair key="Location" label="Location" value={locationFormat(site.address)} />,
        <KeyValuePair key="System Size (kW DC)" label="System Size (kW DC)" value={site.systemSizeDC} />,
      ].concat([
        { label: 'PTO Date', value: dateFormat(site.ptoDate) },
        { label: 'Status', value: site.status },
        { label: 'Install Partner', value: site.installPartnerName },
        { label: 'Sales Partner', value: site.salesPartnerName },
        { label: 'Utility', value: site.utilityCompany },
      ].map(
        ({ label, value }) => <KeyValuePair key={label} label={label} value={value} />,
      )),
    },
    {
      category: 'Manufacturers',
      displayByDefault: true,
      elements: [
        { label: 'Meter Mfg', value: meterTypes },
        { label: 'Inverter Mfg', value: inverterManufacturers },
        { label: 'Panel Mfg', value: panelManufacturers },
        { label: 'Battery Mfg', value: batteryManufacturers },
      ]
        .concat(makeBatterySnElements())
        .map(({ label, value }) => <KeyValuePair key={label} label={label} value={value} />),
    },
    {
      category: 'Stuff',
      displayByDefault: false,
      elements: [
        { label: 'Latitude', value: site.address.lat },
        { label: 'Longitude', value: site.address.lon },
        { label: 'FYGE (kWh)', value: site.fyge },
        { label: 'Billing Type', value: site.billingType },
        { label: 'Fund', value: site.fundName },
      ]
        .map(({ label, value }) => <KeyValuePair key={label} label={label} value={value} />),
    },
    {
      category: 'Key Dates',
      displayByDefault: false,
      elements: [
        { label: 'Welcome Date', value: dateFormat(site.welcomeDate) },
        { label: 'M1 Date', value: dateFormat(site.m1Date) },
        { label: 'M2 Date', value: dateFormat(site.m2Date) },
        { label: 'PTO Rec Date', value: dateFormat(site.ptoRecordedDate) },
      ]
        .map(({ label, value }) => <KeyValuePair key={label} label={label} value={value} />),
    },
  ];

  return columns;
};

const buildSalesforceUrl = contractId => (contractId ? `${SALESFORCE_ROOT}/${contractId}` : '');

const ItemLink = Item.withComponent('a');

const handleOnClick = url => () => mixpanel.track('SCN Link Clicked', { url });

const ServiceContractName = ({ scn, contractId }) => {
  if (scn && contractId) {
    return (
      <ItemLink
        style={{ color: BLUE, textDecoration: 'none' }}
        href={buildSalesforceUrl(contractId)}
        target="_blank"
        onClick={handleOnClick(buildSalesforceUrl(contractId))}
      >
        {`SCN ${scn}`}
      </ItemLink>
    );
  }
  return null;
};

ServiceContractName.propTypes = {
  contractId: PropTypes.string,
  scn: PropTypes.string,
};

ServiceContractName.defaultProps = {
  scn: undefined,
  contractId: undefined,
};

const SystemHeader = ({ isBrightBox, batteryManufacturers, panelManufacturers }) => {
  if (isBrightBox) {
    return (
      <BatterySystemHeader>Battery</BatterySystemHeader>
    );
  }
  if (panelManufacturers && !isBrightBox) {
    return (
      <div>Solar Only</div>
    );
  }
  if (!panelManufacturers && batteryManufacturers) {
    return (
      <BatterySystemHeader> Battery Only</BatterySystemHeader>
    );
  }
  return null;
};

SystemHeader.propTypes = {
  batteryManufacturers: PropTypes.string.isRequired,
  isBrightBox: PropTypes.bool.isRequired,
  panelManufacturers: PropTypes.string.isRequired,
};

const KeyValuePair = ({ label, value }) => (
  <Item title={value}>{`${label} - ${valueOrEmpty(value)}`}</Item>
);

KeyValuePair.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

KeyValuePair.defaultProps = {
  value: undefined,
};

const AssetInformationComponent = ({
  batteryQuery,
  isExpanded = false,
  meterInfoQuery,
  siteDetailsQuery,
  systemsCharacteristicsQuery,
}) => {
  if (!siteDetailsQuery.site) return null;
  const columns = createColumns(
    siteDetailsQuery.site,
    batteryQuery.battery,
    systemsCharacteristicsQuery.systemsCharacteristics,
    meterInfoQuery.meterInfo,
  );
  return (
    <Grid fluid>
      <Row>
        {
          columns
            .filter(g => (isExpanded ? true : g.displayByDefault))
            .map(g => (
              <Category key={g.category} xs={6} sm={isExpanded ? 4 : 6} md={isExpanded ? 3 : 6} lg={isExpanded ? 3 : 6}>
                {g.elements}
              </Category>
            ))
        }
      </Row>
    </Grid>
  );
};

AssetInformationComponent.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  meterInfoQuery: PropTypes.shape({
    meterInfo: PropTypes.arrayOf(meterType),
  }).isRequired,
  batteryQuery: PropTypes.shape({
    battery: PropTypes.arrayOf(batteryType),
  }),
  systemsCharacteristicsQuery: PropTypes.shape({
    systemsCharacteristics: PropTypes.arrayOf(siteSystemCharacteristicsType),
  }).isRequired,
  siteDetailsQuery: PropTypes.shape({
    site: siteDetailsType,
  }).isRequired,
};

AssetInformationComponent.defaultProps = {
  batteryQuery: {},
};


export default flowRight(
  withMeterInfo,
  withBatteryManufacturer,
  withSystemCharacteristics,
  withSiteDetails,
)(AssetInformationComponent);
