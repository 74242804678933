import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import mixpanel from 'mixpanel-browser';

import './ExternalLinkComponent.scss';

const handleOnClick = url => () => mixpanel.track('External Link Clicked', { url });

const ExternalLinkComponent = ({ url, children, isActive }) => (
  <a
    className={ClassNames('external-link-component', { active: isActive })}
    onClick={handleOnClick(url)}
    href={url}
    rel="external noopener noreferrer"
    target="_blank"
  >
    {children}
  </a>
);

ExternalLinkComponent.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  isActive: PropTypes.bool,
};

ExternalLinkComponent.defaultProps = {
  isActive: false,
};

export default ExternalLinkComponent;
