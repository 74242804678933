import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import styled from '@emotion/styled/macro';
import mixpanel from 'mixpanel-browser';
import { Formik, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Col, Row } from 'react-flexbox-grid';
import Button from './Button';
import { ToggleButton, DropDown } from '../../general/components';
import {
  CCC, CHARCOAL, BLUE,
} from '../../general/constants/_colors';
import CenteredLoader from '../../general/components/CenteredLoader';

const Title = styled.h3`
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 16px;
`;

const LightTitle = styled(Title)`
  color: ${CCC};
`;

const CaseDropDown = styled(DropDown)`
  width: 100px;
  margin-left: 5px;
`;

const SettingsDropDown = styled(CaseDropDown)`
  float: right;
  top: -4px;
  width: 100px;
`;

const SubCaseRow = styled(Row)`
  width: 100%;
  justify-content: space-between;
`;

const CaseRow = styled(SubCaseRow)`
  margin-bottom: 15px;
`;

const RightCol = styled(Col)`
  padding-left: 45px;
`;

const TextArea = styled.textarea`
  height: 395px;
  width: 100%;
  resize: none;
  border: 1px solid ${CHARCOAL};
  border-radius: 3px;
  padding: 15px;
  outline: none;
  margin-bottom:  22px;
`;

const UpdateButton = styled(Button)`
  margin-top: 22px;
`;

const InvertedDropDown = styled(DropDown)`
  background: white;
  border: 1px solid ${BLUE};
  color: ${BLUE};
  font-size: 18px;
  border-radius: 18px;
  text-align: center;
  padding: 5px;
`;

const SettingsInput = styled.input`
  border: 1px solid ${CHARCOAL};
  color: ${BLUE};
  height:40px;
  position: relative;
  padding: 6px;
  padding-right: 0px;
  width: 200px;
`;

const SettingItem = styled.div`
  align-items: center;
  display: flex;
  font-size: 16px;
  height: 60px;
  justify-content: space-between;
`;

const RelevantCaseInput = styled(SettingsInput)`
  top: 0px;
  width: calc(100% - 30px);
`;

const SmallSettingsInput = styled(SettingsInput)`
  width: 50px;
`;

const CaseToggleButton = styled(ToggleButton)`
  position: relative;
  top: 2px;
`;

const StyledError = styled.div`
  color: red;
  font-size: small;
  text-align: end;
  width: 100%;
`;

const dayOptions = [];

for (let i = 0; i < 31; i += 1) {
  dayOptions.push({
    name: i.toString(),
    value: i.toString(),
  });
}

const priorityOptions = [
  {
    name: 'Urgent',
    value: 'Urgent',
  },
  {
    name: 'Escalated',
    value: 'Escalated',
  },
  {
    name: 'High',
    value: 'High',
  },
  {
    name: 'Normal',
    value: 'Normal',
  },
];

const statusOptions = [
  {
    name: 'Open',
    value: 'Open',
  },
  {
    name: 'Closed',
    value: 'Closed',
  },
];
const UPDATE_ALERT_SETTINGS = gql`
  mutation AlertType($id: ID!, $alertType: AlertTypeMutationInput!) {
    alertType(id: $id, alertType: $alertType) {
      id
    }
  }
`;

const CREATE_RELEVANT_CASE = gql`
  mutation CreateRelevantCase($alertTypeId: ID!, $relevantCase: RelevantCaseMutationInput!, $caseSubsets: [CaseSubsetReasonMutationInput]) {
    relevantCaseCreate(alertTypeId: $alertTypeId, relevantCase: $relevantCase, caseSubsets: $caseSubsets) {
      id
    }
  }
`;

const UPDATE_RELEVANT_CASE = gql`
  mutation UpdateRelevantCase($relevantCaseId: ID!, $relevantCase: RelevantCaseMutationInput!) {
    relevantCaseUpdate(relevantCaseId: $relevantCaseId, relevantCase: $relevantCase) {
      id
    }
  }
`;

const DELETE_RELEVANT_CASE = gql`
  mutation DeleteRelevantCase($relevantCaseId: ID!) {
    relevantCaseDelete(relevantCaseId: $relevantCaseId)
  }
`;

const CREATE_CASE_SUBSET_REASON = gql`
  mutation CreateCaseSubsetReason($relevantCaseId: ID!, $caseSubsetReason: CaseSubsetReasonMutationInput!) {
    caseSubsetReasonCreate(relevantCaseId: $relevantCaseId, caseSubsetReason: $caseSubsetReason) {
      id
    }
  }
`;

const UPDATE_CASE_SUBSET_REASON = gql`
  mutation UpdateCaseSubsetReason($subsetReasonId: ID!, $caseSubsetReason: CaseSubsetReasonMutationInput!) {
    caseSubsetReasonUpdate(subsetReasonId: $subsetReasonId, caseSubsetReason: $caseSubsetReason) {
      id
    }
  }
`;

const DELETE_CASE_SUBSET_REASON = gql`
  mutation DeleteCaseSubsetReason($subsetReasonId: ID!) {
    caseSubsetReasonDelete(subsetReasonId: $subsetReasonId)
  }
`;

// get the update params to alertType
const getUpdateAlertTypeParams = (values) => {
  const settingValues = JSON.parse(JSON.stringify(values));
  settingValues.activeForCaseCreation = parseInt(settingValues.activeForCaseCreation, 10);
  settingValues.closedCaseWaitPeriod = parseInt(settingValues.closedCaseWaitPeriod, 10);
  settingValues.caseCreateLatency = parseInt(settingValues.caseCreateLatency, 10);
  settingValues.currentSeverityThreshold = parseFloat(settingValues.currentSeverityThreshold);
  delete settingValues.id;
  delete settingValues.relevantCases;
  const updateAlertSettingParams = {
    mutation: UPDATE_ALERT_SETTINGS,
    variables: {
      id: values.id,
      alertType: settingValues,
    },
  };
  return [updateAlertSettingParams];
};

// Find all relevantCases that do not have an id, also find the
// subsetCase that belongs to the uncreaeted relevantCase
const getCreateRelevantCasesParams = (values) => {
  const createRelevantCasesParams = [];
  for (let i = 0; i < values.relevantCases.edges.length; i += 1) {
    const relevantCase = values.relevantCases.edges[i];
    if (!relevantCase.node.id) {
      const caseParams = {
        mutation: CREATE_RELEVANT_CASE,
        variables: {
          alertTypeId: values.id,
          relevantCase: {
            reason: relevantCase.node.reason,
          },
          caseSubsets: relevantCase.node.caseSubsetReasons.edges.map((caseSubset) => {
            return {
              subsetReason: caseSubset.node.subsetReason,
            };
          }),
        },
      };
      createRelevantCasesParams.push(caseParams);
    }
  }
  return createRelevantCasesParams;
};

// Find all relvantCase values that differ from their original values
const getUpdateRelevantCasesParams = (values, originalValues) => {
  const updateRelevantCasesParams = [];
  for (let i = 0; i < originalValues.relevantCases.edges.length; i += 1) {
    const originalCase = originalValues.relevantCases.edges[i];
    for (let j = 0; j < values.relevantCases.edges.length; j += 1) {
      const currentCase = values.relevantCases.edges[j];
      if (currentCase.node.id === originalCase.node.id && currentCase.node.reason !== originalCase.node.reason) {
        const caseParams = {
          mutation: UPDATE_RELEVANT_CASE,
          variables: {
            relevantCaseId: currentCase.node.id,
            relevantCase: {
              reason: currentCase.node.reason,
            },
          },
        };
        updateRelevantCasesParams.push(caseParams);
        break;
      }
    }
  }
  return updateRelevantCasesParams;
};

// Find all relvantCase original values that don't appear in the updated values
const getDeleteRelevantCasesParams = (values, originalValues) => {
  const deleteRelevantCasesParams = [];
  for (let i = 0; i < originalValues.relevantCases.edges.length; i += 1) {
    const originalCase = originalValues.relevantCases.edges[i];
    let hasCase = false;
    for (let j = 0; j < values.relevantCases.edges.length; j += 1) {
      const currentCase = values.relevantCases.edges[j];
      if (currentCase.node.id === originalCase.node.id) {
        hasCase = true;
        break;
      }
    }
    if (!hasCase) {
      const caseParams = {
        mutation: DELETE_RELEVANT_CASE,
        variables: {
          relevantCaseId: originalCase.node.id,
        },
      };
      deleteRelevantCasesParams.push(caseParams);
    }
  }
  return deleteRelevantCasesParams;
};

// Find all caseSubset values without a id and create them
// - if the id doens't exist on the relevant case then the subcase will be
//   found on the getCreateRelevantCase method above
const getCreateCaseSubsetsParams = (values) => {
  const createCaseSubsetsParams = [];
  for (let i = 0; i < values.relevantCases.edges.length; i += 1) {
    const relevantCase = values.relevantCases.edges[i];
    if (relevantCase.node.id) {
      for (let j = 0; j < relevantCase.node.caseSubsetReasons.edges.length; j += 1) {
        const caseSubsetReason = relevantCase.node.caseSubsetReasons.edges[j];
        if (!caseSubsetReason.node.id && caseSubsetReason.node.subsetReason !== '') {
          const subsetParams = {
            mutation: CREATE_CASE_SUBSET_REASON,
            variables: {
              relevantCaseId: relevantCase.node.id,
              caseSubsetReason: {
                subsetReason: caseSubsetReason.node.subsetReason,
              },
            },
          };
          createCaseSubsetsParams.push(subsetParams);
        }
      }
    }
  }
  return createCaseSubsetsParams;
};

// Find all caseSubset values that don't match their original values
const getUpdateCaseSubsetsParams = (values, originalValues) => {
  const updateCaseSubsetsParams = [];
  for (let i = 0; i < values.relevantCases.edges.length; i += 1) {
    const relevantCase = values.relevantCases.edges[i];
    if (relevantCase.node.id) {
      for (let j = 0; j < relevantCase.node.caseSubsetReasons.edges.length; j += 1) {
        const caseSubsetReason = relevantCase.node.caseSubsetReasons.edges[j];
        for (let n = 0; n < originalValues.relevantCases.edges.length; n += 1) {
          const originalRelevantCase = originalValues.relevantCases.edges[n];
          for (let m = 0; m < originalRelevantCase.node.caseSubsetReasons.edges.length; m += 1) {
            const originalCaseSubsetReason = originalRelevantCase.node.caseSubsetReasons.edges[m];
            if (caseSubsetReason.node.id === originalCaseSubsetReason.node.id && caseSubsetReason.node.subsetReason !== originalCaseSubsetReason.node.subsetReason) {
              const subsetParams = {
                mutation: UPDATE_CASE_SUBSET_REASON,
                variables: {
                  subsetReasonId: caseSubsetReason.node.id,
                  caseSubsetReason: {
                    subsetReason: caseSubsetReason.node.subsetReason,
                  },
                },
              };
              updateCaseSubsetsParams.push(subsetParams);
            }
          }
        }
      }
    }
  }
  return updateCaseSubsetsParams;
};

// Find all caseSubset values that don't exist anymore
const getDeleteCaseSubsetsParams = (values, originalValues) => {
  const deleteCaseSubsets = [];
  for (let i = 0; i < originalValues.relevantCases.edges.length; i += 1) {
    const originalRelevantCase = originalValues.relevantCases.edges[i];
    for (let j = 0; j < values.relevantCases.edges.length; j += 1) {
      const relevantCase = values.relevantCases.edges[j];
      if (originalRelevantCase.node.id === relevantCase.node.id) {
        for (let n = 0; n < originalRelevantCase.node.caseSubsetReasons.edges.length; n += 1) {
          const originalCaseSubsetReason = originalRelevantCase.node.caseSubsetReasons.edges[n];
          let hasSubsetCase = false;
          for (let m = 0; m < relevantCase.node.caseSubsetReasons.edges.length; m += 1) {
            const caseSubsetReason = relevantCase.node.caseSubsetReasons.edges[m];
            if (caseSubsetReason.node.id === originalCaseSubsetReason.node.id) {
              hasSubsetCase = true;
              break;
            }
          }
          if (!hasSubsetCase) {
            const deleteCaseSubsetParams = {
              mutation: DELETE_CASE_SUBSET_REASON,
              variables: {
                subsetReasonId: originalCaseSubsetReason.node.id,
              },
            };
            deleteCaseSubsets.push(deleteCaseSubsetParams);
          }
        }
      }
    }
  }
  return deleteCaseSubsets;
};

const formSchema = Yup.object().shape({
  currentSeverityThreshold: Yup.number()
    .positive('Positive numbers only')
    .required('Required'),
});

class AlertSettingsForm extends PureComponent {
  constructor(props) {
    super(props);
    this.handleIntRefChange = this.handleIntRefChange.bind(this);
    this.priorityOptions = props.priorityItems.map((item) => {
      return {
        name: item.node.name,
        value: item.node.intRef,
      };
    });
    this.state = {
      priorityTypeIndex: 0,
    };
  }

  handleOnClick = () => {
    mixpanel.track('Alert Settings Updated');
  }

  errorComponent = (msg, fieldName) => {
    if (msg.split(' ')[0] === fieldName) {
      return <StyledError>Numbers Only</StyledError>;
    }
    return <StyledError>{msg}</StyledError>;
  }

  handleIntRefChange(name, value, formikResetForm) {
    const { priorityItems } = this.props;
    const priorityTypeIndex = priorityItems.findIndex(item => item.node.intRef === value);
    if (priorityTypeIndex !== -1) {
      const priorityTypeItem = priorityItems[priorityTypeIndex].node;
      this.setState({ priorityTypeIndex });
      formikResetForm({
        intRef: value,
        id: priorityTypeItem.id,
        activeForCaseCreation: priorityTypeItem.activeForCaseCreation.toString(),
        currentSeverityThreshold: priorityTypeItem.currentSeverityThreshold.toString(),
        closedCaseWaitPeriod: priorityTypeItem.closedCaseWaitPeriod.toString(),
        caseCreateLatency: priorityTypeItem.caseCreateLatency.toString(),
        sfdcCasePriority: priorityTypeItem.sfdcCasePriority,
        sfdcCaseStatus: priorityTypeItem.sfdcCaseStatus,
        sfdcCaseOwnerId: priorityTypeItem.sfdcCaseOwnerId,
        sfdcCaseRecordTypeId: priorityTypeItem.sfdcCaseRecordTypeId,
        sfdcCaseOrigin: priorityTypeItem.sfdcCaseOrigin,
        caseReason: priorityTypeItem.caseReason,
        caseSubsetReason: priorityTypeItem.caseSubsetReason,
        caseSubject: priorityTypeItem.caseSubject,
        caseDescription: priorityTypeItem.caseDescription,
        relevantCases: priorityTypeItem.relevantCases,
      });
    }
  }

  render() {
    const { priorityTypeIndex } = this.state;
    const { priorityItems, handleRefetch } = this.props;
    const priorityTypeItem = priorityItems[priorityTypeIndex].node;
    const timeUnit = priorityTypeItem.severityLabel.charAt(0).toUpperCase() + priorityTypeItem.severityLabel.slice(1);
    return (
      <Mutation mutation={UPDATE_ALERT_SETTINGS}>
        {(updateAlertSettings, alertSettingsMutation) => (
          <Formik
            onSubmit={(values, actions) => {
              let alertUpdateParams = [];
              alertUpdateParams = alertUpdateParams.concat(getUpdateAlertTypeParams(values));
              alertUpdateParams = alertUpdateParams.concat(getCreateRelevantCasesParams(values));
              alertUpdateParams = alertUpdateParams.concat(getUpdateRelevantCasesParams(values, priorityTypeItem));
              alertUpdateParams = alertUpdateParams.concat(getDeleteRelevantCasesParams(values, priorityTypeItem));
              alertUpdateParams = alertUpdateParams.concat(getCreateCaseSubsetsParams(values));
              alertUpdateParams = alertUpdateParams.concat(getUpdateCaseSubsetsParams(values, priorityTypeItem));
              alertUpdateParams = alertUpdateParams.concat(getDeleteCaseSubsetsParams(values, priorityTypeItem));
              const alertPromises = alertUpdateParams.map((mutationParams) => {
                return alertSettingsMutation.client.mutate(mutationParams);
              });
              actions.setSubmitting(true);
              Promise.all(alertPromises)
                .then(() => {
                  handleRefetch();
                  actions.setSubmitting(false);
                })
                .catch(() => {
                  handleRefetch();
                  actions.setSubmitting(false);
                });
            }}
            initialValues={{
              intRef: this.priorityOptions[0].value,
              id: priorityTypeItem.id,
              activeForCaseCreation: priorityTypeItem.activeForCaseCreation.toString(),
              currentSeverityThreshold: priorityTypeItem.currentSeverityThreshold.toString(),
              closedCaseWaitPeriod: priorityTypeItem.closedCaseWaitPeriod.toString(),
              caseCreateLatency: priorityTypeItem.caseCreateLatency.toString(),
              sfdcCasePriority: priorityTypeItem.sfdcCasePriority,
              sfdcCaseStatus: priorityTypeItem.sfdcCaseStatus,
              sfdcCaseOwnerId: priorityTypeItem.sfdcCaseOwnerId,
              sfdcCaseRecordTypeId: priorityTypeItem.sfdcCaseRecordTypeId,
              sfdcCaseOrigin: priorityTypeItem.sfdcCaseOrigin,
              caseReason: priorityTypeItem.caseReason,
              caseSubsetReason: priorityTypeItem.caseSubsetReason,
              caseSubject: priorityTypeItem.caseSubject,
              caseDescription: priorityTypeItem.caseDescription,
              relevantCases: priorityTypeItem.relevantCases,
            }}
            validationSchema={formSchema}
            render={({
              handleChange,
              handleSubmit,
              isSubmitting,
              resetForm,
              setFieldValue,
              values,
            }) => (
              <Fragment>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={6}>
                      <InvertedDropDown
                        name="intRef"
                        placeholder="Int Ref"
                        options={this.priorityOptions}
                        value={values.intRef}
                        onChange={(name, option) => {
                          this.handleIntRefChange(name, option.value, resetForm);
                        }}
                      />
                      <div>
                        <SettingItem>
                          Active for Case Creation
                          <SettingsDropDown
                            placeholder="Case Creation"
                            options={[
                              {
                                name: 'Yes',
                                value: '1',
                              },
                              {
                                name: 'No',
                                value: '0',
                              },
                            ]}
                            name="activeForCaseCreation"
                            value={values.activeForCaseCreation}
                            onChange={(name, option) => setFieldValue(name, option.value)}
                          />
                        </SettingItem>
                        <SettingItem>
                          {`Alert Severity (${timeUnit})`}
                          <SmallSettingsInput
                            name="currentSeverityThreshold"
                            onChange={handleChange}
                            value={values.currentSeverityThreshold}
                          />
                        </SettingItem>
                        <ErrorMessage
                          name="currentSeverityThreshold"
                          render={msg => this.errorComponent(msg, 'currentSeverityThreshold')}
                        />
                        <SettingItem>
                          Closed Case Wait Period (Days)
                          <SettingsDropDown
                            placeholder="Case Creation"
                            options={dayOptions}
                            name="closedCaseWaitPeriod"
                            value={values.closedCaseWaitPeriod}
                            onChange={(name, option) => setFieldValue(name, option.value)}
                          />
                        </SettingItem>
                        <SettingItem>
                          Case Create Duration Wait (Days)
                          <SettingsDropDown
                            placeholder="Case Create Duration"
                            options={dayOptions}
                            name="caseCreateLatency"
                            value={values.caseCreateLatency}
                            onChange={(name, option) => setFieldValue(name, option.value)}
                          />
                        </SettingItem>
                        <SettingItem>
                          Case Create Priority
                          <SettingsDropDown
                            placeholder="Case Priority"
                            options={priorityOptions}
                            name="sfdcCasePriority"
                            value={values.sfdcCasePriority}
                            onChange={(name, option) => setFieldValue(name, option.value)}
                          />
                        </SettingItem>
                        <SettingItem>
                          Case Status
                          <SettingsDropDown
                            placeholder="Case Status"
                            options={statusOptions}
                            name="sfdcCaseStatus"
                            value={values.sfdcCaseStatus}
                            onChange={(name, option) => setFieldValue(name, option.value)}
                          />
                        </SettingItem>
                        <SettingItem>
                          Case Owner ID
                          <div>{values.sfdcCaseOwnerId}</div>
                        </SettingItem>
                        <SettingItem>
                          Case Owner Type ID
                          <div>{values.sfdcCaseRecordTypeId}</div>
                        </SettingItem>
                        <SettingItem>
                          Case Origin
                          <div>{values.sfdcCaseOrigin}</div>
                        </SettingItem>
                        <ErrorMessage
                          name="sfdcCaseOrigin"
                          render={msg => <StyledError>{msg}</StyledError>}
                        />
                        <SettingItem>
                          Case Reason
                          <SettingsInput
                            name="caseReason"
                            value={values.caseReason || ''}
                            onChange={handleChange}
                          />
                        </SettingItem>
                        <SettingItem>
                          Case Subset Reason
                          <SettingsInput
                            name="caseSubsetReason"
                            value={values.caseSubsetReason || ' '}
                            onChange={handleChange}
                          />
                        </SettingItem>
                        <SettingItem>
                          Case Subject
                          <SettingsInput
                            name="caseSubject"
                            value={values.caseSubject || ''}
                            onChange={handleChange}
                          />
                        </SettingItem>
                      </div>
                    </Col>
                    <RightCol xs={6}>
                      <Title>
                        Case Description
                      </Title>
                      <TextArea
                        name="caseDescription"
                        value={values.caseDescription || ''}
                        onChange={handleChange}
                      />
                      <Title>
                        Relevant Cases
                      </Title>
                      <Row>
                        <Col xs={6}>
                          <LightTitle>
                            Case Reason
                          </LightTitle>
                        </Col>
                        <Col xs={6}>
                          <LightTitle>
                            Case Subset Reason
                          </LightTitle>
                        </Col>
                      </Row>
                      <FieldArray
                        name="relevantCases.edges"
                        render={(relevantCasesActions) => {
                          return (
                            <Row>
                              {
                                values.relevantCases.edges.map((_rC, caseIndex) => {
                                  return (
                                    <CaseRow key={`relvantCase-${_rC.node.id ? _rC.node.id : caseIndex}`}>
                                      <Col xs={6}>
                                        <CaseRow>
                                          <CaseToggleButton
                                            isExpanded
                                            clickHandler={() => {
                                              relevantCasesActions.remove(caseIndex);
                                            }}
                                          />
                                          <RelevantCaseInput
                                            name={`relevantCases.edges.${caseIndex}.node.reason`}
                                            value={values.relevantCases.edges[caseIndex].node.reason}
                                            onChange={handleChange}
                                          />
                                        </CaseRow>
                                      </Col>
                                      <Col xs={6}>
                                        <FieldArray
                                          name={`relevantCases.edges.${caseIndex}.node.caseSubsetReasons.edges`}
                                          render={(subRelevantCasesActions) => {
                                            return (
                                              <Fragment>
                                                {
                                                  values.relevantCases.edges[caseIndex].node.caseSubsetReasons.edges.map((_sRC, subIndex) => {
                                                    return (
                                                      <SubCaseRow key={`subsetCase-${_sRC.node.id ? _sRC.node.id : subIndex}`}>
                                                        <CaseToggleButton
                                                          isExpanded
                                                          clickHandler={() => {
                                                            subRelevantCasesActions.remove(subIndex);
                                                          }}
                                                        />
                                                        <RelevantCaseInput
                                                          name={`relevantCases.edges.${caseIndex}.node.caseSubsetReasons.edges.${subIndex}.node.subsetReason`}
                                                          value={values.relevantCases.edges[caseIndex].node.caseSubsetReasons.edges[subIndex].node.subsetReason}
                                                          onChange={handleChange}
                                                        />
                                                      </SubCaseRow>
                                                    );
                                                  })
                                                }
                                                <SubCaseRow>
                                                  <CaseToggleButton
                                                    clickHandler={() => {
                                                      subRelevantCasesActions.push({
                                                        node: {
                                                          subsetReason: '',
                                                        },
                                                      });
                                                    }}
                                                  />
                                                </SubCaseRow>
                                              </Fragment>
                                            );
                                          }}
                                        />
                                      </Col>
                                    </CaseRow>
                                  );
                                })
                              }
                              <CaseToggleButton
                                clickHandler={() => {
                                  relevantCasesActions.push({
                                    node: {
                                      reason: '',
                                      caseSubsetReasons: {
                                        edges: [],
                                      },
                                    },
                                  });
                                }}
                              />
                            </Row>
                          );
                        }}
                      />
                      <UpdateButton type="submit" disabled={isSubmitting} onClick={this.handleOnClick}>
                        Update
                      </UpdateButton>
                    </RightCol>
                  </Row>
                </form>
                {
                  isSubmitting && (
                    <CenteredLoader />
                  )
                }
              </Fragment>
            )}
          />
        )}
      </Mutation>
    );
  }
}

AlertSettingsForm.propTypes = {
  priorityItems: PropTypes.arrayOf(PropTypes.shape({
    node: PropTypes.shape({
      activeForCaseCreation: PropTypes.number.isRequired,
      alertColor: PropTypes.string.isRequired,
      caseCreateLatency: PropTypes.number.isRequired,
      caseDescription: PropTypes.string,
      caseReason: PropTypes.string,
      caseSubject: PropTypes.string,
      caseSubsetReason: PropTypes.string,
      closedCaseWaitPeriod: PropTypes.number.isRequired,
      createdDate: PropTypes.string.isRequired,
      currentSeverityThreshold: PropTypes.number.isRequired,
      id: PropTypes.string.isRequired,
      intRef: PropTypes.string.isRequired,
      lastUpdated: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      relevantCases: PropTypes.shape({}),
      severityLabel: PropTypes.string.isRequired,
      severityMultiplier: PropTypes.number.isRequired,
      sfdcCaseOrigin: PropTypes.string.isRequired,
      sfdcCaseOwnerId: PropTypes.string.isRequired,
      sfdcCasePriority: PropTypes.string.isRequired,
      sfdcCaseRecordTypeId: PropTypes.string.isRequired,
      sfdcCaseStatus: PropTypes.string.isRequired,
    }),
  })).isRequired,
  handleRefetch: PropTypes.func.isRequired,
};

export const UnitTestingDiff = {
  getUpdateAlertTypeParams,
  getCreateRelevantCasesParams,
  getUpdateRelevantCasesParams,
  getDeleteRelevantCasesParams,
  getCreateCaseSubsetsParams,
  getUpdateCaseSubsetsParams,
  getDeleteCaseSubsetsParams,
  UPDATE_ALERT_SETTINGS,
  CREATE_RELEVANT_CASE,
  UPDATE_RELEVANT_CASE,
  DELETE_RELEVANT_CASE,
  CREATE_CASE_SUBSET_REASON,
  UPDATE_CASE_SUBSET_REASON,
  DELETE_CASE_SUBSET_REASON,
};

export default AlertSettingsForm;
