import React from 'react';
import styled from '@emotion/styled/macro';
import { RED } from '../constants/_colors';

const ErrorMessage = styled.div`
  width: 100%;
  text-align: center;
  color: ${RED};
  font-size: 15px;
`;

const NotAuthorized = () => (
  <ErrorMessage>
    You do not have the correct permissions to view this page.
  </ErrorMessage>
);

export default NotAuthorized;
