import React, { useState } from 'react';
import { withAuth } from '@okta/okta-react';
import { gql, useMutation } from '@apollo/client';
import { DownloadCsv } from '@sunrun/recent-performance-charts';
import CSVReader from 'react-csv-reader';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  adHocStyles,
  StyledButtonCancel,
  StyledButtonContainer,
  StyledButtonUpload,
  StyledErrorCell,
  StyledErrorHeader,
  StyledErrorText,
  StyledFileInputLabel,
  StyledPageContainer,
  StyledSampleCSVDownload,
  StyledSuccessChip,
  StyledTableBody,
  StyledTabledCell,
} from './styles';

const sampleDownload = {
  serviceContractNumber: 'Service Contract Number',
  serialNumber: 'Meter/inverter serial number',
  meterType: 'Manufacturer taken from SFDC (SS ITron, Enphase, Etc)',
  meterReadStartLocal: 'Start date when communication/data was lost (optional) Format: MM/DD/YYY',
  meterReadEndLocal: 'Date that meter was replaced Format: MM/DD/YYYY',
  meterKwhReading: 'Final kWh reading of the meter',
  readingType: 'This would be categorized as “meter replacement”  (optional)',
  source: 'This would be categorized as “SFDC” (optional)',
};

const useStyles = makeStyles(adHocStyles);

const UPLOAD_AD_HOC_METER_READINGS = gql`
  mutation AdHocMeterReadingsUpload($meterReadings: [AdHocMeterReadingsMutationType]!) {
    adHocMeterReadingsUpload(meterReadings: $meterReadings) {
      errors {
        msg
        code
      }
      meterKwhReading
      meterReadEndLocal
      meterReadStartLocal
      meterType
      readingType
      serialNumber
      serviceContractNumber
      source
    }
  }
`;

const AdHocContainer = () => {
  const classes = useStyles();
  const [parsedData, setParsedData] = useState(null);
  const [updateAdHoc, { error, loading }] = useMutation(UPLOAD_AD_HOC_METER_READINGS);
  const [responseData, setResponseData] = useState(null);
  const [hasResponseError, setHasResponseError] = useState(false);

  const inputHandler = (newParsedData) => {
    setParsedData(newParsedData);
    setResponseData(null);
  };

  const handleUpload = () => {
    updateAdHoc({ variables: { meterReadings: parsedData }, onError: e => e.message })
      .then((resp) => {
        if (resp.data.adHocMeterReadingsUpload) {
          setResponseData(resp.data.adHocMeterReadingsUpload);
        } else {
          setHasResponseError(true);
        }
      })
      .catch(() => setHasResponseError(true));
  };

  if (hasResponseError) {
    return (
      <StyledPageContainer>
        <StyledErrorText>
          {(error && error.message) || 'An unknown error occured.'}
        </StyledErrorText>
      </StyledPageContainer>
    );
  }
  if (parsedData && !responseData) {
    return (
      <StyledPageContainer>
        <h2 className={classes.textSpacing}>Upload Preview</h2>
        <p className={classes.textSpacing}>
          Check the first five rows and confirm to upload.
          <i> Changes will take up to 24 hours to process.</i>
        </p>
        <p className={classes.textSpacing}>
          <b>
            {`${parsedData.length} `}
            rows will be updated.
          </b>
        </p>
        <TableContainer className={classes.textSpacing}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTabledCell>Row #</StyledTabledCell>
                <StyledTabledCell>SCN</StyledTabledCell>
                <StyledTabledCell>Serial Number</StyledTabledCell>
                <StyledTabledCell>Meter Type</StyledTabledCell>
                <StyledTabledCell>Meter Reading (kWh)</StyledTabledCell>
                <StyledTabledCell>Start Date</StyledTabledCell>
                <StyledTabledCell>End Date</StyledTabledCell>
                <StyledTabledCell>Reading Type</StyledTabledCell>
                <StyledTabledCell>Source</StyledTabledCell>
              </TableRow>
            </TableHead>
            <StyledTableBody>
              {parsedData.slice(0, 5).map((row, index) => (
                <TableRow key={`${row.serviceContractNumber}${row.serialNumber}`}>
                  <StyledTabledCell>{`${index + 1}`}</StyledTabledCell>
                  <StyledTabledCell>{row.serviceContractNumber}</StyledTabledCell>
                  <StyledTabledCell>{row.serialNumber}</StyledTabledCell>
                  <StyledTabledCell>{row.meterType}</StyledTabledCell>
                  <StyledTabledCell>{row.meterKwhReading}</StyledTabledCell>
                  <StyledTabledCell>{row.meterReadStartLocal}</StyledTabledCell>
                  <StyledTabledCell>{row.meterReadEndLocal}</StyledTabledCell>
                  <StyledTabledCell>{row.readingType}</StyledTabledCell>
                  <StyledTabledCell>{row.source}</StyledTabledCell>
                </TableRow>
              ))}
            </StyledTableBody>
          </Table>
        </TableContainer>
        <StyledButtonContainer>
          <StyledButtonUpload
            disabled={loading}
            onClick={handleUpload}
            size="large"
          >
            Confirm & Upload
          </StyledButtonUpload>
          <StyledButtonCancel
            disabled={loading}
            onClick={() => setParsedData(null)}
            size="small"
          >
            Cancel
          </StyledButtonCancel>
        </StyledButtonContainer>
      </StyledPageContainer>
    );
  }

  return (
    <StyledPageContainer>
      <h2 className={classes.textSpacing}>Upload Meter Reading</h2>
      <StyledButtonContainer>
        <CSVReader
          cssInputClass={classes.fileInput}
          label={
            <StyledFileInputLabel>Upload CSV</StyledFileInputLabel>
          }
          onFileLoaded={inputHandler}
          parserOptions={{ header: true }}
        />
        <DownloadCsv
          data={[sampleDownload]}
          fields={Object.keys(sampleDownload).map(k => ({ label: k, value: k }))}
          fileName="Sample Ad Hoc Meter Reading CSV"
        >
          <StyledSampleCSVDownload>Download CSV Template</StyledSampleCSVDownload>
        </DownloadCsv>
      </StyledButtonContainer>
      {responseData && (
        <>
          <div className={classes.textSpacing}>
            <StyledSuccessChip
              label={`${parsedData.length - responseData.length} rows updated successfully.`}
            />
          </div>
          {responseData.length
            ? (
              <>
                <StyledErrorHeader>
                  <p>
                    {`${responseData.length} `}
                    errors. Please check these rows and try again.
                  </p>
                  <DownloadCsv
                    data={responseData}
                    fields={Object.keys(responseData[0]).map(k => ({ label: k, value: k }))}
                    fileName="Upload Errors"
                  >
                    <div className={classes.sampleCSVLink}>Download Errors as CSV</div>
                  </DownloadCsv>
                </StyledErrorHeader>
                <TableContainer className={classes.textSpacing}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTabledCell>Row #</StyledTabledCell>
                        <StyledTabledCell>SCN</StyledTabledCell>
                        <StyledTabledCell>Serial Number</StyledTabledCell>
                        <StyledTabledCell>Meter Type</StyledTabledCell>
                        <StyledTabledCell>Meter Reading (kWh)</StyledTabledCell>
                        <StyledTabledCell>Start Date</StyledTabledCell>
                        <StyledTabledCell>End Date</StyledTabledCell>
                        <StyledTabledCell>Reading Type</StyledTabledCell>
                        <StyledTabledCell>Source</StyledTabledCell>
                      </TableRow>
                    </TableHead>
                    <StyledTableBody>
                      {responseData.map((row, index) => (
                        <TableRow key={`${row.serviceContractNumber}${row.serialNumber}`}>
                          <StyledTabledCell>
                            <StyledErrorCell>
                              {`${index + 1}`}
                            </StyledErrorCell>
                            <StyledErrorText />
                          </StyledTabledCell>
                          <StyledTabledCell>
                            <StyledErrorCell>
                              {row.serviceContractNumber}
                            </StyledErrorCell>
                            <StyledErrorText>
                              {
                                row.errors
                                  .filter(e => e.code === 1 || e.code === 2)
                                  .map(e => e.msg)
                                  .join(', ')
                              }
                            </StyledErrorText>
                          </StyledTabledCell>
                          <StyledTabledCell>
                            <StyledErrorCell>
                              {row.serialNumber}
                            </StyledErrorCell>
                            <StyledErrorText>
                              {
                                row.errors
                                  .filter(e => e.code === 3 || e.code === 9)
                                  .map(e => e.msg)
                                  .join(', ')
                              }
                            </StyledErrorText>
                          </StyledTabledCell>
                          <StyledTabledCell>
                            <StyledErrorCell>
                              {row.meterType}
                            </StyledErrorCell>
                            <StyledErrorText>
                              {
                                row.errors
                                  .filter(e => e.code === 4 || e.code === 5)
                                  .map(e => e.msg)
                                  .join(', ')
                              }
                            </StyledErrorText>
                          </StyledTabledCell>
                          <StyledTabledCell>
                            <StyledErrorCell>
                              {row.meterKwhReading}
                            </StyledErrorCell>
                            <StyledErrorText>
                              {
                                row.errors
                                  .filter(e => e.code === 8)
                                  .map(e => e.msg)
                                  .join(', ')
                              }
                            </StyledErrorText>
                          </StyledTabledCell>
                          <StyledTabledCell>
                            <StyledErrorCell>
                              {row.meterReadStartLocal}
                            </StyledErrorCell>
                            <StyledErrorText>
                              {
                                row.errors
                                  .filter(e => e.code === 6 || e.code === 10)
                                  .map(e => e.msg)
                                  .join(', ')
                              }
                            </StyledErrorText>
                          </StyledTabledCell>
                          <StyledTabledCell>
                            <StyledErrorCell>
                              {row.meterReadEndLocal}
                            </StyledErrorCell>
                            <StyledErrorText>
                              {
                                row.errors
                                  .filter(e => e.code === 7 || e.code === 11)
                                  .map(e => e.msg)
                                  .join(', ')
                              }
                            </StyledErrorText>
                          </StyledTabledCell>
                          <StyledTabledCell>
                            <StyledErrorCell>
                              {row.readingType}
                            </StyledErrorCell>
                            <StyledErrorText />
                          </StyledTabledCell>
                          <StyledTabledCell>
                            <StyledErrorCell>
                              {row.source}
                            </StyledErrorCell>
                            <StyledErrorText />
                          </StyledTabledCell>
                        </TableRow>
                      ))}
                    </StyledTableBody>
                  </Table>
                </TableContainer>
              </>
            )
            : null
          }
        </>
      )}
    </StyledPageContainer>
  );
};

export default withAuth(AdHocContainer);
