import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Row } from 'react-flexbox-grid';

import { MODAL_ZINDEX } from '../constants/_zIndex';
import { Card } from './index';

const Overlay = styled.div`
  @keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

  animation: fadeIn .2s ease-in-out 0s forwards;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, .8);
  display: flex;
  justify-content: center;
  position:fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${MODAL_ZINDEX}
`;

const Content = styled.div`
  max-width: 90vw;
  margin-top: 100px;
  max-height: 80vh;
`;

const ModalHeader = styled(Row)`
  padding: 20px 0;
  width: 100%;
`;

const ModalContent = styled(Card)`
  padding: 0 20px;
  max-height: 80vh;
  overflow: auto;
  width: 90vw;
`;

const Modal = ({ isOpen, children, closeModal }) => {
  if (!isOpen) return null;
  return (
    <Overlay onClick={closeModal}>
      <Content onClick={e => e.stopPropagation()}>
        {children}
      </Content>
    </Overlay>
  );
};

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export {
  ModalContent,
  ModalHeader,
};
export default Modal;
