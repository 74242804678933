import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '@okta/okta-react';

import LoginComponent from '../components/LoginComponent';

const LoginContainer = ({ auth }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    const checkAuthentication = async () => {
      const authentication = await auth.isAuthenticated();
      if (authentication !== isAuthenticated) {
        if (authentication !== userInfo) {
          const newUserInfo = await auth.getUser();
          setUserInfo(newUserInfo);
        }
        setIsAuthenticated(authentication);
      }
    };
    checkAuthentication();
  }, [auth, isAuthenticated, userInfo]);
  const login = useCallback(async () => {
    auth.login('/');
  }, [auth]);
  if (isAuthenticated === null) return null;
  return (
    <LoginComponent
      isAuthenticated={isAuthenticated}
      name={userInfo ? userInfo.given_name : undefined}
      onLogin={login}
    />
  );
};

LoginContainer.propTypes = {
  auth: PropTypes.shape({
    getUser: PropTypes.func,
    isAuthenticated: PropTypes.func,
    login: PropTypes.func,
  }).isRequired,
};

export default withAuth(LoginContainer);
