import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withAuth } from '@okta/okta-react';
import { get, intersection } from 'lodash';

function withAuthorizationCheck(WrappedComponent, authorizedGroups) {
  const AuthorizationCheck = ({ auth: authentication, ...rest }) => {
    const [user, setUser] = useState(null);
    const { getUser } = authentication;
    useEffect(() => {
      const authUser = async () => {
        const userAuth = await getUser();
        setUser(userAuth);
      };
      authUser();
      return () => setUser(null);
    }, [getUser]);

    if (!user) return null;
    const groupsUserBelongsTo = get(user, 'group_id', []);
    const isAuthorized = intersection(groupsUserBelongsTo, authorizedGroups).length > 0;
    return (
      <WrappedComponent {...rest} isAuthorized={isAuthorized} />
    );
  };

  AuthorizationCheck.propTypes = {
    auth: PropTypes.shape({
      getUser: PropTypes.func,
    }),
  };

  AuthorizationCheck.defaultProps = {
    auth: {},
  };

  return withAuth(AuthorizationCheck);
}

export default withAuthorizationCheck;
