import React from 'react';
import PropTypes from 'prop-types';
import { Button, Paper } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const Container = styled(Paper)({
  margin: 20,
  padding: 20,
  display: 'flex',
  justifyContent: 'space-evenly',
});

const ExponentialValueControls = ({
  handleExponentialValueSubmit,
  isActionLoading,
}) => (
  <Container elevation={3}>
    <Button
      disabled={isActionLoading}
      onClick={handleExponentialValueSubmit}
      variant="contained"
    >
      Get Sites with Exponential Values
    </Button>
  </Container>
);

ExponentialValueControls.propTypes = {
  handleExponentialValueSubmit: PropTypes.func.isRequired,
  isActionLoading: PropTypes.bool.isRequired,
};

export default ExponentialValueControls;
