import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import mixpanel from 'mixpanel-browser';

import {
  ButtonLink,
  Icon,
  Modal,
  ModalContent,
  ModalHeader,
  SectionHeader,
} from '../../general/components';
import { MeteringDetailsTable } from './Tables';
import { FADED_GREY } from '../../general/constants/_colors';

export const ActiveMeterLink = styled(ButtonLink)`
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: center;
  line-height: 20px;
  text-transform: uppercase;
`;

const ActiveMeterLinkIcon = styled(Icon)`
height: 20px;
margin-left: 3px;
width: 20px;
`;

const FootNote = styled.div`
  color: ${FADED_GREY};
  padding: 20px;
  text-align: right;
`;

const CloseXIcon = styled(Icon)`
  margin-left: 10px;
`;

const ActiveMeters = ({ prospectId }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <ActiveMeterLink onClick={() => {
        setIsOpen(true);
        mixpanel.track('Active Meters Modal Opened');
      }}
      >
      Active Meters
        <ActiveMeterLinkIcon name="ActiveMetersIcon" />
      </ActiveMeterLink>
      <Modal isOpen={isOpen} closeModal={() => setIsOpen(false)}>

        <ModalContent>
          <ModalHeader middle="xs" between="xs">
            <SectionHeader>Active Meters</SectionHeader>
            <ButtonLink onClick={() => setIsOpen(false)}>
              CLOSE
              <CloseXIcon name="CloseXIcon" />
            </ButtonLink>
          </ModalHeader>
          <MeteringDetailsTable prospectId={prospectId} hideInactive />
          <FootNote>
          * For all meters, please scroll down to the end of the page under System Details
          </FootNote>
        </ModalContent>

      </Modal>
    </>
  );
};

ActiveMeters.propTypes = {
  prospectId: PropTypes.string.isRequired,
};

export default ActiveMeters;
