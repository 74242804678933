import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import styled from '@emotion/styled/macro';
import Table from '../../../general/components/Table';
import { withAlertTypes } from '../../queries/withAlertTypeQueries';
import { withSitePeers } from '../../queries/withSiteQueries';
import { GREEN } from '../../../general/constants/_colors';
import { validAlertTypes } from '../../../general/constants/_alertColors';

const Circle = styled.div`
  background: ${props => props.alertColor};
  border-radius: 5px;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  width: 10px;
`;

const AlertLabel = styled.span`
  opacity: ${props => (props.peerAlertCount === 0 ? 0.5 : 1)};
`;

const Wrapper = styled.div`
  padding: 0 1em;
`;

const noIssuesAlertType = {
  intRef: 'no_issues',
  name: 'No Known Issues',
  alertColor: GREEN,
  caseReason: 'No Known Issues',
  casePriority: '15',
};

const reduceAlertTypes = (acc, alert) => {
  if (validAlertTypes[alert.intRef]) {
    const newAlert = {
      ...alert,
      alertColor: validAlertTypes[alert.intRef],
    };
    acc.push(newAlert);
    return acc;
  }
  return acc;
};

const columns = [
  {
    key: 'alert_type',
    name: 'Alert Type',
    headStyle: {
      paddingLeft: 32,
      textAlign: 'left',
    },
    style: {
      textAlign: 'left',
    },
    render: (row) => {
      return (
        <Fragment>
          <Circle alertColor={row.alertColor} />
          <AlertLabel peerAlertCount={row.peerAlertCount}>
            {row.name}
          </AlertLabel>
        </Fragment>
      );
    },
  },
  {
    key: 'number_of_alerts',
    name: 'Number of Alerts',
    headStyle: {
      textAlign: 'left',
    },
    style: {
      textAlign: 'left',
      paddingLeft: 18,
    },
    render: (row) => {
      return (
        <span style={{ opacity: row.peerAlertCount === 0 ? 0.5 : 1 }}>
          {`${row.peerAlertCount} systems`}
        </span>
      );
    },
  },
];

class AlertTable extends PureComponent {
  constructor(props) {
    super(props);
    this.formatPeerAlerts = this.formatPeerAlerts.bind(this);
  }

  formatPeerAlerts() {
    const {
      alertTypesQuery: { alertType },
      sitePeersQuery: { sitePeers },
    } = this.props;
    const shownAlertTypes = alertType.reduce(reduceAlertTypes, []);
    shownAlertTypes.push(noIssuesAlertType);
    return shownAlertTypes
      .map((alert) => {
        const alertCount = sitePeers.reduce((count, peer) => {
          if (
            peer.openAlerts.edges.length > 0
            && peer.openAlerts.edges[0].node.intRef === alert.intRef
          ) return count + 1;
          if (
            peer.openAlerts.edges.length < 1
            && alert.intRef === 'no_issues'
          ) return count + 1;
          return count;
        }, 0);
        return {
          ...alert,
          peerAlertCount: alertCount,
        };
      })
      .sort((a, b) => b.peerAlertCount - a.peerAlertCount);
  }

  render() {
    const { alertTypesQuery } = this.props;
    if (alertTypesQuery.alertType.length === 0) return null;
    const sortedAlerts = this.formatPeerAlerts();
    return (
      <Wrapper>
        <Table
          getUniqueKey={row => row.alertId}
          isBorderless
          rows={sortedAlerts}
          columns={columns}
        />
      </Wrapper>
    );
  }
}

AlertTable.propTypes = {
  alertTypesQuery: PropTypes.shape({
    alertType: PropTypes.arrayOf(
      PropTypes.shape({
        alertColor: PropTypes.string.isRequired,
        alertId: PropTypes.string.isRequired,
        caseReason: PropTypes.string,
        intRef: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  sitePeersQuery: PropTypes.shape({
    sitePeers: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  }).isRequired,
};


export default flowRight(
  withAlertTypes,
  withSitePeers,
)(AlertTable);
