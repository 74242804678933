import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { flowRight } from 'lodash';
import { DateTime } from 'luxon';
import { Icon } from '../../../general/components';
import { DOLPHIN_GREY } from '../../../general/constants/_colors';
import { withOpenAlerts } from '../../queries/withSiteQueries';
import { dateTimeFormat } from '../../../general/utils/dateFormatUtils';

const AlertRow = styled.div`
  display: flex;
  overflow: ${props => (props.isExpanded ? 'auto' : 'hidden')};
  padding: 10px;
  padding-top: 0;
`;

const AlertWrap = styled.div`
  dispaly: flex;
  min-width: 180px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-right: 1px solid ${DOLPHIN_GREY};
  text-align: center;
`;

const Title = styled.h3`
  font-size: 16px;
  margin-bottom: 7px;
`;

const AlertIcon = styled(Icon)`
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
`;

const Info = styled.p`
  font-size: 16px;
`;

const iconMap = {
  non_comm: 'NetworkErrorIcon',
  sys_fail: 'SolarIcon',
  sysact_sys_fail: 'AlertGreyIcon',
  non_nighttime: 'AlertGreyIcon',
  under_performance: 'DownChartIcon',
  performance_decline: 'DownArrowIcon',
  inv_fail: 'ClipboardIcon',
  sysact_non_comm: 'AlertGreyIcon',
  sysact_non_nighttime: 'AlertGreyIcon',
  sysact_under_performance: 'AlertGreyIcon',
  environ_event: 'CloudIcon',
  over_performance: 'BoltIcon',
  sudden_perf_drop: 'DownChartIcon',
};

const AlertInformation = ({ isExpanded, openAlertsQuery }) => {
  if (!openAlertsQuery.openAlerts) return null;
  return (
    <AlertRow isExpanded={isExpanded}>
      {
        openAlertsQuery.openAlerts.length > 0 && (
          openAlertsQuery.openAlerts.map((alert) => {
            return (
              <AlertWrap key={alert.alertId}>
                <Title>
                  {alert.caseReason}
                </Title>
                <AlertIcon name={iconMap[alert.intRef]} />
                <Info>
                  { dateTimeFormat(alert.createdDateUTC) }
                </Info>
                <Info>
                  {`Severity ${alert.currentSeverity}`}
                </Info>
              </AlertWrap>
            );
          })
        )
      }
      {
        openAlertsQuery.openAlerts.length === 0 && (
          <AlertWrap>
            <Title>
              No Known Issues
            </Title>
            <AlertIcon name="CheckIcon" />
            <Info>
              { DateTime.local().toLocaleString(DateTime.DATETIME_SHORT)}
            </Info>
            <Info>
              Severity 0 days
            </Info>
          </AlertWrap>
        )
      }
    </AlertRow>
  );
};

AlertInformation.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  openAlertsQuery: PropTypes.shape({
    openAlerts: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  }).isRequired,
};

export default flowRight(withOpenAlerts)(AlertInformation);
