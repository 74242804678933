
import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

const Container = styled.div`
`;

const Trigger = styled.div`
  cursor: pointer;
`;

const TooltipWrapper = styled.div`
  height: 0;
  position: absolute;
  white-space: nowrap;
`;

const StyledTooltip = styled.div`
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
  left: 20px;
  min-width: 140px;
  opacity: 0.9;
  padding: 14px;
  position: relative;
  top: 20px;
  z-index: 1;
`;

const Tooltip = ({ children, hoverTarget, style }) => {
  const [isHovered, setHover] = useState(false);
  const [useLeft, setSide] = useState(false);

  return (
    <Container ref={(el) => {
      if (el) {
        setSide(el.getBoundingClientRect().x > (window.innerWidth / 2));
      }
    }
    }
    >
      <Trigger
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        { hoverTarget }
        {isHovered && (
        <TooltipWrapper>
          <StyledTooltip style={{ ...style, left: useLeft ? 'calc(-100% + 20px)' : 20 }}>
            {children}
          </StyledTooltip>
        </TooltipWrapper>
        )}
      </Trigger>

    </Container>
  );
};

Tooltip.defaultProps = {
  style: {},
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  hoverTarget: PropTypes.element.isRequired,
  style: PropTypes.shape({}),
};

export default Tooltip;
